import axios from 'axios';
import { isArray } from 'lodash';
import qs from 'qs';

import { showToast } from '@cash2pay/libs/toast';

import { isDev } from './config';

// import jwt from "jsonwebtoken";

//apply base url for axios
const API_URL = isDev
  ? 'https://api.hml.cash2pay.com.br'
  : 'https://api.cash2pay.com.br';

const axiosApi = axios.create({
  baseURL: API_URL,
});

function handleLogout() {
  localStorage.removeItem('authUser');
  window.location = '/login';
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    let message = 'Ocorreu um erro inesperado!';

    if (error.response) {
      const errorMessage =
        error?.response?.data?.message || error?.response?.data?.detail;

      if (
        error.response.config.url.includes('/user') &&
        ['post', 'put'].includes(error.response.config.method)
      ) {
        return Promise.reject(error.response.data);
      }

      if (error?.response?.data?.violations?.length > 0) {
        message = error.response.data?.violations[0]?.reason;
        showToast({ type: 'error', message });
        return Promise.reject(error);
      }

      if (
        error?.response?.data?.message &&
        isArray(error.response.data.message)
      ) {
        error.response.data.message.forEach((msg) => {
          showToast({ type: 'error', message: msg });
        });
        return Promise.reject(error);
      }

      switch (error.response?.status) {
        case 400:
          message =
            errorMessage && errorMessage?.trim()
              ? errorMessage
              : 'Requisição inválida!';
          break;
        case 401:
          message = 'Não autorizado! Faça login novamente.';
          handleLogout();
          break;
        case 403:
          message = 'Acesso negado!';
          handleLogout();
          break;
        case 404:
          message = 'Recurso não encontrado!';
          break;
        case 412:
          message = 'Pré-condição falhou!';
          break;
        case 500:
          message = 'Erro interno no servidor!';
          break;
        case 503:
          message = 'Serviço indisponível!';
          break;
        case 504:
          message = 'Erro ao fazer pedido!';
          break;
        default:
          message =
            errorMessage && errorMessage?.trim()
              ? errorMessage
              : 'Algo deu errado!';
          break;
      }
    } else if (error.request) {
      message = 'Nenhuma resposta do servidor!';
    } else {
      message = 'Erro ao fazer pedido!';
    }

    showToast({ type: 'error', message });

    return Promise.reject(error);
  },
);

axiosApi.defaults.headers.common['Content-Type'] = 'application/json';

// invalidate current user logged, with incompatible token format
let authUser = localStorage.getItem('authUser');
if (authUser) {
  authUser = JSON.parse(authUser);
  if (!authUser['oid']) authUser = null;
}

if (authUser) {
  axiosApi.defaults.headers.common['Authorization'] =
    `Bearer ${authUser['oid'].accessToken}`;
  axiosApi.defaults.headers.common['x-a-oid'] =
    `Bearer ${authUser['oid'].accessToken}`;
}

export async function setAccessToken(accessToken) {
  axiosApi.defaults.headers.common['Authorization'] =
    `Bearer ${accessToken.accessToken}`;
  axiosApi.defaults.headers.common['x-a-oid'] =
    `Bearer ${accessToken.accessToken}`;
}

export async function createNewSession(user) {
  authUser = {
    payload: user.data,
    name: user.data.user['shortName'],
    company: {
      name: user.data['registerAgent']['name'],
    },
    oid: user['oid'],
  };
  console.info('welcome', user.data?.user['shortName']);
  await setAccessToken(user['oid']);
  localStorage.setItem('authUser', JSON.stringify(authUser));
}

export function getUserSession() {
  const loggedUser = authUser;

  if (loggedUser === null || loggedUser === undefined) return null;

  return loggedUser['payload'];
}

export function hasRole(roleName) {
  const usr = getUserSession();
  if (!usr) return false;
  const roles = usr.scope.split(' ');
  if (roleName === 'globalParam.read') {
    console.log('roles', roles);
  }
  return roles.includes(roleName);
}

export function getSessionUserProfile() {
  const usr = getUserSession();
  if (!usr) return null;

  return usr.financialAgent.appProfile;
}

export async function get(
  url,
  config = {},
  query = {},
  setLoading,
  cacheConfig = {},
) {
  try {
    if (setLoading instanceof Function) setLoading(true);

    // if (cacheConfig && cacheConfig.name){
    //     const cache = localStorage.getItem(`cache.${cacheConfig.name}`)
    //     if (cache) return JSON.stringify(cache)
    // }

    const fullUrl = `${url}${toQuery(query)}`;
    const response = await axiosApi
      .get(fullUrl, { ...config })
      .then((response) => response.data);

    // if (response && cacheConfig && cacheConfig.beforeSaving){
    //     if (cacheConfig.beforeSaving(response)){
    //         localStorage.setItem(`cache.${cacheConfig.name}`, JSON.stringify(response))
    //     }
    // }

    return response;
  } catch (e) {
    tryCatchAuthException(e);

    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function getV2(
  url,
  config = {},
  query = {},
  setLoading,
  cacheConfig = {},
) {
  try {
    if (setLoading instanceof Function) setLoading(true);

    // if (cacheConfig && cacheConfig.name){
    //     const cache = localStorage.getItem(`cache.${cacheConfig.name}`)
    //     if (cache) return JSON.stringify(cache)
    // }
    const params = qs.stringify(query);

    const fullUrl = `${url}${params ? '?' + params : ''}`;
    const response = await axiosApi
      .get(fullUrl, { ...config })
      .then((response) => response.data);

    // if (response && cacheConfig && cacheConfig.beforeSaving){
    //     if (cacheConfig.beforeSaving(response)){
    //         localStorage.setItem(`cache.${cacheConfig.name}`, JSON.stringify(response))
    //     }
    // }

    return response;
  } catch (e) {
    tryCatchAuthException(e);

    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export function toQuery2(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? toQuery2(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }
  return str.join('&');
}

export function toQuery(obj) {
  if (!obj) return '';

  let query = '';
  const fields = Object.keys(obj);
  for (const i in fields) {
    const fieldName = fields[i];
    const fieldValue = obj[fieldName];

    if (
      fieldValue !== null &&
      fieldValue !== undefined &&
      typeof fieldValue !== 'object' &&
      fieldValue.toString().trim().length > 0
    ) {
      if (query.length > 0) {
        query += `&`;
      } else {
        query = `?`;
      }
      query += `${fieldName}=${fieldValue}`;
    }
    // else if (
    //   fieldValue !== null &&
    //   fieldValue !== undefined &&
    //   typeof fieldValue === "object" &&
    //   fieldValue.toString().trim().length > 0
    // ) {
    //   query += toQuery2(fieldValue, fieldName);
    // }
  }
  return query;
}

export function tryCatchAuthException(e) {
  let statusCode = e['status'] ? e['status'].toString() : '';

  if (statusCode === '403' || statusCode === '401') {
    localStorage.removeItem('authUser');
    localStorage.setItem('sessionExpired', JSON.stringify(e));
    window.location = '/login';
  }
}

export const rawPost = axiosApi.post;

export async function post(url, data, config = {}, setLoading) {
  try {
    if (setLoading instanceof Function) setLoading(true);
    return await axiosApi
      .post(url, { ...data }, { ...config })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function put(url, data, config = {}, setLoading) {
  try {
    if (setLoading instanceof Function) setLoading(true);

    return await axiosApi
      .put(url, { ...data }, { ...config })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function del(url, config = {}) {
  try {
    return await axiosApi
      .delete(url, { ...config })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  }
}

export async function patch(url, data, config = {}, setLoading) {
  try {
    if (setLoading instanceof Function) setLoading(true);
    return await axiosApi
      .patch(url, { ...data }, { ...config })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function downloadFile(url, config = {}, query = {}, setLoading) {
  try {
    if (setLoading instanceof Function) setLoading(true);
    const fullUrl = `${url}${toQuery(query)}`;

    const newConfig = Object.assign(
      {
        responseType: 'blob',
      },
      config,
    );

    return await axiosApi
      .get(fullUrl, { ...newConfig })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function downloadFilePost(
  url,
  config = {},
  data = {},
  setLoading,
) {
  try {
    if (setLoading instanceof Function) setLoading(true);

    const newConfig = Object.assign(
      {
        responseType: 'blob',
      },
      config,
    );

    return await axiosApi
      .post(url, { ...data }, { ...newConfig })
      .then((response) => response.data);
  } catch (e) {
    tryCatchAuthException(e);
    throw e;
  } finally {
    if (setLoading instanceof Function) setLoading(false);
  }
}

export async function downloadFileComplete(
  url,
  config = {},
  data = {},
  setLoading,
  filename,
  method,
) {
  let response = null;

  if (method && method === 'GET') {
    response = await downloadFile(url, null, data, setLoading);
  } else {
    response = await downloadFilePost(url, null, data, setLoading);
  }

  const newUrl = window.URL.createObjectURL(new Blob([response]));

  const link = document.createElement('a');
  link.href = newUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
