import * as URL from '@cash2pay/helpers/url_helper';

import { HttpService } from './HttpService';

export class OrdersService extends HttpService {
  constructor(http) {
    super(http);
  }

  async getCheckout(orderUri) {
    return this.http.get(`${URL.GET_CHECKOUT}/${orderUri}`);
  }

  async createCheckout(orderUri, client) {
    return this.http.post(`${URL.POST_INI_CHECKOUT}/${orderUri}`, client);
  }
}
