import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import widget from '@cash2pay/assets/images/widget-img.png';
import Loader from '@cash2pay/components/Common/Loader';
import { CREDIT_CODE } from '@cash2pay/domains/CreditDomain';
import { receivablesService } from '@cash2pay/services/http';

import GeneralBalanceReport from './components/GeneralBalanceReport';
import ReceivablesScheduleGraphic from './components/ReceivablesScheduleGraphic';
import ReceivablesUnitsAnalytcs from './components/ReceivablesUnitsAnalytcs';
import { UserIdentifierPanel } from './components/UserIdentifierPanel';

const NotFound = () => (
  <Container className='text-center pb-5'>
    <img
      src={widget}
      className='img-fluid mx-auto d-block w-50'
      alt='Imagem de Ilustração'
    />
    <h4 className='text-uppercase mt-4'>
      Agenda de recebíveis não encontrada!
    </h4>
    <p className='text-muted'>Este CNPJ não possui uma agenda de recebíveis.</p>
  </Container>
);

function ReceivablesScheduleDetail(props) {
  const [loading, setLoading] = useState(true);
  const [receivable, setReceivable] = useState(null);

  /**
   * @param {string} id Receivable legal id
   */
  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const getReceivable = async (legalId) => {
      setLoading(true);

      try {
        const response = await receivablesService.getByLegalId(legalId);
        setReceivable(response.receivable);
      } catch (e) {
        if (e.response.status === 404) {
          history.push('/receivables');
          return;
        }
      }

      setLoading(false);
    };

    getReceivable(id);
  }, [history, id]);

  const isEmptyReceivable = receivable?.tpBlockedCredit === CREDIT_CODE.EMPTY;

  return (
    <>
      <div className='page-content' style={{ padding: '0 10px 0' }}>
        <MetaTags>
          <title>{props.t('COMPANY | Receivables')}</title>
        </MetaTags>

        {!!receivable && (
          <>
            <UserIdentifierPanel entity={receivable} loading={loading} />

            {isEmptyReceivable ? (
              <NotFound />
            ) : (
              <Container fluid>
                <Row>
                  <ReceivablesScheduleGraphic entity={receivable} />
                  <GeneralBalanceReport entity={receivable} loading={loading} />
                </Row>

                <ReceivablesUnitsAnalytcs
                  receivable={receivable}
                  legalIdReceivingEndUser={id}
                />
              </Container>
            )}
          </>
        )}
      </div>

      {loading && <Loader loading={loading} />}
    </>
  );
}

export default withRouter(withTranslation()(ReceivablesScheduleDetail));
