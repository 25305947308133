import { useSelector } from 'react-redux';
import { Switch, BrowserRouter } from 'react-router-dom';

import HorizontalLayout from '@cash2pay/components/HorizontalLayout';
import NonAuthLayout from '@cash2pay/components/NonAuthLayout';
import VerticalLayout from '@cash2pay/components/VerticalLayout';

import { authRoutes, userRoutes } from './allRoutes';
import Authmiddleware from './middleware/Authmiddleware';

const getLayout = (layoutType) =>
  layoutType === 'horizontal' ? HorizontalLayout : VerticalLayout;

// class DebugRouter extends Router {
//   constructor(props) {
//     super(props);
//     console.log("initial history is: ", JSON.stringify(this.history, null, 2));
//     this.history.listen((location, action) => {
//       console.log(
//         `The current URL is ${location.pathname}${location.search}${location.hash}`
//       );
//       console.log(
//         `The last navigation action was ${action}`,
//         JSON.stringify(this.history, null, 2)
//       );
//     });
//   }
// }

export const AppRoutes = () => {
  const layout = useSelector((state) => state.layout);

  return (
    <BrowserRouter>
      <Switch>
        {authRoutes.map((route, id) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={id}
            isAuthProtected={false}
            exact
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={getLayout(layout.layoutType)}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};
