import { useEffect, useState } from "react"
import { plansService } from "../../../services/http"

export const usePlans = () => {
  const [plans, setPlans] = useState([])

  useEffect(() => {
    const listPlans = async () => {
      try {
        const plans = await plansService.getAll()
        setPlans(plans)
      } catch {
        // show toast
      }
    }

    listPlans()
  }, [])

  return { plans }
}
