import React, {useState} from 'react'
import {Col, Row, Modal, Button} from "reactstrap"

import * as URL from "../../helpers/url_helper"

import {withTranslation} from "react-i18next"
import ErrorDialog from "../../components/Common/ErrorDialog";
import checkSuccess from "../../assets/images/check-success.svg"
import $filter from "../../common/utils/$filter";
import SuccessDialog from "../../components/Common/SuccessDialog";

function OrderCreated (props) {

    const [successMessage, setSuccessMessage] = useState(null)

    const [state, setState] = useState( {
        isOpen: props.isOpen,
        entity: props.entity,
        checkoutUrl: `${URL.APP_URL}/checkout/${props.entity.uriPaymentLink}`,
        loading: false,
        error: null,
        form: {
            legalIdBuyer: {isValid: false}
        }
    })

    const toggle = () => {
        state.isOpen = false
        setState(state)
    }

    const afterClosed = () => {
        props.callback({isOpen: false, entity: null, event: 'CLOSE'})
    }

    const setError = (error) => {
        state.error = error
        setState(state)
    }

    const copyLink = () => {
        navigator.clipboard.writeText(
            state.checkoutUrl
        )
        setSuccessMessage({
            detail: `Link copiado com sucesso: ${state.checkoutUrl}`
        })
    }

    const sendWhats = () => {
        const amount = $filter(state.entity.amount, 'MONEY')
        const message = `Pagamento de ${amount} 
para *${state.entity.seller.name}*
identificador: ${state.entity.invoiceNumber}
        
${state.checkoutUrl}

Cash2Pay à conexão certa entre indústria e varejo`

        const whatsUrl = `https://wa.me/?text=${encodeURI(message)}`

        window.open(whatsUrl, "_blank")
    }

    const openCheckout = () => {
        window.open(state.checkoutUrl, "_blank")
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={state.isOpen}
                toggle={() => {
                    toggle()
                }}
                onClosed={() => {
                    afterClosed()
                }}
                modalClassName="drawer right-align"
            >
                <div className="modal-body text-center" style={{padding: 50}}>

                    <Row className="mt-3">
                        <Col>
                            <img src={checkSuccess} alt="" height="160"  />
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-4">
                        <h4>Cobrança gerada com sucesso!</h4>
                    </Row>

                    <Row className="mt-5">
                        <Col className="text-start">
                            Valor da cobrança
                        </Col>
                        <Col className="text-end">
                            {$filter(state.entity.amount, 'MONEY')}
                        </Col>
                    </Row>

                    <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

                    <Row>
                        <Col className="text-start">
                            {props.t("Identifier")}
                        </Col>
                        <Col className="text-end">
                            {state.entity.invoiceNumber}
                        </Col>
                    </Row>

                    <hr style={{borderTop: "1px solid rgb(187 187 187)"}}/>

                    <Row className="mt-5">
                        <Col> <button className="btn btn-primary w-sm waves-effect waves-light w-100"
                                  onClick={copyLink}>
                            <i className="bx bx-link font-size-20" />&nbsp;&nbsp;
                            Copiar link
                            </button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col> <button className="btn btn-primary w-sm waves-effect waves-light w-100"
                                       onClick={sendWhats}>
                            <i className="bx bxl-whatsapp font-size-20" />&nbsp;&nbsp;
                            Abrir no Whats
                        </button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col> <button className="btn btn-primary w-sm waves-effect waves-light w-100"
                                      o onClick={openCheckout}>
                            <i className="fa fa-external-link-alt" />&nbsp;&nbsp;
                            Abrir checkout
                        </button>
                        </Col>
                    </Row>

                </div>

                {
                    state.error ? (
                        <ErrorDialog
                            title={state.error['title']}
                            onClose={() => {
                                setError(null)
                            }}
                        >
                            <p>{state.error['detail']} </p>
                        </ErrorDialog>
                    ) : ("")
                }

                {
                    successMessage ? (
                        <SuccessDialog
                            title={successMessage['title']}
                            onClose={() => {
                                setSuccessMessage(null)
                            }}
                        >
                            <p>{successMessage['detail']} </p>
                        </SuccessDialog>
                    ) : ("")
                }


            </Modal>

        </React.Fragment>
    )
}


export default withTranslation()(OrderCreated)