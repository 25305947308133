import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Modal,
  Card,
  CardBody,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import $filter from "../../common/utils/$filter";
import {
  downloadFileComplete,
  getSessionUserProfile,
  hasRole,
  patch,
  post,
  get,
  rawPost,
} from "../../helpers/api_helper";
import * as URL from "../../helpers/url_helper"
import Swal from "sweetalert2";
import Loader from "../../components/Common/Loader";
import SuccessDialog from "../../components/Common/SuccessDialog";
import PaymentMethodDomain from "../../domains/PaymentMethodDomain";
import { GET_ORDER_FORMALIZATION, POST_ORDER } from "../../helpers/url_helper";
import classnames from "classnames";
import BillingIntegrationStatus from "../../domains/BillingIntegrationStatus";
import { Link } from "react-router-dom";
import * as url from "../../helpers/url_helper";
import { Scrollbars } from "react-custom-scrollbars-2";
import Select2Generic from "../../components/Common/Select2Generic";
import OrderStatusDomain from "../../domains/OrderStatusDomain";
import PaymentCompanyDomain from "../../domains/PaymentCompanyDomain";
import AccountTypeDomain from "../../domains/AccountTypeDomain";
import OrderViewPayments from "./OrderViewPayments";
import OrderViewReceivables from "./OrderViewReceivables";
import InvalidOperation from "../../common/exceptions/InvalidOperation";
import PaginationService from "../../components/Common/PaginationService";
import { USER_PROFILES } from "../../domains/UserProfileDomain";

const OrderView = (props) => {
  const fileInput = useRef(null);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [invoiceSaved, setInvoiceSaved] = useState(true);
  const [enableGenerateBilling, setEnableGenerateBillingCore] = useState(false);
  const [isFinishLoaded, setIsFinishLoaded] = useState(false);

  const [receivables, setReceivables] = useState([]);
  const [creditorAccount, setCreditorAccount] = useState(null);

  const [state, setState] = useState({
    isOpen: props.isOpen,
    entity: props.entity,
    error: null,
    checkoutUrl: `${URL.APP_URL}/checkout/${props.entity.uriPaymentLink}`,
    successMessage: null,
    optoutLoading: false,
    termDownloading: false,
  });

  const [successMessage, setSuccessMessage] = useState(null);

  const [innerHeight] = useState(window.innerHeight);

  const [currentTab, setCurrentTab] = useState(1);
  const [tabs, setTabs] = useState([
    { id: 1, title: props.t("Resumo") },
    {
      id: props.entity.paymentMethod === "CASH.FLOW" ? 3 : 2,
      title:
        props.entity.paymentMethod === "CASH.FLOW" ? "Recebíveis" : "Boletos",
    },
    { id: 4, title: "Pagamentos" },
    {
      id: 10,
      title: "Conta para recebimento",
      hide: props.entity.paymentMethod !== "CASH.FLOW",
    },
  ]);

  const toggle = () => {
    setState({ ...state, isOpen: false });
  };

  const afterClosed = (newEntity) => {
    props.callback(newEntity || state.entity);
  };

  const close = (newEntity) => {
    props.callback(newEntity);
  };

  const setError = (error) => {
    setState({
      ...state,
      error: error,
    });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(state.checkoutUrl);
  };
  const sendWhats = () => {
    const amount = $filter(state.entity.amount, "MONEY");
    const message = `Pagamento de ${amount} 
para *${state.entity.seller.name}*
identificador: ${state.entity.invoiceNumber}
        
${state.checkoutUrl}

Cash2Pay à conexão certa entre indústria e varejo`;

    const whatsUrl = `https://wa.me/?text=${encodeURI(message)}`;

    window.open(whatsUrl, "_blank");
  };

  const openCheckout = () => {
    window.open(state.checkoutUrl, "_blank");
  };

  const postValidate = async () => {
    if (!fileInput.current.files.length)
      throw new InvalidOperation(
        "Arquivo inválido",
        "XML de Nota Fiscal inconsistente"
      );

    const input = fileInput.current;
    const file = input.files[0];
    if (file.size > 1024 * 1024) {
      throw new InvalidOperation(
        "Arquivo inválido",
        "XML de Nota Fiscal inconsistente. Arquivo com mais de 1MB."
      );
    }

    const formData = new FormData(formRef.current);

    try {
      const baseUrl = POST_ORDER;
      const url = `${baseUrl}/${state.entity.id}/invoice/validate`;
      const result = await rawPost(url, formData);
      return result.data;
    } catch (e) {
      throw new InvalidOperation(
        "Arquivo inválido",
        "XML de Nota Fiscal inconsistente"
      );
    }
  };

  const postSave = (forceSave = false) => {
    if (!state.entity.billings || !state.entity.invoice)
      throw new Error("skip");

    if (!forceSave && !checkInvoiceValue()) {
      askForInvoiceChange();
      return;
    }

    const baseUrl = POST_ORDER;
    const url = `${baseUrl}/${state.entity.id}/invoice`;

    return post(url, {
      id: state.entity.id,
      billings: state.entity.billings,
      invoice: state.entity.invoice,
    });
  };

  const getCheckoutData = (checkoutData) => {
    if (!checkoutData) return "";
    const d = JSON.parse(checkoutData);
    return (
      <>
        <div>
          <strong>Nome: </strong>
          {d.personName}
        </div>
        <div>
          <strong>CPF: </strong>
          {$filter(d?.personLegalId, "CPF_CNPJ")}
        </div>
        <div>
          <strong>Cargo Ocupado: </strong>
          {d?.personCompanyPlacement}
        </div>
        <div>
          <strong>E-mail: </strong>
          {d?.additionalBuyerSigner?.email}
        </div>
        <div>
          <strong>Telefone: </strong>
          {d?.additionalBuyerSigner?.phone}
        </div>
      </>
    );
  };

  const getOrder = async () => {
    try {
      await get(
        `${url.PATCH_ORDER_UPDATE}/${state.entity.id}`,
        null,
        {},
        setLoading
      );
    } catch (error) {
      console.error("ATUALIZAR STATUS ERROR", error);
    }

    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_ORDER,
        "orders",
        {
          orderId: state.entity.id,
        },
        0,
        1,
        setLoading
      );
      const newEntity = pagerResult.results[0];
      if (newEntity.billings && newEntity.billings.length) {
        newEntity.billings = newEntity.billings.sort((a, b) => {
          return parseInt(a.deadline) - parseInt(b.deadline);
        });
      }
      state.entity = newEntity;
      setState(Object.assign({}, state));
    } catch (e) {
      setError(e);
    }
  };

  const checkInvoiceValue = () => {
    if (!state.entity.billings || !state.entity.billings.length) return false;
    const billingsTotalVal = state.entity.billings
      .map((b) => parseFloat(b.amount))
      .reduce((p, n) => p + n, 0);

    const orderVal = parseFloat(state.entity.amount);

    return billingsTotalVal === orderVal;
  };

  const validateOrSave = async (requestSave = false, force = false) => {
    try {
      setLoading(true);

      const result = requestSave ? await postSave(force) : await postValidate();

      if (result && result.billings && result.billings.length) {
        const newEntity = {
          ...state.entity,
          billings: result.billings,
          invoice: result.invoice,
        };
        if (result.hadStatusUpdate) {
          newEntity.status = "P";
          newEntity.hadStatusUpdate = result.hadStatusUpdate;
          close(newEntity);
          return;
        }

        setState({
          ...state,
          entity: newEntity,
        });
        setInvoiceSaved(requestSave);
      }
    } catch (e) {
      if (e.message === "skip") {
        return;
      }
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const askInvoiceExists = () => {
    const html = `<div>
                <p>
                <b>NF emitida?</b>
                <br><br>
                A Nota Fiscal desta cobrança ja foi emitida?
                </p>
            </div>`;

    return Swal.fire({
      title: props.t("Confirm"),
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Yes"),
      cancelButtonText: props.t("No"),
    });
  };

  const askForInvoiceChange = (entity, index) => {
    const html = `<div>
                <p>
                <b>Valor da NFe diferente da Cobrança. Deseja alterar?</b>
                <br><br>
                A cobrança se tornará pendente de autorização em caso de alteração de valores.
                </p>
            </div>`;

    Swal.fire({
      title: props.t("Confirm"),
      icon: "question",
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        rollbackStatus(entity, index).then();
      }
    });
  };

  const rollbackStatus = async () => {
    return validateOrSave(true, true);
  };

  const handleFileSelect = (e) => {
    validateOrSave(false).then();
  };

  const save = async () => {
    validateOrSave(true).then();
  };

  const buildInstallmentsToBiling = (onlyWithoutBilling, installmentNumber) => {
    const request = {
      orderId: state.entity["id"],
      installments: [],
    };
    for (const i in state.entity.billings) {
      const bill = state.entity.billings[i];

      if (installmentNumber !== null) {
        if (bill["billingNum"] === installmentNumber) {
          request.installments.push({
            installmentNumber: bill["billingNum"],
          });
        }
      } else {
        if (onlyWithoutBilling) {
          if (
            bill.info["statusIntegration"] === null ||
            bill.info["statusIntegration"] === undefined ||
            bill.info["statusIntegration"] === BillingIntegrationStatus.ERROR
          ) {
            request.installments.push({
              installmentNumber: bill["billingNum"],
            });
          }
        } else {
          request.installments.push({
            installmentNumber: bill["billingNum"],
          });
        }
      }
    }
    return request;
  };

  const handleGenerateBilling = async (installmentNumber) => {
    if (loading) return;

    if (state.entity.billings && state.entity.billings.length) {
      try {
        const request = buildInstallmentsToBiling(true, installmentNumber);

        // no installments without billing
        if (request["installments"].length === 0) {
          setError({
            title: props.t("Billing not requested"),
            status: "404",
            detail: props.t("Not installments availables to billing"),
          });
          return;
        }

        if (!state.entity.hasInvoice) {
          const resultQuestion = await askInvoiceExists();
          if (resultQuestion.isConfirmed) {
            const finalUrl = `${url.PATCH_ORDER}/${state.entity.id}`;
            const response = await patch(
              finalUrl,
              {
                status: state.entity.status,
                hasInvoice: true,
              },
              null,
              setLoading
            );
            state.entity.hasInvoice = true;
            setState(state);
          }
        }

        const response = await post(
          url.POST_ORDER_BILLING,
          request,
          null,
          setLoading
        );

        response.map((ins) => {
          state.entity.billings.map((subIns) => {
            if (ins["billingNum"] === subIns["billingNum"]) {
              if (!subIns.info) subIns.info = {};
              if (!ins.info) ins.info = {};

              subIns.info["statusChargeInt"] = ins.info["statusIntegration"];
            }
          });
        });

        const newState = Object.assign({}, state);

        setState(newState);
        setEnableGenerateBilling(newState.entity);
      } catch (e) {
        if (e["status"] !== "200" && e["status"] !== "403") setError(e);
      }
    }
  };

  const setEnableGenerateBilling = (order) => {
    if (order.billings && order.billings.length > 0) {
      order.billings.map((inst) => {
        if (!inst.info) inst.info = {};

        if (
          inst.info["statusIntegration"] === null ||
          inst.info["statusIntegration"] === undefined
        ) {
          setEnableGenerateBillingCore(true);
        }
      });
    }
  };

  const handleRefresh = async () => {};
  const handleDownloadBilling = async () => {
    const request = buildInstallmentsToBiling(false, null);

    let fileId = state.entity.id;
    if (state.entity.invoiceNumber) {
      fileId = `${state.entity.id} - ${state.entity.invoiceNumber}`;
    }

    const filename = `${state.entity.buyer["name"]} - ${fileId} - BILLING.pdf`;

    await downloadFileComplete(
      url.POST_ORDER_BILLING_PDF,
      null,
      request,
      setLoading,
      filename
    );
  };

  const downloadTermo = async () => {
    await downloadFileComplete(
      `${GET_ORDER_FORMALIZATION}/${state.entity.id}`,
      null,
      null,
      setLoading,
      `ORDER-${state.entity.id}-${state.entity.buyer.name}.pdf`,
      "GET"
    );
  };

  useEffect(() => {
    if (!isFinishLoaded) {
      setIsFinishLoaded(true);
      if (!enableGenerateBilling) {
        setEnableGenerateBilling(state.entity);
      }
      let additionalInfo = state.entity.additionalInfo;
      if (!(additionalInfo instanceof Object)) {
        additionalInfo = JSON.parse(additionalInfo);
      }

      if (additionalInfo && additionalInfo["receivables"]) {
        setReceivables(additionalInfo.receivables);
      }

      if (additionalInfo && additionalInfo["creditorAccount"]) {
        setCreditorAccount(additionalInfo.creditorAccount);
      }

      if (state.entity.billings && state.entity.billings.length) {
        state.entity.billings = state.entity.billings.sort((a, b) => {
          return parseInt(a.deadline) - parseInt(b.deadline);
        });
        setState(Object.assign({}, state));
      }
    }
  }, [state]);

  const askForJustification = () => {
    const html = `<div>
                <p><strong>Informe um parecer para mudança de status</strong>
                </p>
            </div>`;

    return Swal.fire({
      title: "Alteracao de satus de cobrança",
      icon: "question",
      html: html,
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t("Confirm"),
      cancelButtonText: props.t("Cancel"),
    });
  };

  const handleStatusChange = async (op) => {
    if (getSessionUserProfile() !== USER_PROFILES.ADM) return;

    const payload = { status: op.value };
    if (["A", "R"].includes(op.value)) {
      const justifResponse = await askForJustification();
      if (justifResponse.isConfirmed) {
        payload.statusJustification = justifResponse.value;
      }
    }
    const finalUrl = `${url.PATCH_ORDER_UPDATE}/${state.entity.id}`;

    try {
      const orderUpdate = await patch(finalUrl, payload, null, setLoading);
      // Start polling for status
      // const maxRetries = 1; // Máximo de tentativas de polling
      // const delay = 5000; // Intervalo de polling em milissegundos

      // const orderStatus = await pollOrderStatus(state.entity.id, delay);

      // Update state based on the returned status
      if (orderUpdate.status) {
        state.entity.status = orderUpdate.status;
        setState(state);
      }

      setSuccessMessage({
        title: props.t("Order update"),
        detail: props.t("Order updated successfully"),
      });
    } catch (error) {
      console.error("Failed to update order status:", error);
    }
  };

  const pollOrderStatus = async (orderId, interval = 5000) => {
    return new Promise((resolve, reject) => {
      const checkStatus = async () => {
        try {
          const response = await get(`/order/${orderId}/status`);
          if (response.status === "completed") {
            // Ajuste para corresponder à estrutura da sua resposta
            resolve(response); // Retorna a resposta completa, ajuste conforme necessário
          } else if (response.status === "failed") {
            reject(new Error("Order update failed"));
          } else {
            setTimeout(checkStatus, interval); // Continua o polling
          }
        } catch (error) {
          console.error("Polling error:", error);
          setTimeout(checkStatus, interval); // Continua polling em caso de erro
        }
      };
      checkStatus();
    });
  };

  const allStatus = [
    { value: "V" },
    { value: "P" },
    { value: "A" },
    { value: "R" },
    { value: "C" },
    { value: "E" },
  ];

  return (
    <React.Fragment>
      <Modal
        isOpen={state.isOpen}
        size="xl"
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        contentClassName="modal-content-scroll"
        modalClassName="drawer right-align"
      >
        <Scrollbars style={{ height: innerHeight - 80 }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <p className="card-title-desc mb-1">
                <small> {props.t("Billing")}</small>
                <small>
                  {" / "} {state.entity.id}
                </small>
                {state.entity.invoiceNumber ? (
                  <small>
                    {" / "} {state.entity.invoiceNumber}
                  </small>
                ) : (
                  ""
                )}
              </p>
              {state.entity.buyer.name} <br />
              <p className="card-title-desc mb-0">
                <small>{$filter(state.entity.buyer.legalId, "CPF_CNPJ")}</small>
              </p>
            </h5>
            <button
              type="button"
              onClick={() => {
                toggle();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col lg={8}>
                <Nav pills>
                  {tabs.map((tab, index) => {
                    if (!tab.hide)
                      return (
                        <NavItem key={tab.id}>
                          <NavLink
                            className={classnames({
                              active: currentTab === tab.id,
                            })}
                            onClick={() => {
                              setCurrentTab(tab.id);
                            }}
                          >
                            {tab.title}
                          </NavLink>
                        </NavItem>
                      );
                  })}
                </Nav>
              </Col>

              <Col lg={4}>
                <Select2Generic
                  id="status"
                  required={true}
                  isDisabled={getSessionUserProfile() !== USER_PROFILES.ADM}
                  defaultOptins={allStatus}
                  defaultValue={allStatus
                    .map((v) => v.value)
                    .indexOf(state.entity.status)}
                  getOptionLabel={(op) => {
                    return (
                      <React.Fragment>
                        <div
                          style={{ height: 10, position: "fixed" }}
                          className={OrderStatusDomain.getClassFromValue(
                            op.value
                          )}
                        >
                          {" "}
                        </div>
                        <span className="ms-5">
                          {OrderStatusDomain.getNameFromValue(op.value)}
                        </span>
                      </React.Fragment>
                    );
                  }}
                  onChange={(option) => handleStatusChange(option)}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={12}>
                <div className="content clearfix ">
                  <TabContent activeTab={currentTab}>
                    <TabPane tabId={1}>
                      <Card>
                        <CardBody>
                          <Table className="table table-striped mb-0 font-size-14">
                            <tbody>
                              <tr>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Billing Date")}
                                  </p>
                                  {$filter(state.entity.orderDate, "DATE")}
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Shipping date")}
                                  </p>
                                  {$filter(
                                    state.entity.expectedDeliveryDate,
                                    "DATE"
                                  )}
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Identifier")}
                                  </p>
                                  {state.entity.invoiceNumber}
                                </td>
                              </tr>
                              <tr>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Receipt plan")}
                                  </p>
                                  {
                                    PaymentMethodDomain.getInstanceFromValue(
                                      state.entity.paymentMethod
                                    )["name"]
                                  }
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Payment period")}
                                  </p>
                                  {state.entity.deadline}
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("ID")}
                                  </p>
                                  {state.entity.id}
                                </td>
                              </tr>

                              <tr>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Installment numbers")}
                                  </p>
                                  {state.entity.deadline
                                    ? state.entity.deadline.split("/").length
                                    : ""}
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Tax Operation")}
                                  </p>
                                  {$filter(state.entity.tax, "PRECENT")}
                                </td>
                                <td className="col-md-4">
                                  <p className="card-title-desc mb-2">
                                    {props.t("Total amount")}
                                  </p>
                                  <strong>
                                    {$filter(state.entity.amount, "MONEY")}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td className="col-md-12" colSpan={3}>
                                  <p className="card-title-desc mb-2">
                                    {props.t("Checkout Data")}
                                  </p>
                                  {getCheckoutData(state.entity.checkoutInfo)}
                                </td>
                              </tr>
                              <tr>
                                <td className="col-md-12" colSpan={3}>
                                  <p className="card-title-desc mb-2">
                                    {props.t("Message")}
                                  </p>
                                  {state.entity.message}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Card>
                        <CardBody
                          style={
                            invoiceSaved ? null : { border: "1px solid orange" }
                          }
                        >
                          <React.Fragment>
                            <div className="table-responsive">
                              <Table className="table table-striped table-bordered font-size-default">
                                <thead className="table-light">
                                  <tr>
                                    <th className="col-md-1">
                                      {props.t("Installment")}
                                    </th>
                                    <th className="col-md-4">
                                      {props.t("Due date")}
                                    </th>
                                    <th className="col-md-4">
                                      {props.t("Amount")}
                                    </th>
                                    <th className="col-md-2 text-center">
                                      {props.t("Status")}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {(state.entity.billings || []).map((b) => {
                                    return (
                                      <tr>
                                        <td>{b.billingNum}</td>
                                        <td>{$filter(b.dueDate, "DATE")}</td>
                                        <td>{$filter(b.amount, "MONEY")}</td>
                                        <td className="text-center">
                                          <span
                                            className={BillingIntegrationStatus.getClassFromValue(
                                              b.info
                                                ? b.info["statusIntegration"]
                                                : ""
                                            )}
                                          >
                                            {BillingIntegrationStatus.getLabelByValue(
                                              b.info
                                                ? b.info["statusIntegration"]
                                                : ""
                                            )}
                                          </span>
                                          {b.info &&
                                          b.info["statusIntegration"] ===
                                            "E" ? (
                                            <React.Fragment>
                                              <br />
                                              <Link
                                                to="#!"
                                                onClick={() => {
                                                  handleGenerateBilling(
                                                    b["billingNum"]
                                                  );
                                                }}
                                              >
                                                Gerar cobrança
                                              </Link>
                                            </React.Fragment>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>

                            <Row className="mt-3">
                              <Col lg={12}>
                                <div className="text-end">
                                  {state.entity["status"] === "A" ? (
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary mr-5"
                                        onClick={handleDownloadBilling}
                                      >
                                        <i className="mdi mdi-file-download-outline font-size-16 mr-5" />
                                        {props.t("Ver Boletos")}
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary mr-5"
                                        onClick={getOrder}
                                      >
                                        <i className="mdi mdi-refresh font-size-16 mr-5" />
                                        {props.t("Atualizar")}
                                      </button>
                                      {hasRole("order.write") ? (
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary mr-5"
                                          onClick={() => {
                                            handleGenerateBilling(null);
                                          }}
                                          disabled={!enableGenerateBilling}
                                        >
                                          {props.t("Gerar Boletos")}
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId={3}>
                      <OrderViewReceivables receivables={receivables} />
                    </TabPane>

                    <TabPane tabId={4}>
                      <OrderViewPayments entity={state.entity} />
                    </TabPane>

                    <TabPane tabId={10}>
                      {creditorAccount ? (
                        <Card>
                          <CardBody>
                            <Table className="table table-striped mb-0 font-size-14">
                              <tbody>
                                <tr>
                                  <td className="col-md-5">
                                    <p className="card-title-desc mb-2">
                                      Instituição
                                    </p>
                                    <strong>
                                      {PaymentCompanyDomain.getLabelByCode(
                                        creditorAccount.ispb
                                      )}
                                    </strong>
                                  </td>
                                  <td className="col-md-3">
                                    <p className="card-title-desc mb-2">
                                      Tipo de conta
                                    </p>
                                    {AccountTypeDomain.getLabelByValue(
                                      creditorAccount.accountType
                                    )}
                                  </td>
                                  <td className="col-md-2">
                                    <p className="card-title-desc mb-2">
                                      Agência
                                    </p>
                                    {creditorAccount.branch}
                                  </td>
                                  <td className="col-md-5">
                                    <p className="card-title-desc mb-2">
                                      Conta
                                    </p>
                                    {creditorAccount.accountNumber}
                                    {creditorAccount.accountDigit
                                      ? `-${creditorAccount.accountDigit}`
                                      : ""}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      ) : (
                        ""
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </Scrollbars>

        <div className="modal-footer fixed-bottom">
          <Row className="w-100">
            <Col lg={9}>
              <button
                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                type="button"
                onClick={openCheckout}
              >
                <i className="fa fa-external-link-alt" />
                &nbsp;&nbsp;
                <span className="d-lg-none d-md-none d-sm-none d-xl-block">
                  Abrir checkout
                </span>
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                type="button"
                onClick={copyLink}
              >
                <i className="bx bx-link font-size-20" />
                &nbsp;&nbsp;
                <span className="d-lg-none d-md-none d-sm-none d-xl-block">
                  Copiar link
                </span>
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                type="button"
                onClick={sendWhats}
              >
                <i className="bx bxl-whatsapp font-size-20" />
                &nbsp;&nbsp;
                <span className="d-lg-none d-md-none d-sm-none d-xl-block">
                  Abrir no Whats
                </span>
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-sm btn-light w-sm waves-effect waves-light"
                type="button"
                onClick={downloadTermo}
              >
                <i className="mdi mdi-download font-size-20" />
              </button>
            </Col>
            <Col lg={3}>
              <div className="text-end">
                <button
                  className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                  type="button"
                  onClick={save}
                >
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </div>

        {/*{
                    state.error ? (
                        <ErrorDialog
                            title={state.error['title']}
                            onClose={() => {
                                setError(null)
                            }}
                        >
                            <p>{props.t(state.error['detail'])} </p>
                        </ErrorDialog>
                    ) : ("")
                }*/}

        {successMessage ? (
          <SuccessDialog
            title={successMessage["title"]}
            onClose={() => {
              setSuccessMessage(null);
            }}
          >
            <p>{successMessage["detail"]} </p>
          </SuccessDialog>
        ) : (
          ""
        )}
        {loading ? <Loader loading={loading} /> : " "}
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(OrderView);
