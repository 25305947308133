import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import { Badge, Button, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';

import $filter from '../../../common/utils/$filter';
import DateTimeUtils from '../../../common/utils/DateTimeUtils';
import SelectEndUserReceivables from '../../../components/Common/SelectEndUserReceivables';
import GarantiasModal from './GarantiasModal';
import GarantiasModalConstituidas from './GarantiasModalConstituidas';

function GravameGarantiasNewV2({
  acquirers = [],
  arrangements = [],
  guarantees = [],
  setGuarantees = () => {},
  creditorAccount = {
    taxIdOwner: '',
    bankCode: null,
    accountType: '',
    branch: '',
    ispb: '',
    accountNumber: '',
    accountDigit: '',
    ownerName: '',
    legalId: '',
  },
  receivingEndUserLegalId,
  holderLegalId,
  contract,
  amountGG,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [
    openModalSelectEndUserReceivables,
    setOpenModalSelectEndUserReceivables,
  ] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  const addBook = () => {
    const lastOneId = guarantees.length + 1;
    setGuarantees([
      ...guarantees,
      {
        id: `${lastOneId}`,
        name: `Book ${lastOneId}`,
      },
    ]);
  };

  const openModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const openModalNConstituido = () => {
    setOpenModalSelectEndUserReceivables(!openModalSelectEndUserReceivables);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Garantia ID',
      hidden: true,
    },
    {
      dataField: 'acquirers',
      text: 'Credenciadora',
      formatter: (cell, row, rowIndex) => (
        <div>
          {cell &&
            cell?.map((acq) => (
              <Badge
                className={`badge bg-light rounded-pill font-size-12`}
                key={rowIndex}
                color='light'
                pill={true}
              >
                {acq.name}
              </Badge>
            ))}
        </div>
      ),
    },
    {
      dataField: 'arrangements',
      text: 'Arranjo',
      formatter: (cell, row, rowIndex) => (
        <>
          {cell &&
            cell?.map((arr, i) => (
              <Badge
                className={`badge bg-dark rounded-pill font-size-12`}
                key={`${rowIndex}-${i}`}
                color='dark'
                pill={true}
              >
                {arr.code}
              </Badge>
            ))}
        </>
      ),
    },
    {
      dataField: 'startDate',
      text: props.t('Start date'),
      formatter: (cell, row) => (
        <span key={`${row.id}-startDate`}>
          {DateTimeUtils.format(cell, 'DD/MM/YYYY')}
        </span>
      ),
    },
    {
      dataField: 'endDate',
      text: props.t('End date'),
      formatter: (cell, row) => (
        <span key={`${row.id}-endDate`}>
          {DateTimeUtils.format(cell, 'DD/MM/YYYY')}
        </span>
      ),
    },
    {
      dataField: 'amountCharged',
      text: 'Valor',
    },
    {
      dataField: 'actions',
      text: 'Ações',
      editable: false,
      formatter: (cell, row) => (
        <Button
          color='danger'
          className='btn btn-default'
          onClick={() => {
            setGuarantees(
              guarantees.filter((guarantee) => guarantee.id !== row.id),
            );
          }}
        >
          <i className='bx bx-trash font-size-16 align-middle me-2'></i>
          Excluir
        </Button>
      ),
    },
  ];

  return (
    <>
      <CardTitle>Garantias</CardTitle>
      <CardSubtitle>Selecionadas: {$filter(amountGG, 'MONEY')}</CardSubtitle>
      <Row>
        <BootstrapTable
          keyField='id'
          data={guarantees}
          columns={columns}
          pagination={paginationFactory(options)}
          noDataIndication='Não há garantias cadastradas'
        />
      </Row>
      <Row>
        <Col lg={6}>
          <Button
            color='primary'
            className='btn btn-default'
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
          >
            <i className='bx bx-plus font-size-16 align-middle me-2'></i>
            Adicionar garantias não constituidas
          </Button>
        </Col>
        <Col lg={6}>
          <Button
            color='success'
            className='btn btn-default'
            onClick={(e) => {
              e.preventDefault();
              openModalNConstituido();
            }}
          >
            <i className='bx bx-plus font-size-16 align-middle me-2'></i>
            Adicionar garantias constituidas
          </Button>
        </Col>
      </Row>

      {/* <dv />
      <Button
        color="primary"
        className="btn btn-default"
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        <i className="bx bx-plus font-size-16 align-middle me-2"></i>
        Adicionar garantias nao contituidas
      </Button> */}
      <GarantiasModal
        guarantees={guarantees}
        setGuarantees={setGuarantees}
        creditorAccount={creditorAccount}
        isOpen={isOpenModal}
        toggleModal={openModal}
        acquirers={acquirers}
        arrangements={arrangements}
        receivingEndUserLegalId={receivingEndUserLegalId}
        holderLegalId={holderLegalId}
        contract={contract}
      />

      <GarantiasModalConstituidas
        guarantees={guarantees}
        setGuarantees={setGuarantees}
        creditorAccount={creditorAccount}
        isOpen={openModalSelectEndUserReceivables}
        toggleModal={openModalNConstituido}
        acquirers={acquirers}
        arrangements={arrangements}
        receivingEndUserLegalId={receivingEndUserLegalId}
        holderLegalId={holderLegalId}
        contract={contract}
      />

      {/* <SelectEndUserReceivablesGravame
        isOpen={modalAddGuarantee}
        endUser={{ ...entity.receivingEndUser }}
        amount={entity.amountCharged}
        discountRate={contract?.additionalInfo?.discountRate}
        contract={contract}
        callback={(result) => {
          console.log(result, entity);
          closeModalAddGuarantee(result);
        }}
        endDate={entity.endDate}
        startDate={entity.startDate}
      /> */}
    </>
  );
}

export default withTranslation()(GravameGarantiasNewV2);
