import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//i18n
import PropTypes from 'prop-types';

import SidebarContent from './SidebarContent';
const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className='vertical-menu'>
        <div className='h-100'>
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.layout,
  };
};
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)));
