const ArrangementsDomain = {
  options: [
    {
      label: 'Débito',
      options: [
        {
          code: 'BCD',
          label: 'Banescard Cartão de Débito',
          value: 'BCD',
        },
        {
          code: 'BVV',
          label: 'Ben Visa Vale',
          value: 'BVV',
        },
        {
          code: 'CBD',
          label: 'Cabal Débito',
          value: 'CBD',
        },
        {
          code: 'MCD',
          label: 'Mastercard Cartão de Débito',
          value: 'MCD',
        },
        {
          code: 'SCD',
          label: 'Sorocred Cartão de Débito',
          value: 'SCD',
        },
        {
          code: 'HCD',
          label: 'Hiper Débito',
          value: 'HCD',
        },
        {
          code: 'OCD',
          label: 'Ourocard Cartão de Débito',
          value: 'OCD',
        },
        {
          code: 'ECD',
          label: 'Elo Cartão de Débito',
          value: 'ECD',
        },
        {
          code: 'VCD',
          label: 'Visa Cartão de Débito',
          value: 'VCD',
        },
      ],
    },
    {
      label: 'Crédito',
      options: [
        {
          code: 'AGC',
          label: 'Agiplan',
          value: 'AGC',
        },
        {
          code: 'ACC',
          label: 'Amex Cartão de Crédito',
          value: 'ACC',
        },
        {
          code: 'AUC',
          label: 'Aura',
          value: 'AUC',
        },
        {
          code: 'AVC',
          label: 'Avista',
          value: 'AVC',
        },
        {
          code: 'BCC',
          label: 'Banescard Cartão de Crédito',
          value: 'BCC',
        },
        {
          code: 'BNC',
          label: 'Banese Card',
          value: 'BNC',
        },
        {
          code: 'BRC',
          label: 'Brasil Card',
          value: 'BRC',
        },
        {
          code: 'CBC',
          label: 'Cabal Crédito',
          value: 'CBC',
        },
        {
          code: 'CCD',
          label: 'Calcard',
          value: 'CCD',
        },
        {
          code: 'CAC',
          label: 'Cielo Amex Crédito',
          value: 'CAC',
        },
        {
          code: 'CSC',
          label: 'Credi-Shop',
          value: 'CSC',
        },
        {
          code: 'CZC',
          label: 'CREDZ Crédito',
          value: 'CZC',
        },
        {
          code: 'CUP',
          label: 'Cup Crédito',
          value: 'CUP',
        },
        {
          code: 'DAC',
          label: 'Dacasa',
          value: 'DAC',
        },
        {
          code: 'DBC',
          label: 'Discover',
          value: 'DBC',
        },
        {
          code: 'ECC',
          label: 'Elo Cartão de Crédito',
          value: 'ECC',
        },
        {
          code: 'FRC',
          label: 'Fortbrasil',
          value: 'FRC',
        },
        {
          code: 'GCC',
          label: 'Goodcard Crédito',
          value: 'GCC',
        },
        {
          code: 'HCC',
          label: 'Hipercard Cartão de Crédito',
          value: 'HCC',
        },
        {
          code: 'JCC',
          label: 'JCB Cartão de Crédito',
          value: 'JCC',
        },
        {
          code: 'DCC',
          label: 'Liquidações de transações transfronteiriças Diners',
          value: 'DCC',
        },
        {
          code: 'MAC',
          label: 'Mais!',
          value: 'MAC',
        },
        {
          code: 'MCC',
          label: 'Mastercard Cartão de Crédito',
          value: 'MCC',
        },
        {
          code: 'MXC',
          label: 'Maxifrota',
          value: 'MXC',
        },
        {
          code: 'RCC',
          label: 'Redesplan',
          value: 'RCC',
        },
        {
          code: 'SPC',
          label: 'Sem Parar',
          value: 'SPC',
        },
        {
          code: 'SFC',
          label: 'Senff',
          value: 'SFC',
        },
        {
          code: 'SCC',
          label: 'Sorocred Cartão de Crédito',
          value: 'SCC',
        },
        {
          code: 'TKC',
          label: 'TicketLog Pós',
          value: 'TKC',
        },
        {
          code: 'VDC',
          label: 'Verdecard Cartão de Crédito',
          value: 'VDC',
        },
        {
          code: 'VCC',
          label: 'Visa Cartão de Crédito',
          value: 'VCC',
        },
      ],
    },
  ],

  getLabelByValue(value) {
    const debitors = ArrangementsDomain.options[0];
    const creditors = ArrangementsDomain.options[1];

    for (const i in debitors.options) {
      if (debitors.options[i].value === value) {
        return debitors.options[i].label;
      }
    }
    for (const i in creditors.options) {
      if (creditors.options[i].value === value) {
        return creditors.options[i].label;
      }
    }
    return null;
  },

  getInstancesByValues(values) {
    const instances = [];
    const debitors = ArrangementsDomain.options[0];
    const creditors = ArrangementsDomain.options[1];

    for (const j in values) {
      for (const i in debitors.options) {
        if (debitors.options[i].value === values[j]) {
          instances.push(debitors.options[i]);
        }
      }
      for (const i in creditors.options) {
        if (creditors.options[i].value === values[j]) {
          instances.push(creditors.options[i]);
        }
      }
    }
    return instances;
  },

  getValueByLabel(label = '') {
    const debitors = ArrangementsDomain.options[0];
    const creditors = ArrangementsDomain.options[1];

    for (const i in debitors.options) {
      if (debitors.options[i].label === label) {
        return debitors.options[i].value;
      }
    }
    for (const i in creditors.options) {
      if (creditors.options[i].label === label) {
        return creditors.options[i].value;
      }
    }
    return null;
  },
};

export default ArrangementsDomain;
