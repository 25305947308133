import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  Modal,
  Row,
} from 'reactstrap';

import moment from 'moment-timezone';

import $filter from '../../../common/utils/$filter';
import DateTimeUtils from '../../../common/utils/DateTimeUtils';
import InputDate from '../../../components/Common/InputDate';
import Select2Acquirers from '../../../components/Common/Select2Acquirers';
import Select2Arrangements from '../../../components/Common/Select2Arrangements';
import ChartLocale from '../../../domains/ChartLocale';
import { post } from '../../../helpers/api_helper';
import { GET_RECEIVABLES_LIST } from '../../../helpers/url_helper';

function GarantiasModalConstituidas({
  acquirers = [],
  arrangements = [],
  guarantees = [],
  setGuarantees = () => {},
  toggleModal,
  _afterClosed,
  receivingEndUserLegalId,
  holderLegalId,
  isOpen = false,
  _contract,
  ...props
}) {
  const [entity, setEntity] = useState({
    acquirers: [],
    arrangements: [],
    receivingEndUser: {
      legalId: receivingEndUserLegalId,
    },
    holder: {
      legalId: holderLegalId,
    },
    divisionRule: '1',
    typeDistribution: '1',
    startDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
    endDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
    amountCharged: 0,
  });

  const [labels, setLabels] = useState([]);
  const [seriesFormat, setSeriesFormat] = useState('freeValue');
  const [series, setSeries] = useState([]);
  const [optionsChart, setOptinsChart] = useState({
    chart: {
      height: 300,
      type: 'bar',
      stacked: false,
      defaultLocale: ChartLocale.pt.name,
      locales: [ChartLocale.pt],
      toolbar: {
        show: true,
      },
    },
    title: {
      text: 'Garantias',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
        borderRadius: 8,
      },
    },
    colors: ChartLocale.pt.colors,

    labels: labels,
    markers: {
      size: 0,
    },
    xaxis: {
      type: seriesFormat,
      range: 7,
    },
    yaxis: {
      title: {
        text: 'Valores',
      },
      range: 7,
      labels: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return $filter(y, 'MONEY');
          }
          return y;
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return $filter(y, 'MONEY');
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: '#f8f8f8',
      padding: {
        bottom: 15,
      },
    },
  });
  const [amountCharged, setAmountCharged] = useState(0);
  const [selectGuarante, setSelectGuarante] = useState([]);
  const [dueDate, setDueDate] = useState({
    init: moment().add(1, 'day').format('YYYY-MM-DD'),
    end: moment().add(1, 'month').format('YYYY-MM-DD'),
  });

  /**
   * Add guarantee to list guarantees selected in the modal filter, check duplicate guarantees
   * @param gg
   */
  const updateGuaranteAmount = (gg) => {
    const index = selectGuarante.findIndex((g) => g.id === gg.id);
    if (index === -1) {
      setSelectGuarante([...selectGuarante, gg]);
      setAmountCharged(amountCharged + gg.freeValue);
    }
  };
  const removeGuaranteAmount = (gg) => {
    const index = selectGuarante.findIndex((g) => g.id === gg.id);
    if (index !== -1) {
      selectGuarante.splice(index, 1);
      setSelectGuarante([...selectGuarante]);
      setAmountCharged(amountCharged - gg.freeValue);
    }
  };

  const [modalAddGuarantee, setModalAddGuarantee] = useState(false);

  const handleAcquirers = (selectAcquirers) => {
    setEntity((e) => ({ ...e, acquirers: selectAcquirers }));
  };

  const [receivables, setGgList] = useState([]);

  const handleArrangements = (selectarrangements) => {
    setEntity((e) => ({ ...e, arrangements: selectarrangements }));
  };

  const handleFieldEntity = (event) => {
    const { name, value } = event.target;
    setEntity((e) => ({ ...e, [name]: value }));
  };

  const save = (e) => {
    e.preventDefault();
    const _guarantees = selectGuarante.map((gg) => ({
      ...entity,
      acquirers: [{ legalId: gg.legalIdAcquirer, name: gg.nameAcquirer }],
      arrangements: [{ code: gg.arrangementCode, name: gg.arrangementName }],
      amountCharged: gg.freeValue,
      startDate: gg.settlementDate,
      endDate: gg.settlementDate,
    }));
    setGuarantees([...guarantees, ..._guarantees]);
    toggleModal();
  };

  useEffect(() => {
    setEntity((e) => ({
      ...e,
      receivingEndUser: {
        legalId: receivingEndUserLegalId,
      },
      holder: {
        legalId: holderLegalId,
      },
    }));
  }, [receivingEndUserLegalId, holderLegalId]);

  const getReceivables = async () => {
    const dueDateInit = dueDate.init;
    const dueDateEnd = dueDate.end;

    const data = {
      acquirers: entity?.acquirers || [],
      arrangements: entity?.arrangements || [],
      dueDateInit: dueDateInit,
      dueDateEnd: dueDateEnd,
      legalIdReceivingEndUser: entity?.receivingEndUser?.legalId,
    };

    const result = await post(GET_RECEIVABLES_LIST, data);

    setGgList(result);

    setOptinsChart((o) => ({
      ...o,
      labels: result.map((r) => `${r.settlementDate} - ${r.arrangementCode}`),
    }));

    setSeries([
      {
        name: 'Valor Livre',
        data: result.map((r) => r.freeValue),
      },
      {
        name: 'Valor Total',
        data: result.map((r) => r.totalConstitutedValue),
      },
    ]);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        modalClassName='drawer right-align'
        toggle={toggleModal}
        size='xl'
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>{props.t('Register guarante')}</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggleModal}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          <Form>
            <Row>
              <Col sm={3}>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <Select2Acquirers
                      id='selectAcquirersGuarante'
                      required={true}
                      acquirers={acquirers}
                      label={props.t('Acquirer')}
                      onChange={(options) => {
                        handleAcquirers(options);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <Select2Arrangements
                      id='selectArrangementsGuarante'
                      label={props.t('Payment arrangement')}
                      required={true}
                      arrangements={arrangements?.map((arrangement) => ({
                        ...arrangement,
                        label: arrangement?.name,
                        value: arrangement?.code,
                      }))}
                      onChange={(options) => {
                        handleArrangements(options);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <InputDate
                      id='dueDate'
                      required={true}
                      onChange={(event) => {
                        setDueDate({
                          init: event.init?.value,
                          end: event.end?.value,
                        });
                      }}
                      label={props.t('Due Date')}
                      values={[dueDate.init, dueDate.end]}
                      options={{
                        mode: 'range',
                        minDate: moment().format('YYYY-MM-DD'),
                        maxDate: moment().add(1, 'year').format('YYYY-MM-DD'),
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Button
                    color='primary'
                    onClick={() => {
                      getReceivables();
                    }}
                  >
                    {props.t('Update')}
                  </Button>
                </Row>
                <Row>
                  <Col>
                    <Card className='mt-3'>
                      <CardBody>
                        <CardText>
                          <p className='text-muted text-uppercase mb-0'>
                            <strong>Total Selecionado:</strong>
                          </p>
                          <span className='counter-value'>
                            {$filter(amountCharged, 'MONEY')}
                          </span>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col sm={9}>
                <Row>
                  <ReactApexChart
                    options={optionsChart}
                    series={series}
                    type='bar'
                    height='300'
                    dir='ltr'
                    className='apex-charts'
                    stacked={true}
                  />
                </Row>
                <Row>
                  <BootstrapTable
                    keyField='id'
                    data={receivables}
                    columns={[
                      {
                        dataField: 'settlementDate',
                        text: 'Data de Liquidação',
                      },
                      {
                        dataField: 'arrangementCode',
                        text: 'Arranjo de pagamento',
                      },
                      {
                        dataField: 'freeValue',
                        text: 'Valor Livre',
                      },
                      {
                        dataField: 'totalConstitutedValue',
                        text: 'Valor Total',
                      },
                      {
                        dataField: 'nameAcquirer',
                        text: 'Credenciador/sub',
                        formatter: (cell, row) => (
                          <span
                            className='colunm-h-name-wrap'
                            key={row?.id}
                            about={cell}
                            title={cell}
                          >
                            {cell}
                          </span>
                        ),
                      },
                    ]}
                    pagination={paginationFactory({
                      // pageStartIndex: 0,
                      sizePerPage: 10,
                      hideSizePerPage: true,
                      hidePageListOnlyOnePage: true,
                    })}
                    selectRow={{
                      mode: 'checkbox',
                      clickToSelect: true,
                      onSelect: (row, isSelect, rowIndex) => {
                        if (isSelect) {
                          updateGuaranteAmount(row);
                        } else {
                          removeGuaranteAmount(row);
                        }
                        console.info(row, isSelect, rowIndex);
                      },
                      onSelectAll: (isSelect, rows, e) => {
                        console.log(isSelect);
                        console.log(rows);
                        console.log(e);
                      },
                    }}
                    noDataIndication='Não há garantias cadastradas no filtro selecionado'
                  />
                </Row>
              </Col>
            </Row>
          </Form>
        </div>

        <div className='modal-footer'>
          <div className='text-end'>
            <button
              className='btn btn-primary w-sm waves-effect waves-light'
              onClick={save}
            >
              {props.t('Add')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(GarantiasModalConstituidas);
