import React from 'react';
import { Row, Col } from 'reactstrap';

import { CheckoutFinished } from './CheckoutFinished';
import { CheckoutTicketSeller } from './CheckoutTicketSeller';
import { CompleteFieldsToStartCheckout } from './CompleteFieldsToStartCheckout';

export const CheckoutDesktopContent = (props) => {
  return (
    <React.Fragment>
      <Row className='justify-content-center h-100'>
        {!props.documentAlreadySigned ? (
          <Col xl={4} lg={6} md={6} sm={12} xs={12}>
            <CompleteFieldsToStartCheckout
              handleBuyerField={props.handleBuyerField}
              handleBuyerFormField={props.handleBuyerFormField}
              modalDocInSign={props.modalDocInSign}
              isValidToInitCheckout={props.isValidToInitCheckout}
              initCheckout={props.initCheckout}
            />
          </Col>
        ) : (
          <Col xl={4} lg={6} md={6} sm={12} xs={12} className='text-center'>
            <CheckoutFinished entity={props.entity} />
          </Col>
        )}

        <Col className='pr-0'>
          <CheckoutTicketSeller
            entity={props.entity}
            firstLoad={props.firstLoad}
            handlePaymentPeriod={props.handlePaymentPeriod}
            documentAlreadySigned={props.documentAlreadySigned}
            paymentPeriodSelected={props.paymentPeriodSelected}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
