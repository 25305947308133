import * as url from "../../helpers/url_helper";
import { HttpService } from "./HttpService";

export class PlansService extends HttpService {
  constructor(http) {
    super(http)
  }

  async getAll() {
    return this.http.get(`${url.API_GRAVAME}/plan/list/all`)
  }
}