import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { Col, Row } from 'reactstrap';

import moment from 'moment-timezone';

import InputDate from '@cash2pay/components/Common/InputDate';
import Loader from '@cash2pay/components/Common/Loader';
import Select2Generic from '@cash2pay/components/Common/Select2Generic';

import $filter from '../../../../common/utils/$filter';
import ErrorDialog from '../../../../components/Common/ErrorDialog';
import PaginationService from '../../../../components/Common/PaginationService';
import DivisionRuleDomain from '../../../../domains/DivisionRuleDomain';
import PaymentCompanyDomain from '../../../../domains/PaymentCompanyDomain';
import TipoInfoPagamentoDomain from '../../../../domains/TipoInfoPagamentoDomain';
import SelectedReceivaleUnitsActionsBar from './SelectedReceivaleUnitsActionsBar';

function AnalyticalVisionUnits(props) {
  const columns = [
    {
      text: 'Dt. liquidação',
      dataField: 'settlementDate',
      formatter: (cell) => $filter(cell, 'DATE'),
    },
    {
      text: 'Credenciador/sub',
      dataField: 'nameAcquirer',
      formatter: (cell) =>
        cell && cell.length > 17 ? cell.substr(0, 30) + '...' : cell,
    },
    {
      text: 'Arranjo de pagamento',
      dataField: 'nameArrangement',
    },
    {
      text: 'Valor constituido',
      dataField: 'totalConstitutedValue',
      formatter: (cell) => $filter(cell, 'MONEY'),
    },
    {
      text: 'Valor livre',
      dataField: 'freeValue',
      formatter: (cell) => $filter(cell, 'MONEY'),
    },
    {
      text: 'Antecipação Pré',
      dataField: 'anticipationPre',
      formatter: (cell) => $filter(cell, 'MONEY'),
    },
  ];

  const expandRow = {
    renderer: (receivable) => {
      const paymentColumns = [
        {
          text: 'Ordem Liq.',
          dataField: 'contractEffectIndicator',
        },
        {
          text: 'Beneficiário',
          dataField: 'nameRecipient',
          formatter: (cell, row) => (
            <div>
              {cell}
              <br />
              {$filter(row.legalIdRecipient, 'CPF_CNPJ')}
            </div>
          ),
        },
        {
          text: 'Valor a pagar',
          dataField: 'amountToPay',
          formatter: (cell) => $filter(cell, 'MONEY'),
        },
        {
          text: 'Valor onerado',
          dataField: 'encumberedAmount',
          formatter: (cell, row) =>
            row['divisionRules'] === DivisionRuleDomain.PERCENTUAL
              ? `${row.encumberedValuePercent}%`
              : $filter(cell, 'MONEY'),
        },
        {
          text: 'Data de liquidação efetiva',
          dataField: 'effectivePaymentDate',
          formatter: (cell) => $filter(cell, 'DATE'),
        },
        {
          text: 'Valor de liquidação efetiva',
          dataField: 'effectivePaymentAmount',
          formatter: (cell) => $filter(cell, 'MONEY'),
        },
        {
          text: 'Tipo pagamento',
          dataField: 'typeInfoPayment',
          formatter: (cell) => TipoInfoPagamentoDomain.getLabelByValue(cell),
        },
        {
          text: 'Regra de divisão',
          dataField: 'divisionRules',
          formatter: (cell) => DivisionRuleDomain.getLabelByValue(cell),
        },
        {
          text: 'Títular domicílio',
          dataField: 'nameHolder',
          formatter: (cell, row) => (
            <div>
              {cell}
              <br />
              {$filter(row.legalIdHolder, 'CPF_CNPJ')}
            </div>
          ),
        },
        {
          text: 'Instituição domicílio/ISPB',
          dataField: 'bankAccountIspb',
          formatter: (cell) => (
            <div>
              {PaymentCompanyDomain.getLabelByCode(cell)}
              <br />
              {cell}
            </div>
          ),
        },
        {
          text: 'Agência/conta',
          dataField: 'bankAccountBranch',
          formatter: (cell, row) => (
            <div>
              Ag: {row.bankAccountBranch}
              <br />
              CC: {row.bankAccountAcNumber}
            </div>
          ),
        },
      ];

      return (
        <BootstrapTable
          keyField='id'
          data={receivable.payments}
          columns={paymentColumns}
          classes='table table-striped table-bordered font-size-12'
        />
      );
    },
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <b>[-]</b> : <b>[+]</b>,
    expandColumnRenderer: ({ expanded }) =>
      expanded ? <b>[-]</b> : <b>[+]</b>,
  };

  const defaultSettlementDate = {
    init: moment().format('YYYY-MM-DD'),
    end: moment().add(3, 'months').format('YYYY-MM-DD'),
  };

  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState({
    'pagination.currentPage': 1,
    'pagination.itemsPerPage': 25,
    settlementDate: defaultSettlementDate,
    legalIdReceivingEndUser: props.legalIdReceivingEndUser,
    orderBy: 'settlementDate',
    sortOrder: 'ASC',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalOfItems, setTotalOfItems] = useState(0);
  const [receivableUnits, setReceivableUnits] = useState([]);
  const [receivableUnitsSelected, setReceivableUnitsSelected] = useState([]);

  const applySearch = async () => {
    try {
      setLoading(true);

      const { pagerResult, filterResult } =
        await PaginationService.applySearchV3(props.url, 'data', filter);

      // Creating unique key for each payment
      const receivable = pagerResult.results.map((r, _key) => {
        r.payments = r.payments.map((payment, key) => {
          payment.id = key;
          return payment;
        });
      });

      setReceivableUnits(receivable);
      setPager(pagerResult);
      setFilter(filterResult);
      setTotalOfItems(pagerResult?.pagination?.totalOfItems || 0);
      //
    } catch (e) {
      if (e['status'] !== '200' && e['status'] !== '403') setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    applySearch();
  }, []);

  const buildPaginationFactory = () => {
    return paginationFactory({
      onSizePerPageChange: (sizePerPage, page) => {
        filter['pagination.itemsPerPage'] = sizePerPage;
        filter['pagination.currentPage'] = page;
        setFilter(filter);
        applySearch();
      },
      onPageChange: (page, sizePerPage) => {
        filter['pagination.itemsPerPage'] = sizePerPage;
        filter['pagination.currentPage'] = page;
        setFilter(filter);
        applySearch();
      },
      page: filter['pagination.currentPage'],
      sizePerPage: filter['pagination.itemsPerPage'],
      totalSize: totalOfItems,
      showTotal: true,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPaginationV2(filter));
    applySearch();
  };

  const handleChangeAcquirerFilter = (option, _) => {
    filter.legalIdAcquirer = option?.map((acquirer) => acquirer.value);
    setFilter((prev) => ({ ...prev, ...filter }));
  };

  const handleChangeArrangementFilter = (option, _) => {
    filter.arrangementCode = option?.map((arrangement) => arrangement.value);
    setFilter((prev) => ({ ...prev, ...filter }));
  };

  const handleChangeFilterRangeCalendar = (event, field) => {
    if (!event.init.value || !event.end.value) {
      filter[field] = defaultSettlementDate;
    } else {
      filter[field].init = event?.init?.value;
      filter[field].end = event?.end?.value;
    }

    setFilter((prev) => ({ ...prev, ...filter }));
  };

  return (
    <React.Fragment>
      <Form
        id='searchForm'
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Row>
          <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
            <InputDate
              id='settlementDate'
              onChange={(event) =>
                handleChangeFilterRangeCalendar(event, 'settlementDate')
              }
              placeholder='Data da liquidação'
              options={{
                mode: 'range',
                minDate: moment().format('YYYY-MM-DD'),
                maxDate: moment().add(6, 'months').format('YYYY-MM-DD'),
              }}
            />
          </Col>
          <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
            <Select2Generic
              id='nameAcquirer'
              placeholder='Credenciador/sub'
              defaultOptins={(props.acquirers ?? []).map((acquirer) => ({
                label: acquirer.name,
                value: acquirer.legalId,
              }))}
              onChange={(option) =>
                handleChangeAcquirerFilter(option, 'nameAcquirer')
              }
              isMulti
            />
          </Col>
          <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
            <Select2Generic
              id='nameArrangement'
              placeholder='Arranjo de pagamento'
              defaultOptins={(props.arrangements ?? []).map((arrangement) => ({
                label: arrangement.name,
                value: arrangement.code,
              }))}
              onChange={(option) =>
                handleChangeArrangementFilter(option, 'nameArrangement')
              }
              isMulti
            />
          </Col>
          <Col>
            <button
              type='submit'
              className='btn btn-sm btn-light w-sm waves-effect waves-light'
            >
              {props.t('Apply Search')}
            </button>
          </Col>
        </Row>
      </Form>

      <PaginationProvider pagination={buildPaginationFactory()}>
        {({ _paginationProps, paginationTableProps }) => (
          <BootstrapTable
            keyField='id'
            remote={true}
            data={pager.results || []}
            loading={loading}
            columns={columns}
            expandRow={expandRow}
            onTableChange={() => null}
            {...paginationTableProps}
          />
        )}
      </PaginationProvider>

      {receivableUnitsSelected.length > 0 ? (
        <SelectedReceivaleUnitsActionsBar
          receivablesUnits={receivableUnitsSelected}
        />
      ) : (
        ''
      )}

      {loading && <Loader />}

      {error && (
        <ErrorDialog
          title={error['title']}
          onClose={() => {
            setError(null);
          }}
        >
          <p>{error['detail']} </p>
        </ErrorDialog>
      )}
    </React.Fragment>
  );
}

export default withTranslation()(AnalyticalVisionUnits);
