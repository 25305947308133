import React from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Col, Row } from 'reactstrap';

import $filter from '../../../../common/utils/$filter';
import ChartLocale from '../../../../domains/ChartLocale';
import SystemRegisterDomain from '../../../../domains/SystemRegisterDomain';
import SummarizedVisionChart from './SummarizedVisionChart';

const colors = ChartLocale.pt.colors;

const SummarizedVisionUnits = (props) => {
  const acquirerLabels = [];
  const acquirerSeries = [];

  const paymentArrLabels = [];
  const paymentArrSeries = [];

  const modalityLabels = [];
  const modalitySeries = [];

  const acquirer = [];
  const paymentArrangement = [];
  const modality = [];

  if (
    props['entity'] !== null &&
    props['entity'] !== undefined &&
    props.entity['summary'] !== undefined &&
    props.entity['summary'] !== null
  ) {
    const summary = props.entity['summary'];

    if (summary['acquirer']) {
      summary['acquirer'].forEach((el) => {
        acquirer.push(el);
        acquirerLabels.push(el['name']);
        acquirerSeries.push(el['balanceAvailable'] + el['compromisedValue']);
      });
    }
    if (summary['paymentArrangement']) {
      summary['paymentArrangement'].forEach((el) => {
        paymentArrangement.push(el);
        paymentArrLabels.push(el['name']);
        paymentArrSeries.push(el['balanceAvailable'] + el['compromisedValue']);
      });
    }
    if (summary['modality']) {
      summary['modality'].forEach((el) => {
        modality.push(el);
        modalityLabels.push(el['name']);
        modalitySeries.push(el['balanceAvailable'] + el['compromisedValue']);
      });
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <div className='summarized-vision-group mt-3'>
            <Row>
              <h5>{props.t('Acquirer Alias')}</h5>
              <SummarizedVisionChart
                series={acquirerSeries}
                labels={acquirerLabels}
                colors={colors}
              />
            </Row>

            {acquirer.map((item, index) => {
              return (
                <div
                  className='mb-2'
                  style={{
                    padding: 15,
                    border: '2px dashed #e9e9e9',
                    borderRadius: 10,
                  }}
                  key={index}
                >
                  <Row>
                    <Col lg={2}>
                      <Badge
                        style={{
                          backgroundColor: colors[index],
                          color: '#fff',
                          fontSmooth: 'auto',
                          borderRadius: 4,
                          minWidth: 20,
                          width: 'auto',
                          padding: 4,
                          fontSize: 13,
                          border: '1px solid',
                          borderColor: colors[index + 1],
                        }}
                        pill={true}
                      >
                        {SystemRegisterDomain.getLabelByValue(
                          item?.legalIdSysRegisterProvider,
                        )}
                      </Badge>
                    </Col>
                    <Col lg={10}>
                      <h5>{item['name']}</h5>
                    </Col>
                  </Row>
                  <Row className='amount-kpi mb-2'>
                    <Col lg={7}>
                      <span>Valor disponivel</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span>{$filter(item['balanceAvailable'], 'MONEY')}</span>
                    </Col>
                  </Row>
                  <Row className='amount-kpi'>
                    <Col lg={7}>
                      <span>Valor comprometido</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span> {$filter(item['compromisedValue'], 'MONEY')}</span>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Col>
        <Col lg={4}>
          <div className='summarized-vision-group mt-3'>
            <Row>
              <h5>{props.t('Payment arrangement alias')}</h5>
              <SummarizedVisionChart
                series={paymentArrSeries}
                labels={paymentArrLabels}
                colors={colors}
              />
            </Row>

            {paymentArrangement.map((item, index) => {
              return (
                <div
                  className='mb-2'
                  key={index}
                  style={{
                    padding: 15,
                    border: '2px dashed #e9e9e9',
                    borderRadius: 10,
                  }}
                >
                  <Row>
                    <Col lg={1}>
                      <div
                        style={{
                          backgroundColor: colors[index],
                          color: colors[index],
                          borderRadius: 4,
                          width: 20,
                          marginRight: 10,
                        }}
                      >
                        .
                      </div>
                    </Col>
                    <Col lg={11}>
                      <h5>{item['name']}</h5>
                    </Col>
                  </Row>
                  <Row className='amount-kpi mb-2'>
                    <Col lg={7}>
                      <span>Valor disponivel</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span>{$filter(item['balanceAvailable'], 'MONEY')}</span>
                    </Col>
                  </Row>
                  <Row className='amount-kpi'>
                    <Col lg={7}>
                      <span>Valor comprometido</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span>{$filter(item['compromisedValue'], 'MONEY')}</span>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Col>
        <Col lg={4}>
          <div className='summarized-vision-group mt-3'>
            <Row>
              <h5>{props.t('Modality')}</h5>
              <SummarizedVisionChart
                series={modalitySeries}
                labels={modalityLabels}
                colors={colors}
              />
            </Row>

            {modality.map((item, index) => {
              return (
                <div
                  className='mb-2'
                  key={index}
                  style={{
                    padding: 15,
                    border: '2px dashed #e9e9e9',
                    borderRadius: 10,
                  }}
                >
                  <Row>
                    <Col lg={1}>
                      <div
                        style={{
                          backgroundColor: colors[index],
                          color: colors[index],
                          borderRadius: 4,
                          width: 20,
                          marginRight: 10,
                        }}
                      >
                        .
                      </div>
                    </Col>
                    <Col lg={11}>
                      <h5>{item['name']}</h5>
                    </Col>
                  </Row>
                  <Row className='amount-kpi mb-2'>
                    <Col lg={7}>
                      <span>Valor disponivel</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span>{$filter(item['balanceAvailable'], 'MONEY')}</span>
                    </Col>
                  </Row>
                  <Row className='amount-kpi'>
                    <Col lg={7}>
                      <span>Valor comprometido</span>
                    </Col>
                    <Col lg={5} style={{ textAlign: 'right' }}>
                      <span>{$filter(item['compromisedValue'], 'MONEY')}</span>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(SummarizedVisionUnits);
