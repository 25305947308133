import { combineReducers } from 'redux';

import { notificationsReducer } from './interceptor/reduxNotification';
import { layoutReducer } from './layout/reducer';

const rootReducer = combineReducers({
  layout: layoutReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
