import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';

import Select2Generic from '../../../components/Common/Select2Generic';
import SelectAddAccountModal from '../../../components/Common/SelectAddAccountModal';
import AccountTypeDomain from '../../../domains/AccountTypeDomain';
import PaymentCompanyDomain from '../../../domains/PaymentCompanyDomain';
import { post } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';

function GravameBankAccount({
  bankAccounts,
  financialAgent,
  setCreditorAccount,
  ...props
}) {
  const [modalAddBankAccount, setModalAddBankAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const createBankAccount = async ({
    branch,
    ispb,
    accountNumber,
    accountDigit,
    accountType,
    legalId,
  }) => {
    if (!legalId) {
      legalId = financialAgent?.legalId;
    }
    const response = await post(
      `${url.POST_BANK_ADMIN}`,
      { branch, ispb, accountNumber, accountDigit, accountType, legalId },
      {},
      setLoading,
    );

    return response;
  };
  const addAccount = async (modalResult) => {
    if (modalResult['event'] === 'SAVED') {
      if (!bankAccounts) bankAccounts = [];

      const result = await createBankAccount(modalResult['entity']);
      bankAccounts.push(result);
    }
    setModalAddBankAccount(false);
  };

  return (
    <React.Fragment>
      <Row className=''>
        <Col sm={6}>
          <Select2Generic
            id='selectAccount'
            required={true}
            label={'Selecionar Conta'}
            defaultOptins={bankAccounts.map((account) => {
              return {
                value: account.id,
                label: `${PaymentCompanyDomain.getLabelByCode(
                  account?.ispb,
                )} | ${account.branch} | ${account.accountNumber}-${
                  account.accountDigit
                } | ${AccountTypeDomain.getLabelByValue(account.accountType)}`,
              };
            })}
            onChange={(e) => {
              if (e.value) {
                setCreditorAccount(
                  bankAccounts.find((account) => account.id === e.value),
                );
              } else {
                setCreditorAccount({
                  taxIdOwner: '',
                  bankCode: null,
                  accountType: '',
                  branch: '',
                  ispb: '',
                  accountNumber: '',
                  accountDigit: '',
                  ownerName: '',
                  legalId: '',
                });
              }
            }}
          />
        </Col>
        <Col sm={6} className='mt-3'>
          <div className='text-end'>
            <button
              className='btn btn-select'
              onClick={(e) => {
                e.preventDefault();
                setModalAddBankAccount(true);
              }}
            >
              Adicionar conta
            </button>
            {modalAddBankAccount ? (
              <SelectAddAccountModal callback={addAccount} />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default withTranslation()(GravameBankAccount);
