import { Provider } from 'react-redux';

import { Toast } from './libs/toast';
import { AppRoutes } from './routes/AppRoutes';
import store from './store';

import './assets/scss/theme.scss';

export const App = () => {
  return (
    <Provider store={store}>
      {/* <AlertContainer /> */}
      <Toast />
      <AppRoutes />
    </Provider>
  );
};
