import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 *
 * @param {string} type
 * @param {string} message
 */
export const showToast = ({ type = 'info', message }) => {
  toast(message, { type: type });
};

export const Toast = () => (
  <ToastContainer autoClose={4000} position='top-right' theme='light' />
);
