import { all, takeEvery, put } from 'redux-saga/effects';

import { showNotification } from './actions';
import LayoutSaga from './layout/saga';

function* someSaga(_action) {
  try {
    yield put(showNotification('Success', 'success'));
  } catch (error) {
    yield put(showNotification('Ocorreu um erro', 'error'));
  }
}

export default function* rootSaga() {
  yield takeEvery('SOME_ACTION', someSaga);
  yield all([
    //public
    LayoutSaga(),
  ]);
}
