import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import Loader from "../../components/Common/Loader";
import ErrorDialog from "../../components/Common/ErrorDialog";
import $filter from "../../common/utils/$filter";
import { Scrollbars } from "react-custom-scrollbars-2";
import classnames from "classnames";
import OrganizationEditIdentificationData from "./OrganizationEditIdentificationData";
import OrganizationEditBankAccount from "./OrganizationEditBankAccount";
import OrganizationEditContacts from "./OrganizationEditContacts";
import * as url from "../../helpers/url_helper";
import { patch, get, post } from "../../helpers/api_helper";
import OrganizationEditLimit from "./OrganizationEditLimit";
import Swal from "sweetalert2";

function OrganizationEdit(props) {
  const [entity, setEntity] = useState(props.entity);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [innerHeight] = useState(window.innerHeight);

  const [currentTab, setCurrentTab] = useState(1);
  const [tabs] = useState([
    { id: 1, title: props.t("Dados de identificação") },
    { id: 2, title: props.t("Contatos") },
    { id: 3, title: props.t("Contas bancárias") },
    { id: 4, title: props.t("Limit information") },
  ]);

  const [activeOptins, setActiveOptins] = useState([]);

  useEffect(async () => {
    if (!entity || !entity.legalId) return;
    try {
      const response = await get(
        `${url.GET_OPTIN_FULL}/${entity.legalId}`,
        null,
        setLoading
      );
      console.log(response);
      console.log(response.optins);
      if (response && response.optins) {
        setActiveOptins(response.optins);
      }
    } catch (e) {
      setError(e);
    }
  }, [props.entity]);

  const toggle = () => {
    setIsOpen(false);
  };

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: "CLOSE" });
  };

  const close = (newEntity) => {
    props.callback({ isOpen: false, entity: newEntity, event: "UPDATED" });
  };

  const handleChange = (field, value) => {
    setEntity((prevState) => ({ ...prevState, [field]: value }));
  };

  const save = async () => {
    try {
      const newEntity = {
        segment: entity.segment,
        bankAccounts: entity.bankAccounts,
        isPlanOnline: entity.isPlanOnline,
        idAccessPlan: entity.idAccessPlan,
        status: entity.status,
      };
      if (entity.limitInformation) {
        newEntity.limitInformation = entity.limitInformation;
      }
      const response = await patch(
        `${url.PUT_ORGANIZATION}/${entity.legalId}`,
        newEntity,
        null,
        setLoading
      );
      close(response["organization"]);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") setError(e);
    }
  };

  const optoutOptions = () => {
    const optins = activeOptins
      .filter((o) => o.status === "A")
      .map((o) => `<li>${o.requester.name}</li>`);

    const html = `<div>
            <p className="card-title-desc mb-0">
               Os optins dos seguintes agentes/fornecedores estão ativos: <br><br>
            </p>
            <p>
                <ul>
                ${optins}
                </ul>
            </p>
            <br><br>
            <p>Você deja realizar o Optout Completo?</p>
        </div>`;

    Swal.fire({
      title: "Realizar Optouts",
      icon: "info",
      html: html,
      confirmButtonText: "Certo!",
      cancelButtonText: "Cancelar...",
    }).then(async (result) => {
      const references = activeOptins
        .filter((o) => o.status === "A")
        .map((o) => o.externalReference);
      if (result.isConfirmed) {
        try {
          const result = await post(
            url.POST_OPTOUT_FULL,
            { externalReferences: references },
            null,
            setLoading
          );
          console.log(result);
          close(entity);
        } catch (e) {
          setError(e);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        size="xl"
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName="drawer right-align"
        contentClassName="modal-content-scroll"
      >
        <Scrollbars style={{ height: innerHeight - 80 }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <p className="card-title-desc mb-1">
                <small> {props.t("Organization")}</small>
              </p>
              <p className="mb-0">{entity.name}</p>
              <p className="mb-0">
                <small>{$filter(entity.legalId, "CPF_CNPJ")}</small>
              </p>
            </h5>

            <button
              type="button"
              onClick={() => {
                toggle();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col lg={12}>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {tabs.map((tab, index) => {
                    return (
                      <NavItem key={tab.id}>
                        <NavLink
                          className={classnames({
                            active: currentTab === tab.id,
                          })}
                          onClick={() => {
                            setCurrentTab(tab.id);
                          }}
                        >
                          {tab.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={12}>
                <TabContent activeTab={currentTab}>
                  <TabPane tabId={1}>
                    <OrganizationEditIdentificationData
                      entity={entity}
                      handleChange={handleChange}
                      plans={props.plans}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <OrganizationEditContacts
                      entity={entity}
                      handleChange={handleChange}
                    />
                  </TabPane>
                  <TabPane tabId={3}>
                    <OrganizationEditBankAccount
                      entity={entity}
                      handleChange={handleChange}
                    />
                  </TabPane>
                  <TabPane tabId={4}>
                    <OrganizationEditLimit
                      entity={entity}
                      handleChange={handleChange}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
        </Scrollbars>

        <div className="modal-footer fixed-bottom">
          <div className="text-end">
            <button
              className="btn btn-primary w-sm waves-effect waves-light"
              onClick={save}
            >
              {props.t("Save")}
            </button>
          </div>

          <div className="text-end">
            <button
              disabled={
                !activeOptins ||
                !activeOptins.length ||
                !activeOptins.filter((o) => o.status === "A").length
              }
              className="btn btn-primary w-sm waves-effect waves-light"
              onClick={optoutOptions}
            >
              {props.t("Optout Full")}
            </button>
          </div>
        </div>

        {error ? (
          <ErrorDialog
            title={error["title"]}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error["detail"]} </p>
          </ErrorDialog>
        ) : (
          ""
        )}

        {loading ? <Loader loading={loading} /> : " "}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(OrganizationEdit);
