import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

import Swal from 'sweetalert2';

import $filter from '../../../common/utils/$filter';
import DateTimeUtils from '../../../common/utils/DateTimeUtils';
import Select2Async from '../../../components/Common/Select2Async';
import PaymentSchemaTypeDomain from '../../../domains/PaymentSchemaTypeDomain';
import SystemRegisterDomain from '../../../domains/SystemRegisterDomain';
import { get, post } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import BillingStatusBadge from '../billing/BillingStatusBadge';
import GenerateStatusBadge from '../optin/GenerateStatusBadge';
import CardReceivablesDetails from '../serpro/CardReceivablesDetails';
import SerproLegalIdDetails from '../serpro/SerproLegalIdDetails';

function GravameSelectContract({
  selecteContractor,
  setContractor,
  contractor,
  setSelectedContractor,

  ...props
}) {
  const [error, setError] = useState(null);
  const [loadingContractor, setLoadingContractor] = React.useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  const setSyncDados = async (entity, index) => {
    try {
      await post(
        url.POST_OPTIN_SYNC_DATA,
        {
          externalReference: entity.optins[index].externalReference,
          id: entity.optins[index].id,
        },
        null,
        setLoadingContractor,
      );
      props.afterClosed();
    } catch (e) {
      if (e['status'] !== '200') setError(e);
    }
  };
  const openSyncnizeDataCerc = (entity, index) => {
    const html = `<div>
                <p>Gostaria de Sinconizar os dados do cliente <br/>
                    <strong>${
                      entity?.receivingEndUser?.name ||
                      entity?.receivingEndUser?.legalId ||
                      entity?.legalIdReceivingEndUser
                    }</strong><br/>
                    via CERC e SERPRO.
                </p>
            </div>`;

    Swal.fire({
      title: 'Sincronizar os Dados',
      icon: 'question',
      html: html,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: props.t('Confirm'),
      cancelButtonText: props.t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        setSyncDados(entity, 0);
      }
    });
  };
  const getContractor = async (legalId) => {
    if (!legalId) return;
    const response = await get(
      `${url.GET_PEOPLE_ADMIN_BY_ID}/${legalId}`,
      null,
      {},
      setLoadingContractor,
    );

    return response;
  };

  useEffect(() => {
    if (selecteContractor) {
      getContractor(selecteContractor)
        .then((response) => {
          setContractor(response);
        })
        .finally(() => {
          setLoadingContractor(false);
        });
    }
  }, [selecteContractor]);

  const SelectClientNotFound = ({ footer }) => {
    return (
      <div className='p-3 rounded mb-3'>
        <blockquote className='blockquote font-size-16 mb-0'>
          <p>Selecione um Cliente</p>
          <footer className='blockquote-footer'>
            {footer || 'Selecione um cliente para visualizar os detalhes'}
          </footer>
        </blockquote>
      </div>
    );
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <CardTitle>Selecionar Cliente "Contratante"</CardTitle>
        </Row>
        <Row>
          <Col sm={12}>
            <Row>
              <FormGroup>
                <Select2Async
                  url={url.GET_PAG_PEOPLE}
                  orderBy={'name'}
                  onChange={(event) => {
                    if (event?.value) {
                      // entity.contractor.legalId = event?.value;
                      // entity.contractor.name = event?.label;
                      setContractor({
                        legalId: event?.value,
                        name: event?.label,
                      });
                      setSelectedContractor(event.value);
                    } else {
                      setContractor(null);
                      setSelectedContractor(null);
                    }
                  }}
                  id='contractorLegalId'
                  placeholder={'Busque pelo CNPJ ou Nome do Cliente Final'}
                  // isDisabled={!enableInputs?.cnpj}
                  value={
                    contractor?.legalId
                      ? {
                          value: contractor?.legalId || '',
                          label: contractor?.name || '',
                        }
                      : null
                  }
                />
              </FormGroup>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row className='mt-3'>
              <Col sm={12}>
                <CardTitle>Detalhes do Cliente</CardTitle>
                {contractor.legalId ? (
                  <SerproLegalIdDetails
                    entity={{
                      receivingEndUserDetails:
                        contractor.receivingEndUserDetails,
                      receivingEndUser: {
                        legalId: contractor?.legalId,
                        name: contractor?.name,
                      },
                    }}
                  />
                ) : (
                  <SelectClientNotFound />
                )}
              </Col>

              <Col sm={12}>
                <CardTitle>Detalhes dos recebiveis do Cliente </CardTitle>
                {contractor?.receivableDetails ? (
                  <>
                    <CardReceivablesDetails
                      receivable={contractor?.receivableDetails}
                    />
                    <Card>
                      <Table className='table table-striped mb-0 font-size-14'>
                        <tbody>
                          <tr>
                            <td className='col-md-4'>
                              <p className='card-title-desc mb-2'>
                                {props.t('Acquirer')}
                              </p>
                            </td>
                            <td className='col-md-2'>
                              <p>{props.t('Legal ID Acquirer')}</p>
                            </td>
                            <td className='col-md-3'>
                              <p>Valor disponivel</p>
                            </td>
                            <td className='col-md-3'>
                              <p>Valor indisponivel</p>
                            </td>
                          </tr>

                          {(contractor?.acquirers || []).map((ac, index) => {
                            const registes = SystemRegisterDomain.options.find(
                              (f) => f.value === ac.legalIdSysRegisterProvider,
                            );
                            return (
                              <tr key={index}>
                                <td className='col-md-4'>
                                  <p>
                                    {ac.name} -{' '}
                                    <Badge
                                      pill={true}
                                      className={`badge rounded-pill font-size-12`}
                                      color={registes?.color}
                                      style={{
                                        backgroundColor: registes?.color,
                                      }}
                                    >
                                      {registes?.label}
                                    </Badge>
                                  </p>
                                </td>
                                <td className='col-md-2'>
                                  <p>{$filter(ac.legalId, 'CPF_CNPJ')}</p>
                                </td>
                                <td className='col-md-3'>
                                  <p>
                                    {$filter(ac?.balanceAvailable, 'MONEY')}
                                  </p>
                                </td>
                                <td className='col-md-3'>
                                  <p>
                                    {$filter(ac?.compromisedValue, 'MONEY')}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                          {contractor['acquirers'] === null ||
                          contractor['acquirers'] === undefined ||
                          contractor['acquirers'].length === 0 ? (
                            <tr>
                              <td className='col-md-8'>{props.t('All')}</td>
                              <td className='col-md-4'></td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </Card>
                    <Card>
                      <Table className='table table-striped mb-0 font-size-14'>
                        <tbody>
                          <tr>
                            <td className='col-md-4'>
                              <p className='card-title-desc mb-2'>
                                {props.t('Payment arrangement')}
                              </p>
                            </td>
                            <td className='col-md-2'>
                              <p>Codigo</p>
                            </td>
                            <td className='col-md-3'>
                              <p>Valor disponivel</p>
                            </td>
                            <td className='col-md-3'>
                              <p>Valor indisponivel</p>
                            </td>
                          </tr>
                          {(contractor?.arrangements || []).map((ar, index) => (
                            <tr>
                              <td className='col-md-4'>
                                <p>{ar.name}</p>
                              </td>
                              <td className='col-md-2'>{ar.code}</td>
                              <td className='col-md-3'>
                                {$filter(ar?.balanceAvailable, 'MONEY')}
                              </td>
                              <td className='col-md-3'>
                                {$filter(ar?.compromisedValue, 'MONEY')}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Button
                        color='primary'
                        onClick={() => openSyncnizeDataCerc(contractor)}
                      >
                        Sincronizar dados
                      </Button>
                    </Card>
                  </>
                ) : (
                  <SelectClientNotFound
                    footer={
                      'Selecione um cliente pra ter acesso aos recebiveis do cliente'
                    }
                  />
                )}
              </Col>
              <Col
                sm={12}
                style={{
                  overflowX: 'auto',
                }}
              >
                <CardTitle>Optin</CardTitle>
                {contractor?.optins?.length ? (
                  <BootstrapTable
                    keyField='externalReference'
                    data={contractor.optins || []}
                    columns={[
                      {
                        dataField: 'externalReference',
                        text: 'Codigo de Referência',
                      },
                      {
                        dataField: 'startDate',
                        text: props.t('Start date'),
                        formatter: (cell, row) => (
                          <span key={`${row.id}-startDate`}>
                            {DateTimeUtils.format(cell, 'DD/MM/YYYY')}
                          </span>
                        ),
                      },
                      {
                        dataField: 'endDate',
                        text: props.t('End date'),
                        formatter: (cell, row) => (
                          <span key={`${row.id}-endDate`}>
                            {DateTimeUtils.format(cell, 'DD/MM/YYYY')}
                          </span>
                        ),
                      },
                      {
                        dataField: 'status',
                        text: 'Status',
                        formatter: (cell, row) => (
                          <GenerateStatusBadge status={cell} id={row.id} />
                        ),
                      },
                      {
                        dataField: 'nameFinancialAgent',
                        text: 'Nome Agente Financeiro',
                      },
                    ]}
                    pagination={paginationFactory()}
                  />
                ) : (
                  <SelectClientNotFound
                    footer={
                      'Selecione um cliente pra ter acesso a listagem de optins do cliente'
                    }
                  />
                )}
              </Col>
              <Col
                sm={12}
                style={{
                  overflowX: 'auto',
                }}
              >
                <CardTitle>Boletos</CardTitle>
                {contractor?.billings?.length ? (
                  <BootstrapTable
                    keyField='id'
                    data={contractor?.billings || []}
                    columns={[
                      {
                        dataField: 'id',
                        text: 'Boleto Id',
                      },

                      {
                        dataField: 'dueDate',
                        text: 'Data Vencimento',
                        formatter: (cell, row) => (
                          <span id={row}>{$filter(cell, 'DATE')}</span>
                        ),
                      },
                      {
                        dataField: 'amount',
                        text: 'Valor Boleto',
                        formatter: (cell, row) => (
                          <span id={row}>{$filter(cell, 'MONEY')}</span>
                        ),
                      },

                      {
                        dataField: 'billingNum',
                        text: 'Número Da Parcela',
                      },

                      {
                        dataField: 'statusBilling',
                        text: 'Status Boleto',
                        formatter: (cell, row) => (
                          <BillingStatusBadge key={row.id} status={cell} />
                        ),
                      },
                    ]}
                    pagination={paginationFactory()}
                  />
                ) : (
                  <SelectClientNotFound
                    footer={
                      'Selecione um cliente pra ter acesso a listagem de boeltos do cliente'
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default withRouter(withTranslation()(GravameSelectContract));
