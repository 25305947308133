import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import $filter from "../../common/utils/$filter";

const ParamValueView = (props) => {

    const withType = (val, tpe) => {
        switch (tpe) {
            case 'date':
                return handleDate(val);
            case 'real':
                return handleReal(val);
            case 'percent':
                return handlePercent(val);
            case 'boolean':
                return handleBoolean(val);
            default:
                return val;
        }
    }

    const handleBoolean = (val) => {
        return $filter(val, 'BOOLEAN')
    }

    const handlePercent = (val) => {
        return $filter(val, 'PRECENT')
    }

    const handleDate = (val) => {
        return $filter(val, 'DATE')
    }

    const handleReal = (val) => {
        return $filter(val, 'MONEY')
    }

    return (<>
        <span>{withType(props.keyValue, props.paramType)}</span>
    </>)
}
export default withRouter(withTranslation()(ParamValueView));