import React from 'react';

//Import Breadcrumb
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import $filter from '../../common/utils/$filter';
import TablePagination from '../../components/Common/TablePagination';
import LiquidationStatusDomain from '../../domains/LiquidationStatusDomain';

function TableLiquidationsByDebitor(props) {
  return (
    <React.Fragment>
      <TablePagination
        pager={props.pager}
        applySearch={props.applySearch}
        loading={props.loading}
      >
        <thead className='table-light'>
          <tr>
            <th className='col-md-1'>{props.t('Status')}</th>
            <th className='col-md-2'>{props.t('Due date')}</th>
            <th className='col-md-2'>{props.t('Debitor')}</th>
            <th className='col-md-1'>{props.t('Amount receivable')}</th>
            <th className='col-md-1'>{props.t('Amount received')}</th>
          </tr>
        </thead>
        <tbody>
          {(props.pager.results || []).map((entity, key) => (
            <tr>
              <td>
                <span
                  className={LiquidationStatusDomain.getClassFromValue(
                    entity.status,
                  )}
                >
                  {LiquidationStatusDomain.getNameFromValue(entity.status)}
                </span>
              </td>
              <td>{$filter(entity.dueDate, 'DATE')}</td>
              <td>
                {entity.debitor.name} <br />
                <span className='card-title-desc font-size-12'>
                  {entity.modality}
                </span>
              </td>
              <td>{$filter(entity.amountReceivable, 'MONEY')}</td>
              <td>{$filter(entity.amountReceived, 'MONEY')}</td>
            </tr>
          ))}
        </tbody>
      </TablePagination>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(TableLiquidationsByDebitor));
