import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col, NavItem, NavLink, Nav, Row } from 'reactstrap';

import classnames from 'classnames';

import Loader from '@cash2pay/components/Common/Loader';
import * as url from '@cash2pay/helpers/url_helper';

import { useReceivableUnitsTabs } from '../hooks';
import AnalyticalVisionUnits from './AnalyticalVisionUnits';
import EndUserObligationsView from './EndUserObligationsView';
import SummarizedVisionUnits from './SummarizedVisionUnits';

function ReceivablesUnitsAnalytcs({ t, receivable, legalIdReceivingEndUser }) {
  const { isLoading, tabs, units, activeTab, selectTab } =
    useReceivableUnitsTabs({ t: t, receivable: receivable });

  const acquirers = receivable.summary.acquirer;
  const arrangements = receivable.summary.paymentArrangement;

  const tabsContent = useMemo(
    () => ({
      [tabs[0].value]: () => <SummarizedVisionUnits entity={receivable} />,
      [tabs[1].value]: () => (
        <AnalyticalVisionUnits
          url={url.RECEIVABLES_AVAILABLE}
          legalIdReceivingEndUser={legalIdReceivingEndUser}
          acquirers={acquirers}
          arrangements={arrangements}
        />
      ),
      [tabs[2].value]: () => (
        <AnalyticalVisionUnits
          url={url.RECEIVABLES_COMPROMISED}
          legalIdReceivingEndUser={legalIdReceivingEndUser}
          acquirers={acquirers}
          arrangements={arrangements}
        />
      ),
      [tabs[3].value]: () => (
        <AnalyticalVisionUnits
          url={url.FUTURE_COMMITMENTS}
          legalIdReceivingEndUser={legalIdReceivingEndUser}
          acquirers={acquirers}
          arrangements={arrangements}
        />
      ),
      [tabs[4].value]: () => (
        <EndUserObligationsView endUserObligations={units.obligations} />
      ),
    }),
    [receivable, units],
  );

  const ActiveTabContent = tabsContent[activeTab];

  return (
    <>
      <Card>
        <CardBody>
          <Row className='mb-3'>
            <Col xl={12}>
              <Nav className='nav-tabs-custom nav-justified'>
                {tabs.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      className={classnames({
                        active: activeTab === tab.value,
                      })}
                      onClick={() => selectTab(tab.value)}
                      data-bs-toggle='tab'
                      role='tab'
                    >
                      <span>{tab.title}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <ActiveTabContent />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {isLoading && <Loader loading={isLoading} />}
    </>
  );
}

export default withTranslation()(ReceivablesUnitsAnalytcs);
