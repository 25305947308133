import { Redirect } from 'react-router-dom';

import { USER_PROFILES } from '@cash2pay/domains/UserProfileDomain';
import { getSessionUserProfile } from '@cash2pay/helpers/api_helper';
// Authentication related pages
import BillingV2 from '@cash2pay/pages/Admin/billing/Billing';
import GravameV2 from '@cash2pay/pages/Admin/gravame/Gravame';
import GravameNewV2 from '@cash2pay/pages/Admin/gravame/GravameNewV2';
import OptinV2 from '@cash2pay/pages/Admin/optin/Optin';
import OrderV2 from '@cash2pay/pages/Admin/order/Order';
import AccessManagement from '@cash2pay/pages/Authentication/AccessManagement';
// Opt-In
import Checkout from '@cash2pay/pages/Checkout/index';
import PerformConciliation from '@cash2pay/pages/Conciliation/PerformConciliation';
import PerformConciliationEdit from '@cash2pay/pages/Conciliation/PerformConciliationEdit';
import ManageContracts from '@cash2pay/pages/Contracts/ManageContracts';
import ManageContractsEdit from '@cash2pay/pages/Contracts/ManageContractsEdit';
import ManageContractsView from '@cash2pay/pages/Contracts/ManageContractsView';
import Dashboard from '@cash2pay/pages/Dashboard/index';
import ManageDisputesReceived from '@cash2pay/pages/Disputes/ManageDisputesReceived';
import ManageDisputesReceivedEdit from '@cash2pay/pages/Disputes/ManageDisputesReceivedEdit';
import ManageSubmittedDisputes from '@cash2pay/pages/Disputes/ManageSubmittedDisputes';
import ManageSubmittedDisputesEdit from '@cash2pay/pages/Disputes/ManageSubmittedDisputesEdit';
import ImportFile from '@cash2pay/pages/Files/ImportFile';
import SearchFile from '@cash2pay/pages/Files/SearchFile';
import ShippingFile from '@cash2pay/pages/Files/ShippingFile';
import GlobalParams from '@cash2pay/pages/GlobalParams/GlobalParams';
import HomeMenu from '@cash2pay/pages/HomeMenu/HomeMenu';
import ManageLiquidations from '@cash2pay/pages/Liquidations/ManageLiquidations';
import ManageOptIn from '@cash2pay/pages/Opt-in/ManageOptIn';
// Receivables
import Order from '@cash2pay/pages/Order/Order';
import Organization from '@cash2pay/pages/Organization/Organization';
import ReceivablesScheduleDetail from '@cash2pay/pages/Receivables/ReceivablesScheduleDetail';
import SearchReceivablesSchedule from '@cash2pay/pages/Receivables/SearchReceivablesSchedule';
// Contracts
// Searches
import SearchParticipants from '@cash2pay/pages/Searches/SearchParticipants';
import SearchPaymentArrangement from '@cash2pay/pages/Searches/SearchPaymentArrangement';
// Users
import Segments from '@cash2pay/pages/Segment/Segments';
import Users from '@cash2pay/pages/User/Users';
// Conciliation
// Disputes
// Files
// Dashboard
// Organization
// Liquidations
import Error403 from '@cash2pay/pages/Utility/Error403';
import Error404 from '@cash2pay/pages/Utility/Error404';

const endUserRoutes = [
  { path: '/receivables/user', component: ReceivablesScheduleDetail },
  { path: '/contracts/manage-contracts', component: ManageContracts },
  { path: '/contracts/edit/:id', component: ManageContractsView },
  { path: '/home-menu', component: HomeMenu },

  // Users
  { path: '/users', component: Users },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/receivables/user' />,
  },
  { path: '*', component: () => <Redirect to='/receivables/user' /> },

  { path: '/login', component: AccessManagement },
];

const financialRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/home-menu', component: HomeMenu },

  // Opt-in
  { path: '/opt-in', component: ManageOptIn },

  // Receivable
  { path: '/receivables', component: SearchReceivablesSchedule },
  { path: '/receivables/:id', component: ReceivablesScheduleDetail },

  // Contracts
  { path: '/contracts/manage-contracts', component: ManageContracts },
  { path: '/contracts/simulate-contracts', component: ManageContracts },
  { path: '/contracts/new', component: ManageContractsEdit },
  { path: '/contracts/edit/:id', component: ManageContractsView },

  // Searches
  { path: '/searches/search-participants', component: SearchParticipants },
  {
    path: '/searches/search-payment-arrangement',
    component: SearchPaymentArrangement,
  },

  // Conciliation
  { path: '/perform-conciliation', component: PerformConciliation },
  { path: '/perform-conciliation/new', component: PerformConciliationEdit },
  {
    path: '/perform-conciliation/edit/:id',
    component: PerformConciliationEdit,
  },

  // Files
  { path: '/files/import-file', component: ImportFile },
  { path: '/files/search-file', component: SearchFile },
  { path: '/files/shipping-file', component: ShippingFile },

  // Disputes
  {
    path: '/disputes/manage-disputes-received',
    component: ManageDisputesReceived,
  },
  {
    path: '/disputes/manage-submitted-disputes',
    component: ManageSubmittedDisputes,
  },
  { path: '/disputes/new', component: ManageSubmittedDisputesEdit },
  { path: '/disputes/edit/:id', component: ManageSubmittedDisputesEdit },
  {
    path: '/disputes/received-disputes/edit/:id',
    component: ManageDisputesReceivedEdit,
  },

  // Users
  { path: '/users', component: Users },

  // Organization
  { path: '/organizations', component: Organization },

  // Global Params
  { path: '/global-params', component: GlobalParams },

  // Segment
  { path: '/segments', component: Segments },

  { path: '/billing', component: Order },

  // Liquidations
  { path: '/liquidations', component: ManageLiquidations },
  { path: '/admin/gravame/new', component: GravameNewV2 },
  { path: '/admin/gravame', component: GravameV2 },
  { path: '/admin/opt-in', component: OptinV2 },
  { path: '/admin/billing', component: BillingV2 },
  { path: '/admin/order', component: OrderV2 },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
  { path: '*', component: () => <Redirect to='/dashboard' /> },

  { path: '/login', component: AccessManagement },
];

const sellerRoutes = [
  { path: '/home-menu', component: HomeMenu },
  { path: '/billing', component: Order },

  // Receivable
  { path: '/customers', component: SearchReceivablesSchedule },
  { path: '/receivables/:id', component: ReceivablesScheduleDetail },

  // Users
  { path: '/users', component: Users },

  { path: '/', exact: true, component: () => <Redirect to='/customers' /> },
  { path: '*', component: () => <Redirect to='/customers' /> },
  { path: '/login', component: AccessManagement },
];

const PROFILE_ROUTE_DOMAIN = {
  END_USER: endUserRoutes,
  FINANCIAL: financialRoutes,
  SELLER: sellerRoutes,
  REGISTER_AGENT: financialRoutes,
};

const userProfile = getSessionUserProfile();
const userProfileRoute = PROFILE_ROUTE_DOMAIN[userProfile];

export const authRoutes = [
  { path: '/login', component: AccessManagement },
  { path: '/access-management', component: AccessManagement },
  { path: '/checkout/:orderReference', component: Checkout },

  { path: '/pages-404', component: Error404 },
  { path: '/access-denied', component: Error403 },
];

export const defaultUserRoutes = [
  { path: '/', exact: true, component: () => <Redirect to='/login' /> },
  { path: '*', component: () => <Redirect to='/login' /> },
];

export const userRoutes = userProfileRoute
  ? userProfileRoute
  : defaultUserRoutes;

export const getHome = () => {
  const userProfile = getSessionUserProfile();

  switch (userProfile) {
    case USER_PROFILES.USER: {
      return '/receivables/user';
    }
    case USER_PROFILES.FINANCIAL: {
      return '/home-menu';
    }
    case USER_PROFILES.SELLER: {
      return '/customers';
    }
    case USER_PROFILES.ADM: {
      return '/home-menu';
    }
    default: {
      return '/login';
    }
  }
};
