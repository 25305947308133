import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row, Modal, Alert, TabContent, TabPane } from 'reactstrap';

import DateTimeUtils from '@cash2pay/common/utils/DateTimeUtils';

import posImg from '../../assets/images/features/pos.png';
import $filter from '../../common/utils/$filter';
import StringUtils from '../../common/utils/StringUtils';
import ErrorDialog from '../../components/Common/ErrorDialog';
import FormUtils from '../../components/Common/FormUtils';
import GenericModal from '../../components/Common/GenericModal';
import InputDate from '../../components/Common/InputDate';
import InputMoney from '../../components/Common/InputMoney';
import InputText from '../../components/Common/InputText';
import Loader from '../../components/Common/Loader';
import ReceiptPlanBox from '../../components/Common/ReceiptPlanBox';
import Select2PaymentPeriod from '../../components/Common/Select2PaymentPeriod';
import SelectAddAccountModal from '../../components/Common/SelectAddAccountModal';
import SelectCustomerModal from '../../components/Common/SelectCustomerModal';
import SelectEndUserReceivables from '../../components/Common/SelectEndUserReceivables';
import SelectTextAreaModal from '../../components/Common/SelectTextAreaModal';
import PaymentCompanyDomain from '../../domains/PaymentCompanyDomain';
import { get, post } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';

function OrderNew(props) {
  const [state, setState] = useState({
    entity: {
      expectedDeliveryDate: null,
      invoiceNumber: null,
      buyer: {
        legalId: null,
      },
      amount: null,
      message: null,
      mainAmount: 0.0,
      paymentMethod: null,
      deadline: null,
      tax: 0.0,
      lastUpdateDate: null,
      additionalInfo: {},
    },
    paymentPeriods: [],
    loading: false,
    error: null,
    form: {
      legalIdBuyer: { isValid: false },
    },
  });

  const [bankAccount, setBankAccount] = useState({});

  const [innerHeight] = useState(window.innerHeight);

  const [modalSelectCustomer, setModalSelectCustomer] = useState(false);
  const [modalReceiptPlan, setModalReceiptPlan] = useState(false);
  const [modalReceiptPlanIsOPen, setModalReceiptPlanIsOPen] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [
    openModalSelectEndUserReceivables,
    setOpenModalSelectEndUserReceivables,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [error, setError] = useState(null);
  const [productsTax, setProductsTax] = useState(null);
  const [firstLoded, setFirstLoded] = useState(false);

  const [currentTab, setCurrentTab] = useState('SIMULATE');

  const [openModalCreditorAccount, setOpenModalCreditorAccount] =
    useState(false);
  const [enableCreditorAccountEdit, setEnableCreditorAccountEdit] =
    useState(false);

  const isValid = () => {
    return FormUtils.isValid(state.form);
  };

  const toggle = () => {
    setIsOpen(false);
  };

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: 'CLOSE' });
  };

  const close = (newEntity) => {
    props.callback({ isOpen: false, entity: newEntity, event: 'SAVED' });
  };

  const onChangeValidation = (field, isValid) => {
    state.form[field] = { isValid: isValid };
    setState(Object.assign({}, state));
  };

  const handleFieldEntity = (name, value) => {
    state.entity[name] = value;
    setState(Object.assign({}, state));
  };

  const handleReceiptPlan = (plan) => {
    state.entity['paymentMethod'] = plan && plan['paymentMethod'];
    state.entity['tax'] = plan ? plan['tax'] : null;
    state.entity['deadline'] = null;
    state.paymentPeriodSelected = null;
    setState(Object.assign({}, state));
  };

  const handleAmount = (event) => {
    state.entity['amount'] = normalizedFieldValue(event.target.value);
    state.entity.additionalInfo['receivables'] = [];

    state.entity = calcInstallments(state.entity);

    setState({ ...state });
  };

  const calcInstallments = (entity) => {
    if (entity['deadline']) {
      entity['numberInstallments'] = entity['deadline'].split('/').length;
      entity['installmentMainAmount'] = parseFloat(
        (entity.amount / entity['numberInstallments']).toFixed(2),
      );
    }
    return entity;
  };

  const handlePaymentPeriod = (paymentPeriod) => {
    state.entity['deadline'] = paymentPeriod
      ? paymentPeriod['paymentPeriod']
      : null;
    state.paymentPeriodSelected = paymentPeriod;

    state.entity = calcInstallments(state.entity);
    setState({ ...state });
  };

  const normalizedFieldValue = (value) => {
    return value !== null && value !== undefined && value.toString().length > 0
      ? value
      : null;
  };

  const validate = () => {
    if (!(state.entity.buyer && state.entity.buyer.name)) {
      setError({
        title: 'Nenhum cliente selecionado',
        detail: 'Selecione um cliente para prosseguir',
        code: 400,
      });
      return;
    }

    if (!(state.entity.amount > 0)) {
      setError({
        title: 'Valor a cobrança inválido',
        detail: 'Informe o valor da cobrança para prosseguir',
        code: 400,
      });
      return false;
    }

    if (
      state.entity.paymentMethod === null ||
      state.entity.paymentMethod === undefined
    ) {
      setError({
        title: 'Nenhuma forma de recebimento informada',
        detail: 'Informe como deseja receber',
        code: 400,
      });
      return false;
    }

    if (
      state.entity.paymentMethod === 'CASH.FLOW' &&
      (!state.entity.additionalInfo ||
        !state.entity.additionalInfo['receivables'] ||
        state.entity.additionalInfo['receivables'].length === 0)
    ) {
      setError({
        title: 'Nenhum recebível informado',
        detail: 'Informe os recebíveis para prosseguir com o Cash.Flow',
        code: 400,
      });
      return false;
    }
    if (
      (state.entity.paymentMethod === 'BOLETO_GARANTIDO' ||
        state.entity.paymentMethod === 'CASH.RECUPERA' ||
        state.entity.paymentMethod === 'CASH.NOW') &&
      state.entity['deadline'] === null
    ) {
      setError({
        title: 'Nenhum período de pagamento informado',
        detail: 'Informe um período de pagamento para prosseguir.',
        code: 400,
      });
      return false;
    }

    // if (state.entity.paymentMethod === "CASH.NOW" ) {
    //     if (state.entity.amount > state.entity.buyer.availableLimit) {
    //         setError({
    //             title: 'Limite insuficiente',
    //             detail: 'Limite insuficiente para realização desta operação',
    //             code: 400,
    //         })
    //         return false
    //     }
    // }

    // if (state.entity.paymentMethod === "CASH.RECUPERA" ) {
    //     if (!state.entity.baseDate) {
    //         setError({
    //             title: 'Data base',
    //             detail: 'Data base nao informada',
    //             code: 400,
    //         })
    //         return false
    //     }
    // }

    // if (state.entity.paymentMethod !== "CASH.RECUPERA" && state.entity['deadline']){
    //     if (state.entity.amount > state.entity.buyer.availableLimit ) {
    //         setError({
    //             title: 'Limite insuficiente',
    //             detail: 'Limite insuficiente para realização desta operação',
    //             code: 400,
    //         })
    //         return false
    //     }
    //     if (state.entity.installmentMainAmount > state.entity.buyer.installmentCreditLimit ) {
    //         setError({
    //             title: 'Limite parcela insuficiente',
    //             detail: 'Limite parcela insuficiente para realização desta operação',
    //             code: 400,
    //         })
    //         return false
    //     }
    // }
    return true;
  };

  const next = async () => {
    if (state.loading) return;
    if (!validate()) return;

    setCurrentTab('SUMMARY');
  };

  const prior = async () => {
    setCurrentTab('SIMULATE');
  };

  const save = async () => {
    if (state.loading) return;

    // if (!this.isValid(this.state.form)) return

    if (!validate()) return;

    try {
      const newEntity = Object.assign({}, state.entity);

      if (state.entity.paymentMethod === 'CASH.FLOW') {
        newEntity['additionalInfo']['creditorAccount'] = bankAccount;
      }

      const response = await post(url.POST_ORDER, newEntity, null, setLoading);
      close(response);
    } catch (e) {
      setError(e);
    }
  };

  const openModalSelectCustomer = () => {
    setModalSelectCustomer(true);
  };

  const openModalSelectReceiptPlan = () => {
    setModalReceiptPlan(true);
    setModalReceiptPlanIsOPen(true);
  };

  const closeModalMessage = (resultModal) => {
    if (resultModal['entity'] && resultModal['entity']['message']) {
      handleFieldEntity('message', resultModal['entity']['message']);
    }
    setOpenModalMessage(false);
  };

  const closeModalSelectReceivables = (resultModal) => {
    if (
      resultModal['event'] &&
      resultModal['event'] &&
      resultModal['event'] === 'SELECTED'
    ) {
      if (resultModal['entity'] && resultModal['entity'].length > 0) {
        state.entity.additionalInfo['receivables'] = resultModal['entity'].map(
          (ur) => {
            return {
              acquirer: {
                legalId: ur.legalIdAcquirer,
                name: ur.nameAcquirer,
              },
              arrangement: {
                code: ur.arrangementCode,
                name: ur.nameArrangement,
              },
              receivingEndUser: {
                legalId: ur.legalIdReceivingEndUser,
                name: ur.nameEndUser,
              },
              holder: {
                legalId: ur.legalIdReceivingEndUser,
                name: ur.nameEndUser,
              },
              dueDate: ur.settlementDate,
              amountCharged: ur.amountCharged,
            };
          },
        );
      }
      setState(Object.assign({}, state));
    }
    setOpenModalSelectEndUserReceivables(false);
  };

  const closeModalSelectCustomer = (resultModal) => {
    setModalSelectCustomer(false);

    if (resultModal['event'] === 'SELECTED') {
      handleFieldEntity('buyer', {
        legalId: resultModal['entity']['receivingEndUser']['legalId'],
        name: resultModal['entity']['receivingEndUser']['name'],
        availableLimit: resultModal['entity']['creditLimit'],
        installmentCreditLimit: resultModal['entity']['installmentCreditLimit'],
        balanceAvailable: resultModal['entity']['balanceAvailable'],
      });
      handleFieldEntity(
        'lastUpdateDate',
        resultModal['entity']['lastUpdateDate'],
      );
    }
  };

  const setPaymentPeriodOptionFields = (option) => {
    option['amount'] = state.entity.amount;
    option['installmentMainAmount'] = parseFloat(
      (state.entity.amount / option['numberInstallments']).toFixed(2),
    );

    option['invalidMessage'] = null;

    // if (state.entity.paymentMethod !== 'CASH.RECUPERA' &&
    //   option['amount'] >= state.entity.buyer.availableLimit)
    //     option['invalidMessage'] = `Limite insuficiente`
    //
    // else if (state.entity.paymentMethod !== 'CASH.RECUPERA' &&
    //   option['installmentMainAmount'] >= state.entity.buyer.installmentCreditLimit)
    //     option['invalidMessage'] = `Limite parcela insuficiente`

    return option;
  };

  const closeModalReceiptPlan = (resultModal) => {
    setModalReceiptPlan(false);
  };

  const getProductsTax = async () => {
    const response = await get(
      `${url.GET_SEGMENT_COMPANY}?pagination.currentPage=0&pagination.itemsPerPage=100`,
      null,
      null,
      null,
      null,
    );
    if (response && response['segment']) {
      const prodOptions = {};
      for (const i in response['segment']['products']) {
        const prod = response['segment']['products'][i];

        if (prod.active) {
          prodOptions[prod.product] = {
            tax: prod.tax,
            paymentMethod: prod.product,
          };

          prodOptions[prod.product]['paymentPeriods'] = prod.paymentPeriod.map(
            (pp) => {
              const numberInstallments = pp.split('/').length;

              return {
                paymentPeriod: pp,
                numberInstallments: numberInstallments,
              };
            },
          );
        }
      }
      setProductsTax(prodOptions);
    } else {
      setProductsTax({});
    }
  };

  const [productTemplate] = useState({
    'CASH.NOW': {
      icon: 'fas fa-dollar-sign',
      title: 'Cash.Now',
      detail: props.t('Sale on installment and receive on sight'),
    },
    BOLETO_GARANTIDO: {
      icon: 'fas fa-barcode',
      title: props.t('Billing Guaranteed'),
      detail: props.t('Receive in installments with guarantee of receipt'),
    },
    'CASH.RECUPERA': {
      icon: 'fas fa-handshake',
      title: props.t('Cash.Recupera'),
      detail: props.t('Renegotiate debt with guarantee of receipt'),
    },
    'CASH.FLOW': {
      img: posImg,
      title: props.t('Cash.Flow'),
      detail: props.t(
        "Receive in the flow of receivables from your client's card machines",
      ),
    },
  });

  const hanleOpenSelectEndUserReceivables = () => {
    if (!(state.entity.amount > 0)) {
      setError({
        title: 'Valor a cobrança inválido',
        detail: 'Informe o valor da cobrança para prosseguir',
        code: 400,
      });
      return;
    }
    if (!(state.entity.buyer && state.entity.buyer.name)) {
      setError({
        title: 'Nenhum cliente selecionado',
        detail: 'Selecione um cliente para prosseguir',
        code: 400,
      });
      return;
    }
    setOpenModalSelectEndUserReceivables(true);
  };

  const getMyOrg = async () => {
    const response = await get(
      `${url.GET_MY_ORGANIZATION}`,
      null,
      null,
      null,
      null,
    );
    if (
      response &&
      response['organization'] &&
      response['organization']['bankAccounts']
    ) {
      setBankAccount(response['organization']['bankAccounts'][0]);
    }
  };

  const addAccount = (modalResult) => {
    if (modalResult['event'] === 'SAVED') {
      setBankAccount(modalResult['entity']);
      setEnableCreditorAccountEdit(true);
    }
    setOpenModalCreditorAccount(false);
  };

  useEffect(() => {
    if (!firstLoded) {
      getProductsTax();
      getMyOrg();
      setFirstLoded(true);
    }
  });

  console.log(state.entity);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName='drawer right-align'
        contentClassName='modal-content-scroll'
      >
        <TabContent activeTab={currentTab}>
          <TabPane tabId={'SIMULATE'}>
            <React.Fragment>
              <Scrollbars style={{ height: innerHeight - 80 }}>
                <div className='modal-header'>
                  <h5 className='modal-title mt-0'>
                    {props.t('Register Billing')}
                  </h5>
                  <button
                    type='button'
                    onClick={() => {
                      toggle();
                    }}
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>

                <div className='modal-body'>
                  {state.entity.buyer.legalId !== null ? (
                    <div>
                      <Row>
                        <p className='card-title-desc  mb-3'>
                          {props.t('Gerando cobrança para')}:
                        </p>
                      </Row>
                      <Row className='mb-3'>
                        <table>
                          <tbody>
                            <tr>
                              <td className='col-md-1'>
                                <div className='avatar-sm mx-auto me-3'>
                                  <span
                                    className='avatar-title rounded-circle bg-light font-size-16'
                                    style={{ color: 'black' }}
                                  >
                                    <strong>
                                      {' '}
                                      {StringUtils.prefix(
                                        state.entity.buyer.name,
                                      )}
                                    </strong>
                                  </span>
                                </div>
                              </td>
                              <td className='align-middle'>
                                <strong className='ms-2'>
                                  {state.entity.buyer.name
                                    ? StringUtils.abbreviateName(
                                        state.entity.buyer.name,
                                        45,
                                      )
                                    : $filter(
                                        state.entity.buyer.legalId,
                                        'CPF_CNPJ',
                                      )}
                                </strong>
                                <br />

                                <table className='mt-1'>
                                  <tbody>
                                    <tr>
                                      <td
                                        className='col-md-9'
                                        style={{
                                          borderLeft: '2px solid #923688',
                                          paddingLeft: 10,
                                        }}
                                      >
                                        <p className='static-label'>
                                          Última atualização
                                        </p>
                                      </td>
                                      <td className='text-end'>
                                        <p className='static-label'>
                                          {state.entity?.lastUpdateDate
                                            ? DateTimeUtils.format(
                                                state.entity?.lastUpdateDate,
                                                'DD/MM/YYYY',
                                              )
                                            : 'Data inválida'}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className='col-md-9'
                                        style={{
                                          borderLeft: '2px solid #009688',
                                          paddingLeft: 10,
                                        }}
                                      >
                                        <p className='static-label'>
                                          {' '}
                                          {props.t('Available limit')}
                                        </p>
                                      </td>
                                      <td className='text-end'>
                                        <p className='static-label'>
                                          {' '}
                                          {$filter(
                                            state.entity.buyer.availableLimit,
                                            'MONEY',
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderLeft: '2px solid #f44336',
                                          paddingLeft: 10,
                                        }}
                                        className='col-md-9'
                                      >
                                        <p className='static-label'>
                                          {' '}
                                          {props.t('Installment Limit')}
                                        </p>
                                      </td>
                                      <td className='text-end'>
                                        <p className='static-label'>
                                          {$filter(
                                            state.entity.buyer
                                              .installmentCreditLimit,
                                            'MONEY',
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className='col-md-9'
                                        style={{
                                          borderLeft: '2px solid #03a9f4',
                                          paddingLeft: 10,
                                        }}
                                      >
                                        <p className='static-label'>
                                          {' '}
                                          {props.t('Balance receivable')}
                                        </p>
                                      </td>
                                      <td className='text-end'>
                                        <p className='static-label'>
                                          {$filter(
                                            state.entity.buyer.balanceAvailable,
                                            'MONEY',
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Row>
                    </div>
                  ) : (
                    ''
                  )}

                  <Row>
                    <Col lg={12} className='mb-2'>
                      {state.entity.buyer.legalId !== null ? (
                        <Link to='#!' onClick={openModalSelectCustomer}>
                          {props.t('Change Customer')}
                        </Link>
                      ) : (
                        <button
                          className='btn w-100 btn-select mb-3'
                          onClick={openModalSelectCustomer}
                        >
                          {props.t('Select Customer')}
                        </button>
                      )}
                    </Col>
                  </Row>

                  <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                  <Row className='mt-4'>
                    <Col lg={6} className='mb-3'>
                      <InputMoney
                        id='amount'
                        required={true}
                        value={state.entity.userName}
                        onChange={handleAmount}
                        label={props.t('What amount do you want to charge?')}
                      />
                    </Col>

                    <Col lg={6} className='mb-3'>
                      <InputText
                        id='invoiceNumber'
                        popover={props.t(
                          "External identifier of the order, for purposes of reconciliation with the collector's management system.",
                        )}
                        onChange={(event) =>
                          handleFieldEntity('invoiceNumber', event.target.value)
                        }
                        label={props.t('Identifier')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className='mb-3'>
                      <button
                        className='btn w-100 btn-select'
                        onClick={() => {
                          setOpenModalMessage(true);
                        }}
                      >
                        <i className='bx bx-comment-dots font-size-18' />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {state.entity['message']
                          ? StringUtils.abbreviateName(
                              state.entity['message'],
                              5,
                            )
                          : 'Escrever uma mensagem'}
                      </button>
                    </Col>
                  </Row>

                  <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                  <Row>
                    <h5 className='mt-2 mb-2'>
                      {props.t('How do you want to receive?')}
                    </h5>
                  </Row>

                  {state.entity.paymentMethod ? (
                    <Row>
                      <Col lg={12} className='mb-0'>
                        <ReceiptPlanBox
                          icon={
                            productTemplate[state.entity.paymentMethod]['icon']
                          }
                          img={
                            productTemplate[state.entity.paymentMethod]['img']
                          }
                          title={
                            productTemplate[state.entity.paymentMethod]['title']
                          }
                          detail={
                            productTemplate[state.entity.paymentMethod][
                              'detail'
                            ]
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  <Row>
                    <Col lg={12} className='mb-3'>
                      {state.entity.paymentMethod !== null ? (
                        <>
                          <Link to='#!' onClick={openModalSelectReceiptPlan}>
                            {props.t('Change Receipt Plan')}
                          </Link>
                          <InputDate
                            id='baseDate'
                            value={state.entity.baseDate}
                            required={
                              state.entity.paymentMethod === 'CASH.RECUPERA'
                            }
                            onChange={(event) =>
                              handleFieldEntity(
                                'baseDate',
                                normalizedFieldValue(event.target.value),
                              )
                            }
                            popover={'Data da primeira NF desta cobrança.'}
                            label={props.t('Base date')}
                          />
                        </>
                      ) : (
                        <button
                          className='btn w-100 btn-select'
                          onClick={openModalSelectReceiptPlan}
                        >
                          {props.t('Select Receipt Plan')}
                        </button>
                      )}
                    </Col>
                  </Row>

                  {state.entity.paymentMethod === 'BOLETO_GARANTIDO' ||
                  state.entity.paymentMethod === 'CASH.NOW' ? (
                    <Row className='mb-3'>
                      <Col lg={12}>
                        <Select2PaymentPeriod
                          id='deadline'
                          menuPlacement='top'
                          defaultOptins={
                            productsTax[state.entity.paymentMethod][
                              'paymentPeriods'
                            ]
                          }
                          required={
                            state.entity.paymentMethod === 'BOLETO_GARANTIDO' ||
                            state.entity.paymentMethod === 'CASH.NOW'
                          }
                          beforeGetOptionLabel={setPaymentPeriodOptionFields}
                          popover={props.t(
                            'Inform how many times you want your customer to pay',
                          )}
                          label={props.t('Payment period')}
                          value={state.paymentPeriodSelected}
                          onChange={(option) => {
                            handlePaymentPeriod(option);
                          }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {state.entity.paymentMethod === 'CASH.RECUPERA' ? (
                    <Row>
                      <Col lg={12} className='mb-3'>
                        <Select2PaymentPeriod
                          id='deadline'
                          menuPlacement='top'
                          defaultOptins={
                            productsTax[state.entity.paymentMethod][
                              'paymentPeriods'
                            ]
                          }
                          required={
                            state.entity.paymentMethod === 'CASH.RECUPERA'
                          }
                          beforeGetOptionLabel={setPaymentPeriodOptionFields}
                          popover={props.t(
                            'Inform how many times you want your customer to pay',
                          )}
                          label={props.t('Payment period')}
                          value={state.paymentPeriodSelected}
                          onChange={(option) => {
                            handlePaymentPeriod(option);
                          }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {state.entity.paymentMethod === 'CASH.FLOW' ? (
                    <Row className='mb-3'>
                      <Col lg={12}>
                        <button
                          className='btn w-100 btn-select'
                          onClick={hanleOpenSelectEndUserReceivables}
                        >
                          Selecionar recebíveis disponíveis
                        </button>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  {state.paymentPeriodSelected &&
                  state.paymentPeriodSelected.invalidMessage ? (
                    <Row>
                      <Col>
                        <Alert role='danger' color='danger'>
                          {state.paymentPeriodSelected.invalidMessage}
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </div>
              </Scrollbars>

              <div className='modal-footer fixed-bottom'>
                <div className='text-end'>
                  <button
                    className='btn btn-primary w-sm waves-effect waves-light'
                    onClick={next}
                  >
                    {props.t('Próximo')}
                  </button>
                </div>
              </div>
            </React.Fragment>
          </TabPane>

          <TabPane tabId={'SUMMARY'}>
            {currentTab === 'SUMMARY' ? (
              <React.Fragment>
                <Scrollbars style={{ height: innerHeight - 80 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title mt-0'>
                      {props.t('Resumo da Cobrança')}
                    </h5>
                    <button
                      type='button'
                      onClick={() => {
                        toggle();
                      }}
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>

                  <div className='modal-body'>
                    <Row>
                      <Col lg={12}>
                        <p className='card-title-desc mb-0'>
                          {props.t('Valor à cobrar')}
                        </p>
                        <strong className='text-primary font-size-20'>
                          {$filter(state.entity.amount, 'MONEY')}
                        </strong>
                      </Col>
                    </Row>

                    <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                    <Row>
                      <Col lg={12}>
                        <p className='card-title-desc mb-0'>
                          {props.t('Customer')}
                        </p>
                        <span>{state.entity.buyer.name}</span>
                      </Col>
                    </Row>

                    <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                    <Row>
                      <Col lg={12}>
                        <p className='card-title-desc mb-0'>
                          {props.t('Receipt plan')}
                        </p>
                        <strong>
                          {productTemplate[state.entity.paymentMethod].title}
                        </strong>
                      </Col>
                    </Row>

                    <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                    {state.entity.deadline ? (
                      <React.Fragment>
                        <Row>
                          <Col lg={12}>
                            <p className='card-title-desc mb-0'>
                              {props.t('Payment period')}
                            </p>
                            <span>{state.entity.deadline}</span>
                          </Col>
                        </Row>

                        <hr
                          style={{ borderTop: '1px solid rgb(187 187 187)' }}
                        />
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    <Row>
                      <Col lg={12}>
                        <p className='card-title-desc mb-0'>
                          {props.t('Tax Operation')}
                        </p>
                        <span>{$filter(state.entity.tax, 'PRECENT')}</span>
                      </Col>
                    </Row>

                    <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                    <Row>
                      <Col lg={12}>
                        <p className='card-title-desc mb-0'>
                          {props.t('Identifier')}
                        </p>
                        <span>{state.entity.invoiceNumber}</span>
                      </Col>
                    </Row>

                    <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

                    {state.entity.paymentMethod === 'CASH.FLOW' ? (
                      <React.Fragment>
                        {bankAccount && bankAccount.ispb ? (
                          <React.Fragment>
                            <Row>
                              <Col lg={12}>
                                <p className='card-title-desc mb-1'>
                                  {props.t('Conta onde o valor será creditado')}
                                </p>
                                <strong>
                                  {PaymentCompanyDomain.getLabelByCode(
                                    bankAccount.ispb,
                                  )}
                                </strong>
                                <br />
                                <span>
                                  Agência&nbsp;{bankAccount.branch}&nbsp;
                                  Conta&nbsp;{bankAccount.accountNumber}
                                  {bankAccount.accountDigit
                                    ? `-${bankAccount.accountDigit}`
                                    : ''}
                                </span>
                              </Col>
                            </Row>

                            {enableCreditorAccountEdit ? (
                              <Row className='mt-2'>
                                <Col lg={12}>
                                  <Link
                                    to='#!'
                                    onClick={() => {
                                      setOpenModalCreditorAccount(true);
                                    }}
                                  >
                                    {props.t('Change Creditor Account')}
                                  </Link>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <p className='card-title-desc mb-0'>
                                {props.t('Conta onde o valor será creditado')}
                              </p>
                              <br />
                              <button
                                className='btn w-100 btn-select'
                                onClick={() => {
                                  setOpenModalCreditorAccount(true);
                                }}
                              >
                                Informe conta de recebimento
                              </button>
                            </Col>
                          </Row>
                        )}

                        <hr
                          style={{ borderTop: '1px solid rgb(187 187 187)' }}
                        />
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                </Scrollbars>

                <div className='modal-footer fixed-bottom'>
                  <div className='text-end'>
                    <button
                      className='btn btn-secondary w-sm waves-effect waves-light'
                      onClick={prior}
                    >
                      {props.t('Voltar')}
                    </button>{' '}
                    <button
                      className='btn btn-primary w-sm waves-effect waves-light'
                      onClick={save}
                    >
                      {props.t('Create Billing')}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
          </TabPane>
        </TabContent>

        {error ? (
          <ErrorDialog
            title={error['title']}
            code={error['code']}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error['detail']} </p>
          </ErrorDialog>
        ) : (
          ''
        )}

        {loading ? <Loader loading={loading} /> : ' '}
      </Modal>

      {modalSelectCustomer ? (
        <SelectCustomerModal
          isOpen={modalSelectCustomer}
          callback={closeModalSelectCustomer}
        />
      ) : (
        ''
      )}
      {modalReceiptPlan ? (
        <GenericModal
          isOpen={modalReceiptPlanIsOPen}
          title={props.t('Select Receipt Plan')}
          callback={() => {
            closeModalReceiptPlan();
          }}
        >
          <React.Fragment>
            {productsTax['CASH.NOW'] ? (
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleReceiptPlan(productsTax['CASH.NOW']);
                  closeModalReceiptPlan();
                }}
              >
                <Col lg={12} className='mb-0'>
                  <ReceiptPlanBox
                    selected={state.entity.paymentMethod === 'CASH.NOW'}
                    icon={productTemplate['CASH.NOW'].icon}
                    title={productTemplate['CASH.NOW'].title}
                    detail={productTemplate['CASH.NOW'].detail}
                  />
                  <hr className='m-0 hr-line' />
                </Col>
              </Row>
            ) : (
              ''
            )}
            {productsTax['CASH.FLOW'] ? (
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleReceiptPlan(productsTax['CASH.FLOW']);
                  closeModalReceiptPlan();
                }}
              >
                <Col lg={12} className='mb-0'>
                  <ReceiptPlanBox
                    selected={state.entity.paymentMethod === 'CASH.FLOW'}
                    img={productTemplate['CASH.FLOW'].img}
                    title={productTemplate['CASH.FLOW'].title}
                    detail={productTemplate['CASH.FLOW'].detail}
                  />
                  <hr className='m-0 hr-line' />
                </Col>
              </Row>
            ) : (
              ''
            )}
            {productsTax['BOLETO_GARANTIDO'] ? (
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleReceiptPlan(productsTax['BOLETO_GARANTIDO']);
                  closeModalReceiptPlan();
                }}
              >
                <Col lg={12} className='mb-0'>
                  <ReceiptPlanBox
                    selected={state.entity.paymentMethod === 'BOLETO_GARANTIDO'}
                    icon={productTemplate['BOLETO_GARANTIDO'].icon}
                    title={productTemplate['BOLETO_GARANTIDO'].title}
                    detail={productTemplate['BOLETO_GARANTIDO'].detail}
                  />
                  <hr className='m-0 hr-line' />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {productsTax['CASH.RECUPERA'] ? (
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleReceiptPlan(productsTax['CASH.RECUPERA']);
                  closeModalReceiptPlan();
                }}
              >
                <Col lg={12} className='mb-3'>
                  <ReceiptPlanBox
                    selected={state.entity.paymentMethod === 'CASH.RECUPERA'}
                    icon={productTemplate['CASH.RECUPERA'].icon}
                    title={productTemplate['CASH.RECUPERA'].title}
                    detail={productTemplate['CASH.RECUPERA'].detail}
                  />
                  <hr className='m-0 hr-line' />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </React.Fragment>
        </GenericModal>
      ) : (
        <></>
      )}

      {openModalMessage ? (
        <SelectTextAreaModal
          isOpen={openModalMessage}
          callback={closeModalMessage}
          defaultValue={state.entity['message']}
          title={'Escrever uma mensagem'}
          detail={'Esta mensagem será enviada junto o valor a ser pago'}
        />
      ) : (
        <></>
      )}

      {openModalSelectEndUserReceivables ? (
        <SelectEndUserReceivables
          isOpen={openModalSelectEndUserReceivables}
          callback={closeModalSelectReceivables}
          endUser={Object.assign({}, state.entity.buyer)}
          amount={state.entity.amount}
        />
      ) : (
        <></>
      )}

      {openModalCreditorAccount ? (
        <SelectAddAccountModal callback={addAccount} />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default withTranslation()(OrderNew);
