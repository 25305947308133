import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import ErrorDialog from '../../components/Common/ErrorDialog';
import InputText from '../../components/Common/InputText';
import PaginationService from '../../components/Common/PaginationService';
import SuccessDialog from '../../components/Common/SuccessDialog';
import * as url from '../../helpers/url_helper';
import GlobalParamEdit from './GlobalParamEdit';
import ParamValueView from './ParamValueView';

function GlobalParams(props) {
  const columns = [
    {
      text: 'Nome',
      dataField: 'keyName',
    },
    {
      text: 'Detalhe',
      dataField: 'detail',
    },
    {
      text: 'Valor',
      dataField: 0,
      formatter: (cell, row) => (
        <ParamValueView keyValue={row.keyValue} paramType={row.paramType} />
      ),
    },
    {
      text: '',
      dataField: '',
      formatter: (cell, row) => (
        <span
          className='text-center'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openModalEditEntity(row);
          }}
        >
          <i className='mdi mdi-pencil font-size-16' />
        </span>
      ),
    },
  ];

  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(
    PaginationService.defaultFilter(null, 1),
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalEditEntity, setModalEditEntity] = useState({
    isOpen: false,
    entity: null,
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalOfItems, setTotalOfItems] = useState(0);

  const applySearch = async (currentPage, itemsPerPage) => {
    try {
      const { pagerResult, filterResult } =
        await PaginationService.applySearchV2(
          url.GET_GLOBAL_PARAMS,
          'data',
          filter,
          currentPage,
          itemsPerPage,
          setLoading,
        );

      setPager(pagerResult);
      setFilter(filterResult);
      setTotalOfItems(pagerResult?.pagination?.totalOfItems || 0);
    } catch (e) {
      if (e['status'] !== '200' && e['status'] !== '403') setError(e);
    }
  };

  const handleChangeFilter = (event, field) => {
    const fieldValue = event.target.value;
    setFilter((prevState) => ({ ...prevState, [field]: fieldValue }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPaginationV2(filter));
    applySearch();
  };

  function setResultFromNewEntity(newEntity) {
    setFilter(PaginationService.defaultFilter());

    const newPager = PaginationService.defaultPager();
    newPager.results = [newEntity];
    setPager(newPager);
  }

  const openModalEditEntity = (entity) => {
    setModalEditEntity({
      isOpen: true,
      entity: Object.assign({}, entity),
    });
  };

  const closeModalEditEntity = (resultModal) => {
    setModalEditEntity({
      isOpen: false,
      entity: null,
    });

    if (resultModal['event'] === 'UPDATED') {
      setResultFromNewEntity(resultModal['entity']);

      setSuccessMessage({
        detail: props.t('Organization successfully updated'),
      });
    }
  };

  useEffect(() => {
    applySearch();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>{props.t('Global Parameters')}</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs
            title={props.t('Global Parameters')}
            breadcrumbItem={props.t('Manage Global Parameters')}
          />

          <Form
            id='searchForm'
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t('Filters parameters')}</p>
                    <Row>
                      <Col lg={4} className='mb-3'>
                        <InputText
                          id='filterName'
                          onChange={(event) =>
                            handleChangeFilter(event, 'keyName')
                          }
                          placeholder={props.t('Key Name')}
                        />
                      </Col>
                      <Col lg={4} className='mb-3'>
                        <InputText
                          id='filterDetail'
                          onChange={(event) =>
                            handleChangeFilter(event, 'detail')
                          }
                          placeholder={props.t('Detail')}
                        />
                      </Col>
                    </Row>

                    <Row className='mt-4'>
                      <hr />
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <div className='text-end'>
                          <button
                            type='submit'
                            className='btn btn-sm btn-light w-sm waves-effect waves-light'
                          >
                            {props.t('Apply Search')}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-4'>
                      <Col lg={12}>
                        <PaginationProvider
                          pagination={paginationFactory({
                            onSizePerPageChange: (sizePerPage, page) => {
                              filter['pagination.itemsPerPage'] = sizePerPage;
                              filter['pagination.currentPage'] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            onPageChange: (page, sizePerPage) => {
                              filter['pagination.itemsPerPage'] = sizePerPage;
                              filter['pagination.currentPage'] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            page: filter['pagination.currentPage'],
                            sizePerPage: filter['pagination.itemsPerPage'],
                            totalSize: totalOfItems,
                            showTotal: true,
                          })}
                        >
                          {({ _paginationProps, paginationTableProps }) => (
                            <div>
                              <BootstrapTable
                                onTableChange={(type, newState) => {
                                  return;
                                }}
                                keyField='keyName'
                                remote={true}
                                data={pager.results || []}
                                loading={loading}
                                showTotal={true}
                                columns={columns}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {modalEditEntity.isOpen ? (
            <GlobalParamEdit
              isOpen={modalEditEntity.isOpen}
              entity={modalEditEntity.entity}
              callback={closeModalEditEntity}
            />
          ) : (
            ''
          )}
        </Container>
      </div>

      {error ? (
        <ErrorDialog
          title={error['title']}
          onClose={() => {
            setError(null);
          }}
        >
          <p>{error['detail']} </p>
        </ErrorDialog>
      ) : (
        ''
      )}

      {successMessage ? (
        <SuccessDialog
          title={successMessage['title']}
          onClose={() => {
            setSuccessMessage(null);
          }}
        >
          <p>{successMessage['detail']} </p>
        </SuccessDialog>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(GlobalParams));
