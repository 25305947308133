import * as url from '../../helpers/url_helper';
import { HttpService } from './HttpService';

export class ReceivablesService extends HttpService {
  constructor(http) {
    super(http);
  }

  async getAll() {
    return this.http.get(url.GET_RECEIVABLES);
  }

  async getByLegalId(legalId) {
    return this.http.get(`${url.GET_RECEIVABLES}/${legalId}`);
  }

  async getAllAvailableUnitsLegalId(legalId) {
    return this.http.get(`${url.GET_RECEIVABLES_AVAILABLE}/${legalId}`);
  }

  async getAllCompromisedsUnitsByLegalId(legalId) {
    return this.http.get(`${url.GET_RECEIVABLES_COMPROMISED}/${legalId}`);
  }

  async getAllFutureCommitmentsUnitsByLegalId(legalId) {
    return this.http.get(`${url.GET_FUTURE_COMMITMENTS}/${legalId}`);
  }

  async getAllAcquirersUnitsByLegalId(legalId) {
    return this.http.get(`${url.GET_ACQUIRERS_BY_MONTHS}/${legalId}`);
  }

  async getAllObligationsUnitsByLegalId(legalId) {
    return this.http.get(`${url.GET_END_USER_OBLIGATIONS}/${legalId}`);
  }
}
