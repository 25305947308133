import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useParams, withRouter } from 'react-router-dom';

import Loader from '@cash2pay/components/Common/Loader';
import { showToast } from '@cash2pay/libs/toast';
import { ordersService } from '@cash2pay/services/http';

import {
  CheckoutDesktopContent,
  CheckoutMobileContent,
  ModalCheckoutSign,
} from './components';

const Checkout = () => {
  const [modalDocInSign, setModalDocInSign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [documentAlreadySigned, setDocumentAlreadySigned] = useState(false);
  const [paymentPeriodSelected, setPaymentPeriodSelected] = useState(null);

  const [buyerFormFields, setBuyerFormFields] = useState({
    legalIdBuyer: { isValid: false },
    phoneBuyer: { isValid: false },
    emailBuyer: { isValid: false },
    personLegalId: { isValid: false },
    personName: { isValid: false },
    personCompanyPlacement: { isValid: false },
    birthDate: { isValid: false },
    checkAcceptedTerms: { isValid: true },
  });

  const [isValidToInitCheckout, setIsValidToInitCheckout] = useState(false);

  const [entity, setEntity] = useState({
    orderDate: null,
    invoiceNumber: null,
    seller: {
      legalId: null,
      name: null,
    },
    amount: 0,
    paymentMethod: null,
    deadline: null,
    numInstallments: null,
    installmentAmount: null,
    message: null,
  });

  const [buyer, setBuyer] = useState({
    legalId: null,
    phone: null,
    email: null,
    birthDate: null,
  });

  let { orderReference } = useParams();

  const handleBuyerField = (name, value) => {
    buyer[name] = value;
    setBuyer(buyer);
  };

  const handleBuyerFormField = (fieldId, isValid) => {
    buyerFormFields[fieldId] = { isValid: isValid };
    const newForm = Object.assign({}, buyerFormFields);

    setBuyerFormFields(newForm);
    // console.log('newForm', newForm)
    validateForm(newForm);
  };

  const validateForm = (buyerForm, paymentOption) => {
    let form = buyerForm ? buyerForm : buyerFormFields;
    // let paySelected = paymentOption ? paymentOption : paymentPeriodSelected;

    let isValidFull = true;
    for (const fieldName in form) {
      if (!form[fieldName].isValid) {
        isValidFull = false;
      }
    }

    let paymentSel = true;
    // if (entity.paymentMethod === 'CASH.NOW' && !paySelected){
    //     paymentSel = false
    // }
    setIsValidToInitCheckout(isValidFull && paymentSel);
  };

  const handlePaymentPeriod = (paymentOption) => {
    setPaymentPeriodSelected(paymentOption);
    validateForm(null, paymentOption);
  };

  const closeModalSignd = (modalResult) => {
    setModalDocInSign(false);

    if (
      modalResult &&
      modalResult['event'] &&
      modalResult['event'] === 'DOC_SIGNED'
    ) {
      setDocumentAlreadySigned(true);

      entity.isSigned = modalResult['entity']['isSigned'];
      setEntity(Object.assign({}, entity));
    }
  };

  const initCheckout = async () => {
    if (!isValidToInitCheckout) return;

    try {
      const client = {
        legalId: buyer['legalId'],
        phone: buyer['phone'],
        email: buyer['email'],
        personName: buyer['personName'],
        personLegalId: buyer['personLegalId'],
        birthDate: buyer['birthDate'],
        personCompanyPlacement: buyer['personCompanyPlacement'],
      };

      if (paymentPeriodSelected) {
        client['paymentPeriod'] = paymentPeriodSelected['paymentPeriod'];
      }

      setLoading(true);

      const response = await ordersService.createCheckout(
        orderReference,
        client,
      );

      if (response['order']) {
        response['order']['orderReference'] = orderReference;
        response['order']['buyer']['email'] = buyer['email'];

        if (response['order'] && response['order']['isSigned'] === true) {
          setEntity(
            Object.assign(
              { paymentOptions: entity['paymentOptions'] },
              response['order'],
            ),
          );
          setDocumentAlreadySigned(true);
        } else {
          setEntity(
            Object.assign(
              { paymentOptions: entity['paymentOptions'] },
              response['order'],
            ),
          );
          setModalDocInSign(true);
        }
      }
    } catch (e) {
      if (e.response.status === 404) {
        showToast({
          type: 'error',
          message: "O campo de cnpj não corresponde ao cnpj do pagador'",
        });
      } else if (e.response.status === 400) {
        showToast({ type: 'error', message: e.message });
      }
    }

    setLoading(false);
  };

  const loadCheckout = useCallback(async () => {
    setLoading(true);

    try {
      const response = await ordersService.getCheckout(orderReference);

      if (response['order']) {
        setEntity(response['order']);
      }
    } finally {
      setFirstLoad(false);
    }

    setLoading(false);
  }, [orderReference]);

  useEffect(() => {
    loadCheckout();
  }, [loadCheckout]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Checkout | Cash2Pay</title>
      </MetaTags>
      <div className='checkout-page'>
        <div className='d-none d-md-block h-100'>
          <CheckoutDesktopContent
            documentAlreadySigned={documentAlreadySigned}
            handleBuyerField={handleBuyerField}
            handlePaymentPeriod={handlePaymentPeriod}
            firstLoad={firstLoad}
            entity={entity}
            paymentPeriodSelected={paymentPeriodSelected}
            handleBuyerFormField={handleBuyerFormField}
            modalDocInSign={modalDocInSign}
            isValidToInitCheckout={isValidToInitCheckout}
            initCheckout={initCheckout}
          />
        </div>

        <div className='d-block d-md-none h-100'>
          <CheckoutMobileContent
            documentAlreadySigned={documentAlreadySigned}
            handleBuyerField={handleBuyerField}
            firstLoad={firstLoad}
            entity={entity}
            handlePaymentPeriod={handlePaymentPeriod}
            initCheckout={initCheckout}
            paymentPeriodSelected={paymentPeriodSelected}
            handleBuyerFormField={handleBuyerFormField}
          />
        </div>
      </div>
      {modalDocInSign && (
        <ModalCheckoutSign
          entity={entity}
          isOpen={modalDocInSign}
          callback={closeModalSignd}
        />
      )}
      {loading ? <Loader loading={loading} /> : ' '}
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Checkout));
