import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import GenericModal from '@cash2pay/components/Common/GenericModal';

import { CheckoutFinished } from './CheckoutFinished';
import { CheckoutTicketSeller } from './CheckoutTicketSeller';
import { CompleteFieldsToStartCheckout } from './CompleteFieldsToStartCheckout';

export const CheckoutMobileContent = (props) => {
  const [page, setPage] = useState('CheckoutTicketSeller');

  const translation = useTranslation();

  return (
    <React.Fragment>
      <Row className='justify-content-center h-100'>
        <Col lg={12}>
          <CheckoutTicketSeller
            entity={props.entity}
            firstLoad={props.firstLoad}
            handlePaymentPeriod={props.handlePaymentPeriod}
            paymentPeriodSelected={props.paymentPeriodSelected}
            documentAlreadySigned={props.documentAlreadySigned}
          />

          <Row
            className='sticky-bottom'
            style={{ backgroundColor: 'white', borderRadius: '50px 50px 0 0' }}
          >
            <Col lg={4}>
              <div className='text-center p-4'>
                <button
                  style={{
                    borderRadius: 120,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}
                  className='btn btn-primary w-100 w-sm waves-effect waves-light h-25'
                  onClick={() => {
                    setPage('CompleteFieldsToStartCheckout');
                  }}
                >
                  Próximo
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {page === 'CompleteFieldsToStartCheckout' &&
      !props.documentAlreadySigned ? (
        <GenericModal
          isOpen={
            page === 'CompleteFieldsToStartCheckout' &&
            !props.documentAlreadySigned
          }
          callback={() => {
            setPage('CheckoutTicketSeller');
          }}
        >
          <React.Fragment>
            <CompleteFieldsToStartCheckout
              handleBuyerField={props.handleBuyerField}
              isMobile={true}
              handleBuyerFormField={props.handleBuyerFormField}
            />

            <Row>
              <Col lg={12}>
                <div className='text-center'>
                  <button
                    style={{
                      borderRadius: 120,
                      textTransform: 'uppercase',
                      fontWeight: 600,
                    }}
                    className='btn btn-primary w-100 w-sm waves-effect waves-light h-25'
                    onClick={props.initCheckout}
                  >
                    {translation.t('Start Checkout')}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className='fa fa-lock' />
                  </button>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </GenericModal>
      ) : (
        ''
      )}

      {page === 'CompleteFieldsToStartCheckout' &&
      props.documentAlreadySigned ? (
        <GenericModal
          isOpen={
            page === 'CompleteFieldsToStartCheckout' &&
            props.documentAlreadySigned
          }
          callback={() => {
            setPage('CompleteFieldsToStartCheckout');
          }}
        >
          <CheckoutFinished entity={props.entity} />
        </GenericModal>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};
