import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'reactstrap';

import { add } from '@cash2pay/libs/date';
import { showToast } from '@cash2pay/libs/toast';
import { ordersService } from '@cash2pay/services/http';

export const ModalCheckoutSign = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const finalDateToVerifySignature = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClosed = () => {
    props.callback({ isOpen: false, entity: null });
  };

  const closeAfterSign = useCallback(
    (entity) => {
      props.callback({ isOpen: false, event: 'DOC_SIGNED', entity: entity });
    },
    [props],
  );

  const closeZapsignModalWhenIsSigned = useCallback(async () => {
    if (new Date() > finalDateToVerifySignature.current) return;

    try {
      const response = await ordersService.getCheckout(
        props.entity.orderReference,
      );

      if (response?.order?.isSigned === true) {
        closeAfterSign(response.data['order']);
      } else {
        setTimeout(closeZapsignModalWhenIsSigned, 10000);
      }
    } catch {
      //
    }
  }, [closeAfterSign, props.entity, finalDateToVerifySignature]);

  useEffect(() => {
    const handleMessage = (e) => {
      if (finalDateToVerifySignature.current) return;

      if (e.data === 'zs-doc-signed') {
        finalDateToVerifySignature.current = add(new Date(), { minutes: 5 });
        closeZapsignModalWhenIsSigned(); // Show a success message when the signature is signed in another window
        closeAfterSign({ isSigned: true });
        showToast({
          type: 'success',
          message:
            'Documento assinado com sucesso! Em alguns minutos, a assinatura será processada e refletida na plataforma.',
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [closeAfterSign, closeZapsignModalWhenIsSigned]);

  return (
    <Modal
      modalClassName='drawer right-align'
      size='lg'
      isOpen={isOpen}
      toggle={handleClose}
      onClosed={handleClosed}
    >
      <div className='modal-body'>
        <iframe
          title='Link do documento para assinar'
          src={props.entity.buyer.linkSign[0]}
          width='100%'
          height={Number(document.body.clientHeight) - 68}
        />
      </div>
    </Modal>
  );
};
