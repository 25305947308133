import React from 'react';

const ErrorDialog = (props) => {
  if (props.code && parseInt(props.code) < 500) {
    setTimeout(() => {
      if (props.onClose) props.onClose();
    }, 3000);
  }

  return (
    <div className='toast-container position-absolute top-1 end-0 p-2 p-lg-3'>
      <div
        className='toast fade show'
        role='alert'
        aria-live='assertive'
        aria-atomic='true'
        id='toastErrorDialog'
      >
        <div className='toast-header e'>
          <i className='bx bx-error me-2 font-size-30 text-danger'></i>
          <strong className='me-auto mt-1 '>
            {props.title ? props.title : 'Erro inesperado'}
          </strong>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='toast'
            aria-label='Close'
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          ></button>
        </div>
        <div className='toast-body '>{props.children}</div>
      </div>
    </div>
  );
};

export default ErrorDialog;
