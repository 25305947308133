import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { withRouter, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Badge, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';

import { isObject } from 'lodash';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import InputText from '../../../components/Common/InputText';
import PaginationService from '../../../components/Common/PaginationService';
import Select2Async from '../../../components/Common/Select2Async';
import * as url from '../../../helpers/url_helper';

function GravameV2(props) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const columns = [
    {
      dataField: 'id',
      text: 'Product ID',
    },
  ];

  let query = useQuery();
  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter(50));
  const [loading, setLoading] = useState(false);
  const [statusSelects, setStatusSelects] = useState({});
  const [error, setError] = useState(null);

  const applySearch = async (
    currentPage,
    itemsPerPage,
    filterDefault = filter,
  ) => {
    try {
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        url.GET_OPTIN_ADMIN,
        'optinAdmin',
        filterDefault,
        currentPage,
        itemsPerPage,
        setLoading,
      );
      setPager(pagerResult);
      setFilter(filterResult);
      updateQueryUrl(filterResult);
    } catch (e) {
      if (e['status'] !== '200' && e['status'] !== '403') {
        setError(e);
      }
    }
  };

  const handleChangeFilter = (event, field) => {
    filter[field] = event.target.value;

    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handlerSelect = (event, field) => {
    filter[field] = event?.value || undefined;
    setStatusSelects((v) => ({
      ...v,
      [field]: event,
    }));
    // setFilterField("filter", filter);
    setFilter(filter);
    applySearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));
    applySearch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    const filterInit = PaginationService.defaultFilter(50);
    setStatusSelects((v) => ({
      ...v,
      status: null,
      legalIdRequester: null,
      legalIdReceivingEndUser: null,
    }));
    document.getElementById('searchForm').reset({
      externalReference: undefined,
      startDate: undefined,
      endDate: undefined,
      legalIdRequester: undefined,
      legalIdReceivingEndUser: undefined,
      status: undefined,
    });

    await setFilter((_f) => ({
      ...filterInit,
    }));
    await applySearch(
      filterInit['pagination.currentPage'],
      filterInit['pagination.itemsPerPage'],
      filterInit,
    );
  };

  const updateQueryUrl = (filter) => {
    if (filter) {
      for (let key in filter) {
        if (filter[key] !== null && filter[key] !== undefined) {
          if (!isObject(filter[key])) {
            query.set(key, filter[key]);
          }
        } else {
          query.delete(key);
        }
      }
      for (let key of query.keys()) {
        if (!filter.hasOwnProperty(key)) {
          query.delete(key);
        }
      }

      props.history.push({
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    if (query.size) {
      async function fetchData() {
        const filterInit = {
          externalReference: query.get('externalReference'),
          startDate: query.get('startDate'),
          endDate: query.get('endDate'),
          legalIdRequester: query.get('legalIdRequester'),
          legalIdReceivingEndUser: query.get('legalIdReceivingEndUser'),
          status: query.get('status'),
          'pagination.currentPage': query.get('pagination.currentPage'),
          'pagination.itemsPerPage': query.get('pagination.itemsPerPage'),
        };
        await setFilter((f) => ({
          ...f,
          ...filterInit,
        }));
        await applySearch(
          filterInit['pagination.currentPage'],
          filterInit['pagination.itemsPerPage'],
          filterInit,
        );
        setStatusSelects((v) => ({
          ...v,
          status: {
            value: filterInit['status'],
          },
          legalIdRequester: {
            value: filterInit['legalIdRequester'],
            label: filterInit['legalIdRequester'],
          },
          legalIdFinancialAgent: {
            value: filterInit['legalIdFinancialAgent'],
            label: filterInit['legalIdFinancialAgent'],
          },
          legalIdReceivingEndUser: {
            value: filterInit['legalIdReceivingEndUser'],
            label: filterInit['legalIdReceivingEndUser'],
          },
        }));
      }
      fetchData();
    }
  }, []);

  const optinStatus = [
    { label: 'Ativo', value: 'A' },
    { label: 'Inativo', value: 'I' },
    { label: 'Aguardando assinatura', value: 'S' },
    { label: 'Pendente', value: 'P' },
    { label: 'Com Error', value: 'E' },
  ];
  const GenerateStatus = ({ status }) => {
    let label = optinStatus[0].label;
    let color = 'primary';
    switch (status) {
      case 'A':
        color = 'success';
        label = optinStatus[0].label;
        break;
      case 'I':
        color = 'danger';
        label = optinStatus[1].label;
        break;
      case 'S':
        color = 'warning';
        label = optinStatus[2].label;
        break;
      case 'P':
        color = 'info';
        label = optinStatus[3].label;
        break;
      default:
        color = 'danger';
        label = optinStatus[4].label;
        break;
    }

    return (
      <Badge
        className={`badge bg-${color} rounded-pill font-size-12`}
        color={color}
        pill={true}
      >
        {label}
      </Badge>
    );
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>{props.t('COMPANY | Opt-in')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t('Opt-in')}
            breadcrumbItem={props.t('Manage opt-in')}
          />
          <Form
            id='searchForm'
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            default={{
              externalReference: undefined,
              startDate: undefined,
              endDate: undefined,
              legalIdRequester: undefined,
              legalIdFinancialAgent: undefined,
              legalIdReceivingEndUser: undefined,
              status: undefined,
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t('Filters parameters')}</p>
                    <Row>
                      <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
                        <InputText
                          id='externalReference'
                          onChange={(event) =>
                            handleChangeFilter(event, 'externalReference')
                          }
                          placeholder={props.t('Reference Code')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
                        <Select2Async
                          url={url.GET_FINANCIAL_AGENT_PAGINATION}
                          orderBy={'name'}
                          onChange={(event) =>
                            handlerSelect(event, 'legalIdFinancialAgent')
                          }
                          id='legalIdFinancialAgent'
                          placeholder={'CNPJ Agente de Financeiro'}
                          value={statusSelects['legalIdFinancialAgent']}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
                        <Select2Async
                          url={url.GET_RECEIVING_END_USER_PAGINATION}
                          orderBy={'name'}
                          onChange={(event) =>
                            handlerSelect(event, 'legalIdReceivingEndUser')
                          }
                          id='legalIdReceivingEndUser'
                          placeholder={'CNPJ Cliente Final'}
                          value={statusSelects['legalIdReceivingEndUser']}
                        />
                      </Col>

                      <Col lg={4} xl={3} md={6} xs={12} className='mb-3'>
                        <div className='templating-select select2-container'>
                          <Select
                            id='statusOptin'
                            isMulti={false}
                            classNamePrefix='select2-selection'
                            isSearchable={false}
                            onChange={(event) => handlerSelect(event, 'status')}
                            placeholder={props.t('Status')}
                            options={optinStatus}
                            value={statusSelects['status']}
                            isClearable={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <hr />
                    </Row>
                    <Row className='mt-4'>
                      <Col lg={4} className='col-sm-auto offset-sm-8'>
                        <Row
                          className='row-sm-auto text-end'
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <button
                            className='btn btn-sm btn-light w-sm waves-effect waves-ligh col-auto mr-5'
                            onClick={(e) => clearFilters(e)}
                            title='Limpar Filtros'
                          >
                            Limpar Filtros
                          </button>
                          <button
                            type='submit'
                            title='Aplicar Filtros'
                            className='btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto'
                          >
                            {props.t('Apply Search')}
                          </button>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='mt-4'>
                      <Col lg={12}>
                        <BootstrapTable
                          keyField='id'
                          data={pager.results}
                          columns={columns}
                          pagination={paginationFactory()}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
}

function GravameV3(props) {
  return (
    <>
      <p>Gravame em desenvolvimento</p>
    </>
  );
}

export default withRouter(withTranslation()(GravameV3));
