import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

// Import menuDropdown
import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';
import logoSmLight from '../../assets/images/logo-sm-light.png';
import logoSm from '../../assets/images/logo-sm.png';
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from '../../store/actions';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

//i18n

// Redux Store

const Header = (props) => {
  const [search, setsearch] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      //desktop view
      if (props.leftSideBarType === 'default') {
        props.changeSidebarType('small');
      } else if (
        props.leftSideBarType === 'small' ||
        props.leftSideBarType === 'compact'
      ) {
        props.changeSidebarType('default');
      }
    } else {
      //mobile view
      document.body.classList.toggle('sidebar-enable');
      props.changeSidebarType('default');
    }
  }

  return (
    <header id='page-topbar'>
      <div className='navbar-header'>
        <div className='d-flex'>
          <div className='navbar-brand-box'>
            <Link to='/' className='logo logo-dark'>
              <span className='logo-sm'>
                <img src={logoSm} alt='' height='22' />
              </span>
              <span className='logo-lg'>
                <img src={logoDark} alt='' height='23' />
              </span>
            </Link>

            <Link to='/' className='logo logo-light'>
              <span className='logo-sm'>
                <img src={logoSmLight} alt='' height='22' />
              </span>
              <span className='logo-lg'>
                <img src={logoLight} alt='' height='40' />
              </span>
            </Link>
          </div>
          <button
            type='button'
            onClick={() => {
              tToggle();
            }}
            className='btn btn-sm px-3 font-size-16 header-item '
            id='vertical-menu-btn'
          >
            <i className='fa fa-fw fa-bars' />
          </button>
        </div>
        <div className='d-flex'>
          <div className='dropdown d-inline-block d-lg-none ms-2'>
            <button
              onClick={() => {
                setsearch(!search);
              }}
              type='button'
              className='btn header-item noti-icon'
              id='page-header-search-dropdown'
            >
              <i className='mdi mdi-magnify' />
            </button>
            <div
              className={
                search
                  ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                  : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
              }
              aria-labelledby='page-header-search-dropdown'
            >
              <form className='p-3'>
                <div className='form-group m-0'>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search ...'
                      aria-label="Recipient's username"
                    />
                    <button className='btn btn-primary' type='submit'>
                      <i className='mdi mdi-magnify' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <LanguageDropdown />

          <div className='dropdown d-none d-lg-inline-block ms-1'>
            <button
              type='button'
              onClick={() => {
                toggleFullscreen();
              }}
              className='btn header-item noti-icon '
              data-toggle='fullscreen'
            >
              <i className='mdi mdi-fullscreen' />
            </button>
          </div>

          <NotificationDropdown />
          <ProfileMenu />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
