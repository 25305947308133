import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import $filter from '@cash2pay/common/utils/$filter';
import { BLOCKED_CREDIT } from '@cash2pay/domains/CreditDomain';
import { USER_PROFILES } from '@cash2pay/domains/UserProfileDomain';
import { getSessionUserProfile } from '@cash2pay/helpers/api_helper';
import SvgStatus from '@cash2pay/pages/Receivables/ReceivablesScheduleDetail/components/PopoverInfo';

const EndUserReceivableBox = (props) => {
  const [userProfile] = useState(getSessionUserProfile());

  const handleChangeFilter = () => {
    let color = 'white';

    if (
      props.rec['balanceAvailable'] > 0 &&
      props.rec['balanceAvailable'] !== null &&
      props.rec['blockedCredit'] === BLOCKED_CREDIT.NO &&
      props.rec['blockedCredit'] !== null
    ) {
      color = '#8FFE09'; //green
    } else if (props.rec['blockedCredit'] === BLOCKED_CREDIT.YES) {
      color = '#FF7F00'; //orange
    } else if (!props.rec['compromisedValue']) {
      color = 'red'; //red
    }

    return color;
  };

  const color = handleChangeFilter();

  return (
    <Card className={'plan-box mb-0 h-100'} role='button'>
      <CardBody
        className='p-3'
        onClick={() => {
          props.navigateToEdit(props.rec);
        }}
      >
        <div className='d-flex justify-content-between align-items-start'>
          <div className='me-3'>
            <div className='avatar-sm mx-auto '>
              <span
                className='avatar-title rounded-circle bg-light font-size-16'
                style={{ color: 'black' }}
              >
                <i className='mdi mdi-store-outline font-size-24' />
              </span>
            </div>
          </div>
          <div className='flex-1'>
            <h5
              className='mb-1'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-title={props.rec['receivingEndUser']['name']}
            >
              {props.rec['receivingEndUser']['name'] !== null &&
              props.rec['receivingEndUser']['name'] !== undefined &&
              props.rec['receivingEndUser']['name'].length > 17
                ? props.rec['receivingEndUser']['name'].substr(0, 17) + '...'
                : props.rec['receivingEndUser']['name']}
            </h5>
            <p className='text-muted'>
              {$filter(props.rec['receivingEndUser']['legalId'], 'CPF_CNPJ')}
            </p>
          </div>
          <div className='top-0 end-0'>
            <SvgStatus color={color} index={props.itemKey} />
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between align-items-start'>
          <p
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-title='Razão social'
          >
            {props.rec['receivingEndUser']['name']}
          </p>
        </div>

        <div className='py-1 border-bottom'>
          <h3>
            {$filter(props.rec['balanceAvailable'], 'MONEY')}
            <span className='font-size-13 text-muted'>
              <br />
              Saldo disponível
            </span>
          </h3>
        </div>

        <ul className='list-unstyled plan-features mt-4'>
          <li>
            <i className='mdi mdi-circle-medium text-primary me-2'></i> Valor
            constituido: {$filter(props.rec['totalConstitutedValue'], 'MONEY')}
          </li>
          <li>
            <i className='mdi mdi-circle-medium text-primary me-2'></i> Período
            constituido: {props.rec['totalConstitutedIntervalMonths']} meses
          </li>
          <hr />

          {userProfile === USER_PROFILES.ADM ? (
            <div className='d-flex'>
              <div className='me-3'>
                <div className='avatar-sm mx-auto '>
                  <span
                    className='avatar-title rounded-circle bg-light font-size-16'
                    style={{ color: 'black' }}
                  >
                    <i className='mdi mdi-bank-outline font-size-24' />
                  </span>
                </div>
              </div>
              <div className='flex-1'>
                <h5 className='mb-1'>
                  {props.rec['financialAgent']['name'] !== null &&
                  props.rec['financialAgent']['name'] !== undefined &&
                  props.rec['financialAgent']['name'].length > 17
                    ? props.rec['financialAgent']['name'].substr(0, 17) + '...'
                    : props.rec['financialAgent']['name']}
                </h5>
                <p className='text-muted'>
                  {$filter(props.rec['financialAgent']['legalId'], 'CPF_CNPJ')}
                </p>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <li>
                <i className='mdi mdi-circle-medium text-primary me-2'></i>{' '}
                Valor comprometido:{' '}
                {$filter(props.rec['compromisedValue'], 'MONEY')}
              </li>

              <li>
                <i className='mdi mdi-circle-medium text-primary me-2'></i>{' '}
                Próxima Liquidação:{' '}
                {$filter(props.rec['nextSettlementDate'], 'DATE')}
              </li>
              <li>
                <i className='mdi mdi-circle-medium text-primary me-2'></i> Últ.
                Liquidação: {$filter(props.rec['lastSettlementDate'], 'DATE')}
              </li>
            </React.Fragment>
          )}
        </ul>
      </CardBody>
    </Card>
  );
};

export default EndUserReceivableBox;
