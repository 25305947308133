import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { Badge } from 'reactstrap';

const GenerateStatusBadge = ({ status, id }) => {
  const optinStatus = [
    { label: 'Ativo', value: 'A' },
    { label: 'Inativo', value: 'I' },
    { label: 'Aguardando assinatura', value: 'S' },
    { label: 'Pendente', value: 'P' },
    { label: 'Com Error', value: 'E' },
  ];
  let label = optinStatus[0].label;
  let color = 'primary';
  switch (status) {
    case 'A':
      color = 'success';
      label = optinStatus[0].label;
      break;
    case 'I':
      color = 'danger';
      label = optinStatus[1].label;
      break;
    case 'S':
      color = 'warning';
      label = optinStatus[2].label;
      break;
    case 'P':
      color = 'info';
      label = optinStatus[3].label;
      break;
    default:
      color = 'danger';
      label = optinStatus[4].label;
      break;
  }

  return (
    <Badge
      className={`badge bg-${color} rounded-pill font-size-12`}
      color={color}
      pill={true}
      key={`status-${status}-${id}`}
    >
      {label}
    </Badge>
  );
};

export default withRouter(withTranslation()(GenerateStatusBadge));
