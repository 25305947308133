import React from 'react';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Label } from 'reactstrap';

const DEFAULT_MASK = '(99) 99999-9999';

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mask: DEFAULT_MASK,
      isValid: true,
      $error: null,
    };
  }

  setValue = (event) => {
    const newState = Object.assign({}, this.state);

    newState.value = event.target.value;

    const valueWithoutMask = event.target.value.replace(/\D/g, '').trim();

    if (this.props.onChange) {
      this.props.onChange({
        target: { value: valueWithoutMask, name: event.target.name },
      });
    }

    const validate = this.onChangeValidationCore(event);
    if (!newState.isValid && validate.isValid) {
      newState.isValid = validate.isValid;
    }
    this.setState(newState);
  };

  validate = (valueWithoutMask) => {
    if (
      valueWithoutMask !== null &&
      valueWithoutMask !== undefined &&
      valueWithoutMask.length > 0
    ) {
      if (valueWithoutMask.length === 11) {
        return { isValid: true };
      }
      return { isValid: false, $error: 'Valor invalido' };
    }
    if (this.props['required'] === true) {
      return { isValid: false, $error: 'Valor obrigatorio' };
    }
    return { isValid: true };
  };

  onChangeValidation = (event) => {
    const validate = this.onChangeValidationCore(event);
    this.setState(Object.assign(this.state, validate));
  };

  onChangeValidationCore = (event) => {
    const valueWithoutMask = event.target.value.replace(/\D/g, '').trim();

    const validate = this.validate(valueWithoutMask);

    if (this.props.onChangeValidation) {
      this.props.onChangeValidation(this.props.id, validate.isValid);
    }
    return validate;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.label !== null && this.props.label !== undefined ? (
          <Label for={this.props.id} className='form-label'>
            {`${this.props.required === true ? '*' : ''}${this.props.label}`}
          </Label>
        ) : (
          ''
        )}

        <InputMask
          className={
            this.state.isValid ? 'form-control' : 'form-control is-invalid'
          }
          mask={this.state.mask}
          maskChar=' '
          readOnly={
            this.props.readOnly !== null && this.props.readOnly !== undefined
              ? this.props.readOnly
              : false
          }
          value={
            this.props['value'] !== undefined && this.props['value'] !== null
              ? this.props['value']
              : null
          }
          alwaysShowMask={false}
          placeholder={this.props.placeholder}
          type='text'
          onBlur={this.onChangeValidation}
          id={this.props.id}
          name={this.props.id}
          onChange={this.setValue}
          autoComplete={this.props.autoComplete ? 'on' : 'off'}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(PhoneInput);
