import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';

import $filter from '../../common/utils/$filter';
import ErrorDialog from '../../components/Common/ErrorDialog';
import InputDate from '../../components/Common/InputDate';
import InputMoney from '../../components/Common/InputMoney';
import Loader from '../../components/Common/Loader';
import { post } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';

function LiquidationView(props) {
  const [innerHeight] = useState(window.innerHeight);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [entity, setEntity] = useState(props.entity);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(false);
  };

  const afterClosed = () => {
    props.callback({ isOpen: false, entity: null, event: 'CLOSE' });
  };

  const close = (newEntity) => {
    props.callback({ isOpen: false, entity: newEntity, event: 'SAVED' });
  };

  const validate = () => {
    if (!entity.liquidationDate) {
      setError({
        title: 'Data de liquidação inválida',
        detail: 'Informe a data de liquidação',
      });
      return false;
    }
    if (!entity.liquidationValue) {
      setError({
        title: 'Valor de liquidação inválido',
        detail: 'Informe o valor de liquidação',
      });
      return false;
    }
    return true;
  };

  const registerLiquidation = async () => {
    if (!validate()) return;
    try {
      const response = await post(
        url.POST_LIQUIDATION_CTR,
        {
          originId: parseInt(entity.contractId),
          installmentNumber: entity.installmentNumber,
          amountDue: entity.amountReceivable,
          amountReceived: entity.liquidationValue,
          liquidationDate: entity.liquidationDate,
        },
        null,
        setLoading,
      );
      close(Object.assign(response.liquidation, entity));
    } catch (e) {
      setError(e);
    }
  };

  const handleChangeField = (event) => {
    entity[event.target.name] = event.target.value;
    setEntity({ ...entity });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
        }}
        onClosed={() => {
          afterClosed();
        }}
        modalClassName='drawer right-align'
        contentClassName='modal-content-scroll'
      >
        <Scrollbars style={{ height: innerHeight - 80 }}>
          <div className='modal-header'>
            <h5 className='modal-title mt-0'>{props.t('Liquidation View')}</h5>
            <button
              type='button'
              onClick={() => {
                toggle();
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <div className='modal-body'>
            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>{props.t('Contract ID')}</p>
                <strong>{entity.contractId}</strong>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>
                  {props.t('External Reference')}
                </p>
                <strong>{entity.externalReference}</strong>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>{props.t('Due date')}</p>
                <strong>{$filter(entity.dueDate, 'DATE')}</strong>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>
                  {props.t('Interest Value')}
                </p>
                <strong>{$filter(entity.interestValue, 'MONEY')}</strong>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>
                  {props.t('Amount receivable')}
                </p>
                <strong>{$filter(entity.amountReceivable, 'MONEY')}</strong>
              </Col>
            </Row>

            <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>{props.t('Contractor')}</p>
                <strong>{entity.contractor.name}</strong>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Col lg={12}>
                <p className='card-title-desc mb-0'>{props.t('Debitor')}</p>
                <strong>{entity.debitor.name}</strong>
              </Col>
            </Row>

            <hr style={{ borderTop: '1px solid rgb(187 187 187)' }} />

            <Row className='mb-3'>
              <Col lg={12} className='mb-3'>
                <InputDate
                  id='liquidationDate'
                  onChange={handleChangeField}
                  required={true}
                  label={props.t('Liquidation date')}
                  autoComplete='off'
                />
              </Col>

              <Col lg={12} className='mb-3'>
                <InputMoney
                  id='liquidationValue'
                  required={true}
                  onChange={handleChangeField}
                  label={props.t('Liquidation value')}
                />
              </Col>
            </Row>
          </div>
        </Scrollbars>

        <div className='modal-footer fixed-bottom'>
          <div className='text-end'>
            <button
              className='btn btn-primary w-sm waves-effect waves-light'
              onClick={registerLiquidation}
            >
              Registrar Liquidação
            </button>
          </div>
        </div>

        {error ? (
          <ErrorDialog
            title={error['title']}
            onClose={() => {
              setError(null);
            }}
          >
            <p>{error['detail']} </p>
          </ErrorDialog>
        ) : (
          ''
        )}

        {loading ? <Loader loading={loading} /> : ' '}
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(LiquidationView);
