import { Card, CardBody } from 'reactstrap';

import $filter from '@cash2pay/common/utils/$filter';
import { BLOCKED_CREDIT } from '@cash2pay/domains/CreditDomain';
import SvgStatus from '@cash2pay/pages/Receivables/ReceivablesScheduleDetail/components/PopoverInfo';

const EndUserBySellerReceivableBox = (props) => {
  const handleChangeFilter = () => {
    let color = 'white';

    if (
      props.rec['balanceAvailable'] > 0 &&
      props.rec['balanceAvailable'] !== null &&
      props.rec['blockedCredit'] === BLOCKED_CREDIT.NO &&
      props.rec['blockedCredit'] !== null
    ) {
      color = '#8FFE09'; //green
    } else if (props.rec['blockedCredit'] === BLOCKED_CREDIT.YES) {
      color = '#FF7F00'; //orange
    } else if (!props.rec['compromisedValue']) {
      color = 'red'; //red
    }

    return color;
  };

  const color = handleChangeFilter();

  return (
    <Card className={'plan-box mb-0 h-100'}>
      <CardBody
        className='p-4'
        onClick={() => {
          if (typeof props.navigateToEdit === 'function') {
            props.navigateToEdit(props.rec);
          }
        }}
      >
        <div className='d-flex justify-content-between align-items-start'>
          <div className='d-flex align-items-center jus'>
            <div className='me-3'>
              <div className='avatar-sm mx-auto'>
                <span
                  className='avatar-title rounded-circle bg-light font-size-16'
                  style={{ color: 'black' }}
                >
                  <i className='mdi mdi-store-outline font-size-24' />
                </span>
              </div>
            </div>
            <div className='flex-1'>
              <h5
                className='mb-1'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-title='Nome fantasia'
              >
                {props.rec['receivingEndUser']['legalName'] !== null &&
                props.rec['receivingEndUser']['legalName'] !== undefined &&
                props.rec['receivingEndUser']['legalName'].length > 17
                  ? props.rec['receivingEndUser']['legalName'].substr(0, 17) +
                    '...'
                  : props.rec['receivingEndUser']['legalName']}
              </h5>
              <p className='text-muted'>
                {$filter(props.rec['receivingEndUser']['legalId'], 'CPF_CNPJ')}
              </p>
            </div>
          </div>
          <div className=''>
            <SvgStatus color={color} index={props.itemKey} />
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-start'>
          <p
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-title='Razão social'
          >
            {props.rec['receivingEndUser']['name']}
          </p>
        </div>
        <div className='py-1 border-bottom'>
          <h3>
            {$filter(props.rec['creditLimit'], 'MONEY')}
            <span className='font-size-13 text-muted'>
              <br />
              Limite disponível
            </span>
          </h3>
        </div>

        <ul className='list-unstyled plan-features mt-4'>
          <li>
            <i className='mdi mdi-circle-medium text-primary me-2'></i> Limite
            global: {$filter(props.rec['creditLimit'], 'MONEY')}
          </li>
          <li>
            <i className='mdi mdi-circle-medium text-primary me-2'></i> Limite
            tomado: {$filter(props.rec['limitCompromised'], 'MONEY')}
          </li>
          <li>
            <i className='mdi mdi-circle-medium text-primary me-2'></i> Limite
            parcela: {$filter(props.rec['installmentCreditLimit'], 'MONEY')}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default EndUserBySellerReceivableBox;
