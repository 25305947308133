import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import $filter from '@cash2pay/common/utils/$filter';
import { BLOCKED_CREDIT } from '@cash2pay/domains/CreditDomain';
import { USER_PROFILES } from '@cash2pay/domains/UserProfileDomain';
import { getSessionUserProfile, hasRole } from '@cash2pay/helpers/api_helper';
import { isToday } from '@cash2pay/libs/date';
import AnticipationContractModal from '@cash2pay/pages/Contracts/AnticipationContract/AnticipationContract';

function Component(props) {
  const [userProfile] = useState(getSessionUserProfile());

  const [modalNewAnicipationContract, setModalNewAnicipationContract] =
    useState(false);

  const isOutdated = props.entity.lastUpdateDate
    ? !isToday(`${props.entity.lastUpdateDate}T00:00:00`)
    : false;

  const isShowAntecipateButton =
    hasRole('contract.write') &&
    props.entity.balanceAvailable > 0 &&
    !props.loading;

  const closeModalNewAnicipationContract = () => {
    setModalNewAnicipationContract(false);
  };

  const openModalNewAnicipationContract = () => {
    setModalNewAnicipationContract(true);
  };

  let firstKpi = {
    label: 'Saldo disponivel',
    amount: $filter(props.entity.balanceAvailable, 'MONEY'),
  };
  let secondKpi = {
    label: 'Valor comprometido',
    amount: $filter(props.entity.compromisedValue, 'MONEY'),
  };

  if (userProfile === USER_PROFILES.SELLER) {
    firstKpi = {
      label: 'Limite disponível',
      amount: $filter(props.entity.creditLimit, 'MONEY'),
    };
    secondKpi = {
      label: 'Limite tomado',
      amount: $filter(props.entity.creditLimitCompromised, 'MONEY'),
    };
  }

  return (
    <>
      <Card
        style={{
          marginRight: -22,
          marginLeft: -22,
          paddingLeft: 22,
        }}
      >
        <CardBody>
          <Row>
            <Col lg={4}>
              <p className='text-muted mb-0'>
                {props.entity.receivingEndUser.legalName}
              </p>
              <h4>{props.entity.receivingEndUser.name}</h4>
              <p className='text-muted mb-0'>
                CPF/CNPJ:{' '}
                {$filter(props.entity.receivingEndUser.legalId, 'CPF_CNPJ')}
              </p>
            </Col>
            <Col lg={2}>
              <div>
                <blockquote className='blockquote font-size-16 mb-0'>
                  <p className='text-muted mb-0'>{firstKpi.label}</p>
                  <h4>{firstKpi.amount}</h4>
                </blockquote>
              </div>
            </Col>
            <Col lg={2}>
              {isShowAntecipateButton && (
                <>
                  <button
                    type='button'
                    className='btn btn-primary waves-effect waves-light mt-3'
                    onClick={openModalNewAnicipationContract}
                    disabled={isOutdated}
                    style={{ marginLeft: -50, pointerEvents: 'initial' }}
                    id='tooltip-antecipate'
                  >
                    Antecipar
                  </button>
                  {isOutdated && (
                    <UncontrolledTooltip target='tooltip-antecipate'>
                      A agenda de recebíveis deve estar atualizada para a data
                      de hoje.
                    </UncontrolledTooltip>
                  )}
                </>
              )}
            </Col>
            <Col lg={3}>
              <div>
                <blockquote
                  className='blockquote font-size-16 mb-0'
                  style={{ borderLeft: '4px solid #f56e50' }}
                >
                  <p className='text-muted mb-0'>{secondKpi.label}</p>
                  <h4>{secondKpi.amount}</h4>
                </blockquote>
              </div>
            </Col>
          </Row>

          {props.entity['blockedCredit'] === BLOCKED_CREDIT.YES && (
            <Row className='mt-4'>
              <Col lg={11}>
                <Alert color='warning' role='alert'>
                  <i className='mdi mdi-calendar-lock font-size-20' />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial com </span>
                  <strong>
                    Bloqueio de Agenda ({props.entity['tpBlockedCredit']})
                  </strong>
                  <span>
                    , para operações de crédito é necessário remover os
                    bloqueios. Solicite orientação para equipe de Suporte de
                    Negócio
                  </span>
                </Alert>
              </Col>
            </Row>
          )}

          {!props.entity['lastUpdateDate'] && props.entity['hasLoadedApi'] && (
            <Row className='mt-4'>
              <Col lg={11}>
                <Alert color='info' role='alert'>
                  <i className='mdi mdi-clock font-size-20' />
                  &nbsp;&nbsp;
                  <span>Estabelecimento comercial </span>
                  <strong>sem Agenda de Recebíveis</strong>
                  <span>
                    , até o nomento não recebemos as agendas das maquininhas de
                    cartão.
                  </span>
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      {modalNewAnicipationContract && (
        <AnticipationContractModal
          isOpen={modalNewAnicipationContract}
          receivableUnits={props.entity.receivableUnitsAvailable}
          receivingEndUser={Object.assign(
            {
              bankAccounts: props.entity.bankAccounts,
            },
            props.entity.receivingEndUser,
          )}
          financialAgent={props.entity.financialAgent}
          callback={(entity) => {
            closeModalNewAnicipationContract(entity);
          }}
        />
      )}
    </>
  );
}

export const UserIdentifierPanel = withTranslation()(Component);
