import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import $filter from '@cash2pay/common/utils/$filter';
import Placeholder from '@cash2pay/components/Common/Placeholder';
import PaymentMethodDomain from '@cash2pay/domains/PaymentMethodDomain';
// import CheckoutSelectInstallments from './CheckoutSelectInstallments';

export const CheckoutTicketSeller = (props) => {
  const translation = useTranslation();

  return (
    <React.Fragment>
      <div className='p-lg-5 p-4 bg-primary d-lg-block text-start h-100'>
        <div className='sticky-top' style={{ top: 24 }}>
          <p className='checkout-ticket-title mt-0 mb-1 mt-4'>
            {translation.t('Payment of')}
          </p>
          <p className='checkout-ticket-amount mt-0 mb-2'>
            {$filter(props.entity.amount, 'MONEY')}
          </p>

          <p className='checkout-ticket-seller mt-0 mb-2'>
            <span style={{ color: '#ffffff9e' }}>para&nbsp;</span>
            <Placeholder
              isLoading={props.firstLoad}
              value={props.entity.seller.name}
            />
          </p>

          <p className='checkout-ticket-installments mt-0 mb-2'>
            {props.entity.paymentMethod !== 'CASH.FLOW' && !props.firstLoad && (
              <span>
                {`${translation.t('in')} ${props.entity.numInstallments}x ${translation.t('of')} ${$filter(props.entity.installmentAmount, 'MONEY')}`}
              </span>
            )}
            {props.entity.paymentMethod !== 'CASH.FLOW' &&
              props.paymentPeriodSelected &&
              props.paymentPeriodSelected.numberInstallments > 0 && (
                <span>
                  {`${translation.t('in')} ${props.paymentPeriodSelected.numberInstallments}x ${translation.t('of')} ${$filter(props.paymentPeriodSelected.installmentMainAmount, 'MONEY')}`}
                </span>
              )}
            {props.entity.paymentMethod === 'CASH.FLOW' && (
              <span>
                {'pagando com '}
                <strong>saldo de recebíveis de cartão</strong>
              </span>
            )}
          </p>

          {/* {
                    props.entity.paymentMethod === 'CASH.NOW'
                    && !props.firstLoad && props.entity.paymentOptions
                    && !props.entity.isSigned ?

                        <Row className="mt-4">
                            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                    <CheckoutSelectInstallments
                                        onChange={(option)=>{
                                            if (props.handlePaymentPeriod)
                                                props.handlePaymentPeriod(option)
                                        }}
                                        paymentOptions = {props.entity.paymentOptions}
                                    />
                            </Col>
                        </Row>
                        :
                        ("")
                } */}

          <hr />

          <Row className='mb-2'>
            <Col xl={3} lg={6} md={6} sm={6} xs={6}>
              <span className='checkout-ticket-info mt-0 mb-0'>
                {translation.t('Legal ID Company')}
              </span>
            </Col>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='text-end'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                <Placeholder
                  isLoading={props.firstLoad}
                  value={props.entity.seller.legalId}
                />
              </span>
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='mb-2'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                {translation.t('Identifier')}
              </span>
            </Col>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='text-end'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                <Placeholder
                  isLoading={props.firstLoad}
                  value={props.entity.invoiceNumber}
                />
              </span>
            </Col>
          </Row>

          <Row>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='mb-2'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                {translation.t('Date')}
              </span>
            </Col>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='text-end'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                <Placeholder
                  isLoading={props.firstLoad}
                  value={$filter(props.entity.orderDate, 'DATE')}
                />
              </span>
            </Col>
          </Row>

          {/* {
                    props.entity.deadline && !props.paymentPeriodSelected ?
                        <Row>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-2">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            {translation.t("Payment period")}
                        </span>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6} xs={6} className="text-end">
                        <span className="checkout-ticket-info mt-0 mb-0">
                            <Placeholder
                                isLoading={props.firstLoad}
                                value={props.entity.deadline}
                            />
                        </span>
                            </Col>
                        </Row>
                        : ("")
                } */}
          {props.entity.deadline ? (
            <Row>
              <Col xl={3} lg={6} md={6} sm={6} xs={6} className='mb-2'>
                <span className='checkout-ticket-info mt-0 mb-0'>
                  {translation.t('Payment period')}
                </span>
              </Col>
              <Col xl={3} lg={6} md={6} sm={6} xs={6} className='text-end'>
                <span className='checkout-ticket-info mt-0 mb-0'>
                  <Placeholder
                    isLoading={props.firstLoad}
                    value={props.entity.deadline}
                  />
                </span>
              </Col>
            </Row>
          ) : (
            ''
          )}

          <Row>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='mb-2'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                {translation.t('Form of payment')}
              </span>
            </Col>
            <Col xl={3} lg={6} md={6} sm={6} xs={6} className='text-end'>
              <span className='checkout-ticket-info mt-0 mb-0'>
                <Placeholder
                  isLoading={props.firstLoad}
                  value={
                    PaymentMethodDomain.getInstanceFromValue(
                      props.entity.paymentMethod,
                    )['endUserLabel']
                  }
                />
                <small>
                  &nbsp;&nbsp;(
                  {
                    PaymentMethodDomain.getInstanceFromValue(
                      props.entity.paymentMethod,
                    )['name']
                  }
                  )
                </small>
              </span>
            </Col>
          </Row>

          <hr />
          <Row className='mb-2'>
            <Col>
              <span className='checkout-ticket-info mt-0 mb-0'>
                {translation.t('Message')}
              </span>{' '}
              <br />
              <span className='checkout-ticket-info mt-0 mb-0'>
                {props.entity.message}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
