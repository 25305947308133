import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';

import checkSuccess from '@cash2pay/assets/images/check-success.svg';

function Component(props) {
  const [state, setState] = useState({
    isOpen: props.isOpen,
    entity: props.entity,
    loading: false,
    error: null,
  });

  const sendWhats = () => {
    const message = `Olá, 
gostaria de informação do pedido`;

    const whatsUrl = `https://wa.me/?phone=555481280059&text=${encodeURI(message)}`;

    window.open(whatsUrl, '_blank');
  };

  return (
    <React.Fragment>
      <div className='p-lg-5 p-4 d-lg-block text-center'>
        <div>
          <Row className='mt-3'>
            <Col>
              <img src={checkSuccess} alt='' height='160' />
            </Col>
          </Row>

          <Row className='justify-content-center mt-4'>
            <h4>Pagamento em processamento!</h4>
          </Row>

          <Alert color='success' className='text-center mb-4 mt-4'>
            <strong style={{ fontSize: 16 }}> Acompanhe pelo e-mail:</strong>
            <p> {state.entity.buyer.email}</p>
          </Alert>

          <Row className='justify-content-center text-center mt-2'>
            <Col>
              {' '}
              <button
                className='btn btn-primary w-sm waves-effect waves-light w-100'
                onClick={sendWhats}
              >
                Suporte&nbsp;&nbsp;
                <i className='bx bxl-whatsapp font-size-20' />
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export const CheckoutFinished = withTranslation()(Component);
