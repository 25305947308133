import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';

import AntecipationContractCalc from '../../../common/utils/AntecipationContractCalc';
import DateTimeUtils from '../../../common/utils/DateTimeUtils';
import InputDate from '../../../components/Common/InputDate';
import InputMoney from '../../../components/Common/InputMoney';
import Select2Acquirers from '../../../components/Common/Select2Acquirers';
import Select2Arrangements from '../../../components/Common/Select2Arrangements';
import Select2DistributionType from '../../../components/Common/Select2DistributionType';
import Select2DivisionRule from '../../../components/Common/Select2DivisionRule';
import SelectEndUserReceivablesGravame from '../../../components/Common/SelectEndUserReceivablesGravame';

function GarantiasModal({
  acquirers = [],
  arrangements = [],
  guarantees = [],
  setGuarantees = () => {},
  toggleModal,
  afterClosed,
  receivingEndUserLegalId,
  holderLegalId,
  isOpen = false,
  contract,
  ...props
}) {
  const [entity, setEntity] = useState({
    acquirers: [],
    arrangements: [],
    receivingEndUser: {
      legalId: receivingEndUserLegalId,
    },
    holder: {
      legalId: holderLegalId,
    },
    divisionRule: '1',
    typeDistribution: '1',
    startDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
    endDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
    amountCharged: 0,
  });

  const handleAcquirers = (selectAcquirers) => {
    setEntity((e) => ({ ...e, acquirers: selectAcquirers }));
  };

  const handleArrangements = (selectarrangements) => {
    setEntity((e) => ({ ...e, arrangements: selectarrangements }));
  };

  const handleFieldEntity = (event) => {
    const { name, value } = event.target;
    setEntity((e) => ({ ...e, [name]: value }));
  };

  const save = (e) => {
    e.preventDefault();
    setGuarantees([...guarantees, entity]);
    toggleModal();
  };

  useEffect(() => {
    setEntity((e) => ({
      ...e,
      receivingEndUser: {
        legalId: receivingEndUserLegalId,
      },
      holder: {
        legalId: holderLegalId,
      },
    }));
  }, [receivingEndUserLegalId, holderLegalId]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        modalClassName='drawer right-align'
        toggle={toggleModal}
        onClosed={afterClosed}
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>{props.t('Register guarante')}</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={afterClosed}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12} className='mb-3'>
                  <Select2Acquirers
                    id='selectAcquirersGuarante'
                    required={true}
                    acquirers={acquirers}
                    label={props.t('Acquirer')}
                    onChange={(options) => {
                      handleAcquirers(options);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} className='mb-3'>
                  <Select2Arrangements
                    id='selectArrangementsGuarante'
                    label={props.t('Payment arrangement')}
                    required={true}
                    arrangements={arrangements?.map((arrangement) => ({
                      ...arrangement,
                      label: arrangement?.name,
                      value: arrangement?.code,
                    }))}
                    onChange={(options) => {
                      handleArrangements(options);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} className='mb-3'>
                  <InputDate
                    id='startDate'
                    required={true}
                    onChange={(event) => handleFieldEntity(event)}
                    label={props.t('Start date')}
                    value={entity.startDate}
                  />
                </Col>
                <Col lg={6} className='mb-3'>
                  <InputDate
                    id='endDate'
                    required={true}
                    onChange={(event) => handleFieldEntity(event)}
                    label={props.t('End date')}
                    value={entity.endDate}
                  />
                </Col>
              </Row>

              <p className='card-title-desc mt-3 mb-0'>
                {props.t('Encumbrance rules')}
              </p>
              <hr />

              <Row>
                <Col lg={6} className='mb-3'>
                  <Select2DistributionType
                    label={props.t('Distribution Type')}
                    required={true}
                    onChange={(options) => {
                      handleFieldEntity({
                        target: {
                          name: 'typeDistribution',
                          value: options['value'],
                        },
                      });
                    }}
                    value={entity.typeDistribution}
                  />
                </Col>

                <Col lg={6} className='mb-3'>
                  <Select2DivisionRule
                    label={props.t('Division rule')}
                    required={true}
                    onChange={(options) => {
                      handleFieldEntity({
                        target: {
                          name: 'divisionRule',
                          value: options['value'],
                        },
                      });
                    }}
                    value={entity.divisionRule}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} className='mb-3'>
                  <InputMoney
                    id='amountCharged'
                    onChange={(event) => handleFieldEntity(event)}
                    className='gravame'
                    required={true}
                    label={props.t('Amount to be charged')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} className='mb-3'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary mr-5'
                    onClick={() => {
                      console.log(contract, entity);
                    }}
                  >
                    <i className='mdi mdi-refresh font-size-16 mr-5' />
                    {props.t('Atualizar')}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className='modal-footer'>
          <div className='text-end'>
            <button
              className='btn btn-primary w-sm waves-effect waves-light'
              onClick={save}
            >
              {props.t('Add')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(GarantiasModal);
