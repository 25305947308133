import { useState } from 'react';

import { receivablesService } from '../../../../services/http';

const createTabs = (t) => [
  {
    title: t('Summary'),
    value: 'summary',
  },
  {
    title: t('Receivable Units Available'),
    value: 'availables',
  },
  {
    title: t('Unavailable receivables'),
    value: 'compromiseds',
  },
  {
    title: t('Not constituted receivables'),
    value: 'futureCommitments',
  },
  { title: t('Obligations'), value: 'obligations' },
];

export const useReceivableUnitsTabs = ({ t, receivable }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('summary');
  const [units, setUnits] = useState({
    obligations: [],
  });

  const tabs = createTabs(t);

  const getAllObligationsUnits = async (legalId) => {
    if (units.obligations.length > 0) return;

    try {
      const response =
        await receivablesService.getAllObligationsUnitsByLegalId(legalId);
      setUnits((prevState) => ({
        ...prevState,
        obligations: response.endUserObligations,
      }));
    } catch {
      //
    }
  };

  const selectTab = async (selectedTab) => {
    if (isLoading) return;

    const receivableRequests = {
      [tabs[4].value]: getAllObligationsUnits,
    };

    const receivableRequest = receivableRequests[selectedTab];

    setIsLoading(true);

    await receivableRequest?.(receivable.receivingEndUser.legalId);

    setIsLoading(false);
    setActiveTab(selectedTab);
  };

  return {
    tabs,
    units,
    activeTab,
    selectTab,
    isLoading,
  };
};
