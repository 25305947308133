import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { Col, Row, Modal } from 'reactstrap';

import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import $filter from '../../common/utils/$filter';
import ArrayUtils from '../../common/utils/ArrayUtils';
import DateTimeUtils from '../../common/utils/DateTimeUtils';
import StringUtils from '../../common/utils/StringUtils';
import ErrorDialog from '../../components/Common/ErrorDialog';
import FormUtils from '../../components/Common/FormUtils';
import InputDate from '../../components/Common/InputDate';
import LegalIdInput from '../../components/Common/LegalIdInput';
import Loader from '../../components/Common/Loader';
import Select2Acquirers from '../../components/Common/Select2Acquirers';
import Select2Arrangements from '../../components/Common/Select2Arrangements';
import { USER_PROFILES } from '../../domains/UserProfileDomain';
import { getSessionUserProfile, post } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';

class ManageOptinNew extends React.Component {
  constructor(props) {
    super(props);
    const profile = getSessionUserProfile();
    this.state = {
      isOpen: this.props.isOpen,
      innerHeight: window.innerHeight,

      entity: {
        startDate: DateTimeUtils.format(new Date()),
        endDate: DateTimeUtils.format(DateTimeUtils.addDays(new Date(), 30)),
        receivingEndUser: { legalId: null },
        arrangements: [{ code: 'ALL', label: this.props.t('All') }],
        acquirers: [],
        requestSign: profile === USER_PROFILES.FINANCIAL,
      },

      loading: false,
      form: {
        legalIdreceivingEndUser: { isValid: false },
      },
      error: null,
      profile: profile,
      requestSign: {
        checked: profile === USER_PROFILES.FINANCIAL ? true : undefined,
      },
      basicLayout: profile === USER_PROFILES.SELLER,
    };
  }

  setLoading = (flag) => {
    this.setState(Object.assign(this.state, { loading: flag }));
  };

  toggle = () => {
    this.setState({ isOpen: false });
  };

  afterClosed = () => {
    this.props.callback({ isOpen: false, entity: null });
  };

  close = (newEntity) => {
    this.props.callback({ isOpen: false, entity: newEntity });
  };

  onChangeValidation = (field, isValid) => {
    this.state.form[field] = { isValid: isValid };
    this.setState(this.state);
  };

  handleFieldEntity = (name, value) => {
    this.state.entity[name] = value;
    this.setState(this.state);
  };

  setError = (error) => {
    this.state.error = error;
    this.setState(this.state);
  };

  normalizedFieldValue = (value) => {
    return value !== null && value !== undefined && value.toString().length > 0
      ? value
      : null;
  };

  handleArrangements = (arrangements) => {
    let arrangementsMap = arrangements || [];
    this.handleFieldEntity(
      'arrangements',
      arrangementsMap.length > 0
        ? arrangementsMap.map((opt) => {
            return { code: opt.code };
          })
        : null,
    );
  };

  handleAcquirers = (acquirers) => {
    let acquirersMap = acquirers || [];

    acquirersMap = acquirersMap.filter((ac) => {
      return ac['legalId'] !== 'ALL';
    });

    this.handleFieldEntity(
      'acquirers',
      acquirersMap.length > 0
        ? acquirersMap.map((opt) => {
            return { legalId: opt.legalId };
          })
        : null,
    );
  };

  handleRequestSign = (event) => {
    this.handleFieldEntity('requestSign', event.target.checked);
  };

  isValid = () => {
    let valid = FormUtils.isValid(this.state.form);
    valid = valid && ArrayUtils.isNotEmpty(this.state.entity.arrangements);
    valid = valid && StringUtils.isNotEmpty(this.state.entity.startDate);

    return valid;
  };

  save = async () => {
    if (this.state.loading) return;

    if (!this.isValid(this.state.form)) return;
    try {
      const response = await post(
        url.POST_OPTIN,
        this.state.entity,
        null,
        this.setLoading,
      );
      this.close(response['optin']);
    } catch (error) {
      console.error('FALHA AO CRIAR OPTIN', error.toJSON());
      const e = error?.response?.data;
      if (e['status'] !== '200' && e['status'] !== '403') {
        if (e.violations && e.violations.length) {
          const planMaxOptin = e.violations.filter(
            (v) => v['property'] === 'accessPlan.planMaxOptin',
          );
          const isPlanOnline = e.violations.filter(
            (v) => v['property'] === 'accessPlan.isPlanOnline',
          );
          if (planMaxOptin && planMaxOptin.length) {
            this.openAccessPlanExcededModal();
          } else if (isPlanOnline && isPlanOnline.length) {
            this.openAccessPlanActiveModal();
          } else {
            this.openInvalidTimeModal();
          }
        } else {
          this.setError(e);
        }
      } else {
        this.setError(e);
      }
    }
  };

  openInvalidTimeModal = () => {
    const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você esta tentando realizar uma consulta fora do horario permitido... <br>    
                </p>
                <p>
                    Horário permitido para consultas: 9:00 às 17:00.
                </p>
            </div>`;

    Swal.fire({
      title: 'Horário de Consulta',
      icon: 'info',
      html: html,
      confirmButtonText: 'Certo!',
      cancelButtonText: 'Cancelar...',
    });
  };

  openAccessPlanExcededModal = () => {
    const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que você excedeu o seu plano de acesso. <br>    
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`;

    Swal.fire({
      title: 'Plano de Acesso',
      icon: 'info',
      html: html,
      confirmButtonText: 'Certo!',
      cancelButtonText: 'Cancelar...',
    });
  };

  openAccessPlanActiveModal = () => {
    const html = `<div>
                <p className="card-title-desc mb-0">
                   Parece que seu plano de acesso esta suspenso. <br>
                </p>
                <p>
                    Contate nosso setor financeiro para acessar um plano que se ajuste melhor a sua necessidade!
                </p>
                <p>
                    <a href="mailto:contato@cashtf.com">contato@cashtf.com</a>
                </p>
            </div>`;

    Swal.fire({
      title: 'Plano de Acesso',
      icon: 'info',
      html: html,
      confirmButtonText: 'Certo!',
      cancelButtonText: 'Cancelar...',
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isOpen}
          toggle={() => {
            this.toggle();
          }}
          onClosed={() => {
            this.afterClosed();
          }}
          modalClassName='drawer right-align'
        >
          <div className='modal-header'>
            <h5 className='modal-title mt-0'>
              {this.props.t('Register Opt-in')}
            </h5>
            <button
              type='button'
              onClick={() => {
                this.toggle();
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <div className='modal-body'>
            <Row className={this.state.basicLayout ? 'd-none' : ''}>
              <Col lg={12} className='mb-3'>
                <InputDate
                  id='endDate'
                  required={true}
                  value={this.state.entity.endDate}
                  onChange={(event) =>
                    this.handleFieldEntity(
                      'endDate',
                      this.normalizedFieldValue(event.target.value),
                    )
                  }
                  popover={this.props.t(
                    'After this date, the Opt-in will be automatically deactivated, revoking the access permission to the receivables schedule',
                  )}
                  label={this.props.t('Opt-in expiration date')}
                />
              </Col>
            </Row>

            <p className='card-title-desc mt-3 mb-0'>
              {this.props.t('Origin of the receivables')}
            </p>
            <hr />

            <Row>
              <Col lg={12} className='mb-3'>
                <LegalIdInput
                  id='legalIdreceivingEndUser'
                  required={true}
                  autoComplete='off'
                  onChangeValidation={this.onChangeValidation}
                  onChange={(event) => {
                    this.handleFieldEntity('receivingEndUser', {
                      legalId: this.normalizedFieldValue(event.target.value),
                    });
                  }}
                  label={this.props.t('Legal Id Receiving end user')}
                />
              </Col>
            </Row>

            <Row className={this.state.basicLayout ? 'd-none' : ''}>
              <Col lg={12} className='mb-3'>
                <Select2Acquirers
                  id='selectAcquirers'
                  defaultValue={0}
                  menuPlacement='bottom'
                  label={this.props.t('Acquirer')}
                  onChange={(options) => {
                    this.handleAcquirers(options);
                  }}
                />
              </Col>
            </Row>

            <Row className={this.state.basicLayout ? 'd-none' : ''}>
              <Col lg={12} className='mb-3'>
                <Select2Arrangements
                  label={this.props.t('Payment arrangement')}
                  menuPlacement='top'
                  defaultValue={0}
                  required={true}
                  onChange={(options) => {
                    this.handleArrangements(options);
                  }}
                />
              </Col>
            </Row>

            <Row className={this.state.basicLayout ? 'd-none mt-2' : 'mt-2'}>
              <Col lg={12} className='mb-3'>
                <div className='col-lg-12'>
                  <div className='form-check form-check-active'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={this.state.entity.requestSign}
                      name='requestSigning'
                      id='requestSigning'
                      onChange={(event) => {
                        this.handleRequestSign(event);
                      }}
                      {...this.state.requestSign}
                    />
                    <label
                      htmlFor='requestSigning'
                      className='form-check-label'
                    >
                      {this.props.t('Request signing')}
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className='modal-footer'>
            <div className='text-end'>
              <button
                className='btn btn-primary w-sm waves-effect waves-light'
                onClick={this.save}
              >
                {this.props.t('Confirm')}
              </button>
            </div>
          </div>

          {this.state.error ? (
            <ErrorDialog
              title={this.state.error['title']}
              onClose={() => {
                this.setError(null);
              }}
            >
              <p>{this.state.error['detail']} </p>
            </ErrorDialog>
          ) : (
            ''
          )}

          {this.state.loading ? <Loader loading={this.state.loading} /> : ' '}
        </Modal>
      </React.Fragment>
    );
  }
}

ManageOptinNew.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default withTranslation()(ManageOptinNew);
