import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';

import classnames from 'classnames';

import DateTimeUtils from '../../common/utils/DateTimeUtils';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ErrorDialog from '../../components/Common/ErrorDialog';
import InputDate from '../../components/Common/InputDate';
import InputText from '../../components/Common/InputText';
import LegalIdInput from '../../components/Common/LegalIdInput';
import PaginationService from '../../components/Common/PaginationService';
import Select2Generic from '../../components/Common/Select2Generic';
import SuccessDialog from '../../components/Common/SuccessDialog';
import LiquidationStatusDomain from '../../domains/LiquidationStatusDomain';
import { get } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import LiquidationView from './LiquidationView';
import TableLiquidationsByContractor from './TableLiquidationsByContractor';
import TableLiquidationsByDebitor from './TableLiquidationsByDebitor';

function ManageLiquidations(props) {
  const [pager, setPager] = useState(PaginationService.defaultPager());

  const [filter, setFilter] = useState(
    Object.assign(
      {
        startDueDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
        endDueDate: DateTimeUtils.format(new Date(), 'YYYY-MM-DD'),
      },
      PaginationService.defaultFilter(),
    ),
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [openLiquidationView, setOpenLiquidationView] = useState({
    isOpen: false,
    entity: null,
  });

  const [csvDisabled, setCsvDisabled] = useState(true);

  const [successMessage, setSuccessMessage] = useState(null);

  const [searchByTab] = useState([
    { id: 'CONTRACT', title: props.t('By contract') },
    { id: 'DEBITOR', title: props.t('By debitor') },
  ]);
  const [searchBySelected, setSearchBySelected] = useState('CONTRACT');

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination(filter));

    const forceSearchByContract =
      filter['contractor.legalId'] ||
      filter['contractor.name'] ||
      filter['contractId'] ||
      filter['externalReference'];

    if (forceSearchByContract) searchBy('CONTRACT');
    else applySearch();
  };

  const handleChangeFilter = (event) => {
    filter[event.target.name] = event.target.value;
    setFilter(filter);
  };

  const applySearch = async (currentPage, itemsPerPage, searchType = null) => {
    try {
      const thisReqUrl =
        (searchType || searchBySelected) === 'DEBITOR'
          ? url.GET_LIQUIDATIONS
          : url.GET_LIQUIDATIONS_CTR;
      setCsvDisabled((searchType || searchBySelected) === 'DEBITOR');
      const { pagerResult, filterResult } = await PaginationService.applySearch(
        thisReqUrl,
        'expectedSettlements',
        filter,
        currentPage,
        itemsPerPage,
        setLoading,
      );
      setPager(pagerResult);
      setFilter(filterResult);
    } catch (e) {
      setError(e);
    }
  };

  const download = (f) => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(f.content),
    );
    element.setAttribute('download', f.fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const getCsv = async () => {
    try {
      const result = await get(
        url.GET_LIQUIDATIONS_CTR_CSV,
        null,
        filter,
        setLoading,
      );
      download({ content: result.content, fileName: 'liquidacoes.csv' });
    } catch (e) {
      setError(e);
    }
  };

  const searchBy = async (searchByFilter) => {
    setFilter(PaginationService.resetPagination(filter));
    setSearchBySelected(searchByFilter);
    setPager(PaginationService.defaultPager());
    await applySearch(null, null, searchByFilter);
  };

  const handleClickRow = (entity) => {
    if (entity.status !== 'TOTAL_PAID') {
      openLiquidationView.isOpen = true;
      openLiquidationView.entity = entity;
      setOpenLiquidationView({ ...openLiquidationView });
    }
  };

  const closeLiquidationView = (modalResult) => {
    openLiquidationView.isOpen = false;
    openLiquidationView.entity = null;
    setOpenLiquidationView({ ...openLiquidationView });

    if (modalResult.event === 'SAVED') {
      setSuccessMessage({
        title: 'Liquidação registrada',
        detail: 'Liquidação registrada com sucesso',
      });

      filter['contractId'] = modalResult.entity.originId;
      filter['status'] = null;
      filter['startDueDate'] = modalResult.entity.dueDate;
      filter['endDueDate'] = modalResult.entity.dueDate;

      setFilter({ ...filter });
      applySearch().then(() => {
        filter['startDueDate'] = null;
        filter['endDueDate'] = null;
        setFilter({ ...filter });
      });
    }
  };

  useEffect(() => {
    applySearch();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>{props.t('Liquidations')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t('Liquidations')}
            breadcrumbItem={props.t('Manage Liquidations')}
          />

          {/* Search */}
          <Form id='searchForm' onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t('Filters parameters')}</p>
                    <Row>
                      <Col lg={3} className='mb-3'>
                        <Select2Generic
                          id='status'
                          required={false}
                          defaultOptins={[
                            { value: 'ALL', label: 'Todos' },
                            ...LiquidationStatusDomain.options,
                          ]}
                          getOptionLabel={(op) => {
                            return (
                              <React.Fragment>
                                {op.value === 'ALL' ? (
                                  op.label
                                ) : (
                                  <React.Fragment>
                                    <div
                                      style={{ height: 10, position: 'fixed' }}
                                      className={LiquidationStatusDomain.getClassFromValue(
                                        op.value,
                                      )}
                                    >
                                      {' '}
                                    </div>
                                    <span className='ms-5'>
                                      {LiquidationStatusDomain.getNameFromValue(
                                        op.value,
                                      )}
                                    </span>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          }}
                          onChange={(option) => {
                            if (option.value !== 'ALL') {
                              handleChangeFilter({
                                target: {
                                  name: 'status',
                                  value: option.value,
                                },
                              });
                            } else {
                              handleChangeFilter({
                                target: {
                                  name: 'status',
                                  value: null,
                                },
                              });
                            }
                          }}
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <InputText
                          id='debitor.name'
                          onChange={handleChangeFilter}
                          placeholder={props.t('Name debitor')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <LegalIdInput
                          id='contractor.legalId'
                          onChange={handleChangeFilter}
                          placeholder={props.t('Legal Id contractor')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <InputText
                          id='contractor.name'
                          onChange={handleChangeFilter}
                          placeholder={props.t('Name contractor')}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} className='mb-3'>
                        <InputDate
                          id='startDueDate'
                          value={filter.startDueDate}
                          onChange={handleChangeFilter}
                          placeholder={props.t('Start due date')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <InputDate
                          id='endDueDate'
                          value={filter.endDueDate}
                          onChange={handleChangeFilter}
                          placeholder={props.t('End due date')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <InputText
                          id='contractId'
                          onChange={handleChangeFilter}
                          value={filter.contractId}
                          placeholder={props.t('Contract ID')}
                          autoComplete='off'
                        />
                      </Col>

                      <Col lg={3} className='mb-3'>
                        <InputText
                          id='externalReference'
                          onChange={handleChangeFilter}
                          placeholder={props.t('External Reference')}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col lg={12}>
                        <div className='text-end'>
                          <button
                            type='submit'
                            className='btn btn-sm btn-light w-sm waves-effect waves-light'
                          >
                            {props.t('Apply Search')}
                          </button>{' '}
                          <button
                            onClick={getCsv}
                            disabled={csvDisabled}
                            className='btn btn-sm btn-light w-sm waves-effect waves-light'
                          >
                            <i className='mdi mdi-file-download-outline font-size-16 mr-5' />
                            {props.t('Download')}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <Nav tabs className='nav-tabs-custom nav-justified'>
                          {searchByTab.map((tab, index) => {
                            return (
                              <NavItem key={tab.id}>
                                <NavLink
                                  className={classnames({
                                    active: searchBySelected === tab.id,
                                  })}
                                  onClick={() => {
                                    searchBy(tab.id);
                                  }}
                                >
                                  {tab.title}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </Col>
                    </Row>

                    <Row className='mt-4'>
                      <Col lg={12}>
                        <TabContent activeTab={searchBySelected}>
                          <TabPane tabId='DEBITOR'>
                            {searchBySelected === 'DEBITOR' ? (
                              <TableLiquidationsByDebitor
                                pager={pager}
                                applySearch={applySearch}
                                loading={loading}
                                onClickRow={handleClickRow}
                              />
                            ) : (
                              ''
                            )}
                          </TabPane>

                          <TabPane tabId='CONTRACT'>
                            {searchBySelected === 'CONTRACT' ? (
                              <TableLiquidationsByContractor
                                pager={pager}
                                applySearch={applySearch}
                                loading={loading}
                                onClickRow={handleClickRow}
                              />
                            ) : (
                              ''
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

      {error ? (
        <ErrorDialog
          title={error['title']}
          onClose={() => {
            setError(null);
          }}
        >
          <p>{error['detail']} </p>
        </ErrorDialog>
      ) : (
        ''
      )}

      {openLiquidationView.isOpen ? (
        <LiquidationView
          isOpen={openLiquidationView.isOpen}
          entity={openLiquidationView.entity}
          callback={closeLiquidationView}
        />
      ) : (
        ''
      )}

      {successMessage ? (
        <SuccessDialog
          title={successMessage['title']}
          onClose={() => {
            setSuccessMessage(null);
          }}
        >
          <p>{successMessage['detail']} </p>
        </SuccessDialog>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(ManageLiquidations));
