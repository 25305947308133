import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, TabContent, TabPane } from 'reactstrap';

import { AvField, AvForm } from 'availity-reactstrap-validation';

import EmailInput from '@cash2pay/components/Common/EmailInput';
import InputDate from '@cash2pay/components/Common/InputDate';
import InputText from '@cash2pay/components/Common/InputText';
import LegalIdInput from '@cash2pay/components/Common/LegalIdInput';
import PhoneInput from '@cash2pay/components/Common/PhoneInput';
import { diffInDays, format, sub } from '@cash2pay/libs/date';

const birthDateInputOptions = {
  maxDate: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd'),
};

export const CompleteFieldsToStartCheckout = (props) => {
  const [currentStatusTab] = useState('New');

  const translation = useTranslation();
  // const [statusTabs] = useState([{ id: 'New', title: 'Comprar sem cadastro' }]);

  const handleBuyerField = (name, value) => {
    props.handleBuyerField(name, value);
  };

  const handleBuyerFormField = (fieldId, isValid) => {
    props.handleBuyerFormField(fieldId, isValid);
  };

  const isValidString = (v) => {
    return v && v.toString().length > 0;
  };

  const handleValidSubmit = () => {};

  const handleBirthDateChange = (e) => {
    const value = e.target.value;
    const birthDate = new Date(`${value}T00:00:00`);
    const isValid = diffInDays(birthDate, new Date()) < 0 && !!value;

    handleBuyerFormField('birthDate', isValid);
    handleBuyerField('birthDate', value);
  };

  return (
    <React.Fragment>
      <div
        className={
          props.isMobile ? 'd-lg-block' : 'p-lg-5 pb-lg-3 p-4 d-lg-block'
        }
      >
        <Row>
          <h3 className='checkout-buyer-title'>
            {translation.t('Complete the fields')}
          </h3>
          <br />
          <h3 className='checkout-buyer-title'>
            {translation.t('to start Checkout')}
          </h3>
        </Row>

        <TabContent activeTab={currentStatusTab}>
          <TabPane tabId='Login'>
            <AvForm
              className='form-horizontal'
              onValidSubmit={handleValidSubmit}
            >
              <Row className='mt-5 mb-4'>
                <div>
                  <AvField
                    name='userName'
                    label='Email'
                    className='form-control'
                    placeholder={translation.t('Enter your email here')}
                    type='email'
                    required
                    autoComplete='on'
                    errorMessage={translation.t('Invalid email')}
                    style={{ height: 48 }}
                  />
                </div>
              </Row>

              <Row className='mb-4'>
                <div>
                  <AvField
                    style={{ height: 48 }}
                    name='secret'
                    label={translation.t('Secret')}
                    autoComplete='on'
                    type='password'
                    errorMessage={translation.t('Required field')}
                    required
                    placeholder={translation.t('Enter your password here')}
                  />
                </div>
              </Row>
            </AvForm>
          </TabPane>

          <TabPane tabId='New'>
            <Row className='mt-5 mb-4'>
              <Col lg={12} sm={12}>
                <LegalIdInput
                  id='legalIdBuyer'
                  className='form-control'
                  required
                  autoComplete='on'
                  onChangeValidation={handleBuyerFormField}
                  onChange={(event) => {
                    handleBuyerField('legalId', event.target.value);
                  }}
                  label={translation.t('Legal Id Buyer')}
                />
                <div className='invalid-feedback'>CNPJ inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <PhoneInput
                  id='phoneBuyer'
                  required={true}
                  autoComplete='on'
                  onChangeValidation={handleBuyerFormField}
                  onChange={(event) => {
                    handleBuyerField('phone', event.target.value);
                  }}
                  label={translation.t('Phone Buyer')}
                />
                <div className='invalid-feedback'>Celular inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <EmailInput
                  id='emailBuyer'
                  required={true}
                  autoComplete='on'
                  onChangeValidation={handleBuyerFormField}
                  onChange={(event) => {
                    handleBuyerField('email', event.target.value);
                  }}
                  label={translation.t('E-mail')}
                />
                <div className='invalid-feedback'>E-mail inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <LegalIdInput
                  id='personLegalId'
                  required={true}
                  autoComplete='on'
                  onChangeValidation={handleBuyerFormField}
                  onChange={(event) => {
                    handleBuyerField('personLegalId', event.target.value);
                  }}
                  label={translation.t('Responsable Legal Id')}
                />
                <div className='invalid-feedback'>CPF inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <InputText
                  id='personName'
                  required
                  autoComplete='on'
                  validation={(value) => !!value}
                  onChange={(event) => {
                    handleBuyerFormField(
                      'personName',
                      isValidString(event.target.value),
                    );
                    handleBuyerField('personName', event.target.value);
                  }}
                  label={translation.t('Responsable Name')}
                />
                <div className='invalid-feedback'>Nome inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <InputText
                  id='personCompanyPlacement'
                  required
                  autoComplete='on'
                  validation={(value) => !!value}
                  onChange={(event) => {
                    handleBuyerFormField(
                      'personCompanyPlacement',
                      isValidString(event.target.value),
                    );
                    handleBuyerField(
                      'personCompanyPlacement',
                      event.target.value,
                    );
                  }}
                  label={translation.t('Responsable Company Placement')}
                />
                <div className='invalid-feedback'>Cargo inválido</div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col lg={12} sm={12}>
                <InputDate
                  required
                  label='Data de nascimento'
                  options={birthDateInputOptions}
                  onChange={handleBirthDateChange}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <Row className='mb-3'>
          <div>
            <div className='form-check form-check-active mb-0'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultChecked={true}
                onChange={(event) => {
                  handleBuyerFormField(
                    'checkAcceptedTerms',
                    event.target.checked,
                  );
                }}
                name='requestSigning'
                id='requestSigning'
              />
              <label
                htmlFor='requestSigning'
                className='form-check-label mb-0 ms-1'
              >
                <p className='font-size-12 mb-0'>
                  Li e aceito todos os termos e condições
                </p>
              </label>
            </div>
          </div>
        </Row>

        {!props.documentAlreadySigned && !props.isMobile && (
          <Row className='sticky-bottom'>
            <Col>
              {!props.modalDocInSign && (
                <div className='text-center py-3 bg-white'>
                  <button
                    style={{
                      borderRadius: 120,
                      textTransform: 'uppercase',
                      fontWeight: 600,
                    }}
                    className='btn btn-primary w-100 w-sm waves-effect waves-light h-25'
                    disabled={!props.isValidToInitCheckout}
                    onClick={props.initCheckout}
                  >
                    {translation.t('Start Checkout')}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className='fa fa-lock' />
                  </button>
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};
