import { get, getV2, post } from '../../helpers/api_helper';

class PaginationService {
  constructor(entityName, url) {
    this.entityName = entityName;
    this.filter = {
      'pagination.currentPage': 0,
      'pagination.itemsPerPage': 10,
    };
    this.url = url;
    this.pager = PaginationService.defaultPager();
  }

  static defaultPager = () => {
    return { results: [], pagination: {} };
  };

  static defaultFilter = (itemsPerPage, currentPage = 0) => {
    return {
      'pagination.currentPage': currentPage,
      'pagination.itemsPerPage': itemsPerPage ? Number(itemsPerPage) : 10,
    };
  };

  static resetPagination = (filter) => {
    filter['pagination.currentPage'] = 0;
    filter['pagination.itemsPerPage'] = filter['pagination.itemsPerPage'];

    return filter;
  };

  static resetPaginationV2 = (filter) => {
    if (!filter) {
      filter = {
        'pagination.itemsPerPage': 10,
      };
    }
    filter['pagination.currentPage'] = 1;
    filter['pagination.itemsPerPage'] = filter['pagination.itemsPerPage'];

    return filter;
  };

  static applySearchPagination = (filter, currentPage, itemsPerPage) => {
    if (currentPage !== null && currentPage !== undefined) {
      filter['pagination.currentPage'] = currentPage;
    }
    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      filter['pagination.itemsPerPage'] = itemsPerPage;
    }
  };

  static applySearch = async (
    url,
    resultEntity,
    filter,
    currentPage,
    itemsPerPage,
    setLoading,
  ) => {
    PaginationService.applySearchPagination(filter, currentPage, itemsPerPage);

    const response = await get(url, null, filter, setLoading);

    const pager = {
      results: response[resultEntity] || [],
      pagination: response['parameters']
        ? response['parameters']['pagination']
        : {},
    };
    return {
      pagerResult: pager,
      filterResult: filter,
      currentPage: currentPage,
    };
  };

  static applySearchV2 = async (
    url,
    resultEntity,
    filter,
    currentPage,
    itemsPerPage,
    setLoading,
  ) => {
    PaginationService.applySearchPagination(filter, currentPage, itemsPerPage);

    const response = await getV2(url, null, filter, setLoading);

    const pager = {
      results: response[resultEntity] || [],
      pagination: response['parameters']
        ? response['parameters']['pagination']
        : {},
    };
    return {
      pagerResult: pager,
      filterResult: filter,
      currentPage: currentPage,
    };
  };

  /**
   * @description Apply search with post method
   */
  static applySearchV3 = async (url, resultEntity, data) => {
    const response = await post(url, data);

    const pager = {
      results: response[resultEntity] || [],
      pagination: response['parameters']
        ? response['parameters']['pagination']
        : {},
    };
    return {
      pagerResult: pager,
      filterResult: data,
      currentPage: data['pagination.currentPage'],
    };
  };
}

export default PaginationService;
